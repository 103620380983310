import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {List} from "@mui/material";
import {SideNavItem} from "../../layouts/dashboard/flying-layout/side-nav-item";
import {useLocation, useNavigate} from "react-router-dom";
import usePagination from "../../../hooks/use-pagination";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import OnlineIndicator from "../analytics/dashboards/online-indicator";
import {useSelector, useDispatch} from "react-redux";
import {updateSpaces} from "../../../store/actions/spaces-actions";

export default function SpacesSidebarSection(){
    // TODO: Later we can have icons after type

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { hasRights } = useSecurityCheck();
    const spaces = useSelector(state => state.spaces.spaces);
    const elementLoader = usePagination({
        endpoint: 'pm/projects',
        query: {ordering: 'created_at'},
        page: 1,
        pageSize: 500
    })

    const spaceStates = {
        not_started: 'offline',
        in_progress: 'away',
        finished: 'online',
        blocked: 'busy'
    }

    useEffect(() => {
        if(!elementLoader.loading && elementLoader.elements.length > 0){
            dispatch(updateSpaces(elementLoader.elements));
        }
    }, [elementLoader.loading]);

    return (
        <>
            <List sx={{py: 0}}>
                {Object.values(spaces.byId)?.map(space => (
                    <SideNavItem
                        title={space.title}
                        icon="Headphones02"
                        active={location.pathname?.includes(`/groon/spaces/view/${space.id}`)}
                        path={`/groon/spaces/view/${space.id}/overview`}
                        chip={(
                            <OnlineIndicator status={spaceStates?.[space.state]} />
                        )}
                    />
                ))}
                {hasRights(["add_open_space", "add_team_space", "add_private_space"], 'or') && (
                    <SideNavItem
                        title={t('projects.new_project')}
                        icon="PlusCircle"
                        path='/groon/spaces/add'
                        active={location.pathname?.includes(`/groon/spaces/add`)}
                    />
                )}
            </List>
        </>
    )

}