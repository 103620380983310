import React, {useState} from "react";
import PropTypes from "prop-types";
import {Badge, Fade, IconButton, Link, List, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import AiModelImage from "../ai-model-image";
import {Link as RouterLink} from "react-router-dom";
import OnIcon from "../../../elements/icon";
import SmartPopper from "../../../elements/smart-popper";
import ShareButton from "../../../elements/sharing/share-button";
import RemoveSharableObject from "../../../elements/sharing/remove-sharable-object";
import usePopover from "../../../../hooks/use-popover";
import AssistantLogo from "../assistants/assistant-logo";
import useOmniaApi from "../../../../hooks/use-omnia-api";

function AgentCard({agent, handleOpen, onDelete, ...rest}) {

    const { t } = useTranslation();
    const theme = useTheme();
    const [ hovered, setHovered ] = useState(false);
    const popover = usePopover();

    const handleDelete = () => {
        onDelete(agent);
    }

    return (
        <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={{width: '100%'}}
            spacing={2}
            {...rest}
        >

            <AssistantLogo
                assistant={agent}
                sizePixels={40}
            />
            {/*<Badge color={STATE_COLORS?.[assistant?.llm?.state]} variant="dot" size="small">*/}
            {/*    <AssistantLogo*/}
            {/*        assistant={assistant}*/}
            {/*        sizePixels={40}*/}
            {/*    />*/}
            {/*</Badge>*/}
            <Stack
                sx={{width: '100%'}}
                direction="column"
                spacing={2}
            >
                <Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        onMouseLeave={() => setHovered(false)}
                        onMouseEnter={() => setHovered(true)}
                        sx={{width: '100%'}}
                        justifyContent='flex-start'
                        spacing={2}
                        {...rest}
                    >
                        <Link
                            underline="hover"
                            variant="h6"
                            component={RouterLink}
                            color="textPrimary"
                            onClick={handleOpen}
                        >
                            {agent?.name}
                        </Link>
                        <Fade in={hovered}>
                            <Stack direction="row" spacing={1} alignItems="right">
                                <IconButton size="small" onClick={handleDelete}>
                                    <OnIcon iconName="Trash01" size="small" color="error"/>
                                </IconButton>
                            </Stack>
                        </Fade>
                    </Stack>
                    <Typography variant="subtitle2" color="textSecondary">
                        {agent?.description}
                    </Typography>
                </Stack>
                <Stack>
                    {agent?.llm ? (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <AiModelImage aiModel={agent?.llm} sizePixels={15} />
                            <Typography variant="body1" color="textPrimary">
                                {agent?.llm?.name}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="body1" color="textSecondary">
                                {t('intelligence.assistants.no_language_model_set')}
                            </Typography>
                        </Stack>
                    )}

                </Stack>
            </Stack>
        </Stack>
    )
}

AgentCard.propTypes = {
    agent: PropTypes.object.isRequired,
}

export default AgentCard;