import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Skeleton} from "@mui/lab";
import {Fade} from "@mui/material";

function LoadingCard({height, width = '100%'}){

    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
        // setTimeout(() => {
        //     setShow(true);
        // }, 1000);
    }, []);

    return (
        <Fade in={show}>
            <Skeleton variant="rounded" animation="pulse" style={{borderRadius: 12}} width={width} height={height} />
        </Fade>
    );
}

LoadingCard.propTypes = {
    height: PropTypes.any,
    width: PropTypes.any
}

export default LoadingCard;