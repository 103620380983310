import React from "react";
import {useSelector} from "react-redux";
import {Box, Chip, CircularProgress, Link, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import moment from "moment";
import UserAvatar from "../../UserAvatar";
import {useTranslation} from "react-i18next";
import ServerSideDataTable from "../../../../elements/tables/server-side-data-table";

function UserTable(props){

    const initialized = useSelector(state => state.groups.initialized);
    const { t } = useTranslation();
    const user = useSelector(state => state.account.user);
    const users = useSelector(state => state.users.users);

    const getName = (data) => {
        return (
            <Box display="flex" alignItems="center">
                <UserAvatar
                    alt="Person"
                    style={{marginRight: 20}}
                    user={data.row}
                    component={RouterLink}
                    to={"/groon/home/users/" + data.row.id}
                />
                <Link variant="h6" underline="hover" component={RouterLink} color="textPrimary" to={"/groon/home/users/" + data.row.id}>
                    {data.row.name}
                </Link>
            </Box>
        )
    }

    const getEmail = (data) => {
        return (
            <Typography>
                <Link underline="hover" variant="body1" color="textPrimary" href={"mailto:" + data.row.email}>
                    {data.row.email}
                </Link>
            </Typography>
        )
    }

    const getGroup = (data) => {
        if(data.row.current_group === null){
            return <Typography />;
        } else {
            return (
                <Link
                    underline="hover"
                    variant="body1"
                    color="text.primary"
                    to={"/groon/home/team/" + data.row.current_group.id}
                    component={RouterLink}
                >
                    <Typography>{data.row.current_group.name}</Typography>
                </Link>
            )
        }
    }

    const getSupervisor = (data) => {
        if(data.row.supervisor === null){
            return null
        } else {
            let supervisor = null;
            for(let i = 0; i < users.length; i++)
                if(users[i].id === data.row.supervisor)
                    supervisor = users[i]
            return (
                <Link
                    variant="body1"
                    underline="hover"
                    color="text.primary"
                    to={"/groon/home/users/" + supervisor?.id}
                    component={RouterLink}
                >
                    <Typography>{supervisor?.name || t('notify.not_found')}</Typography>
                </Link>
            )
        }
    }

    const getCreated = (data) => {
        return (
            <Typography>{moment(data.row.created_at).fromNow()}</Typography>
        )
    }

    const getOnlineStatus = (data) => {

        if(user.id === data.row.id)
            return (
                <Chip
                    label={t("Online")}
                    color="primary"
                />
            )

        return (
            <>
                {data.row.is_online ? (
                    <>
                        {data.row.is_active ? (
                            <Chip
                                label={t("Online")}
                                color="primary"
                            />
                        ) : (
                            <Chip
                                label={t("Away")}
                                color="secondary"
                            />
                        )}
                    </>
                ) : (
                    <Chip
                        label={t("Offline")}
                        color="default"
                    />
                )}
            </>
        )
    }

    const renderLanguage = (data) => {
        if(!data.row?.language)
            return null;
        return (
            <Chip label={data.row?.language?.toUpperCase()} />
        )
    }

    let sensitiveFields = [
        'is_blocked',
        'is_deleted',
        'settings',
        'table_settings',
        'current_dashboard',
        'title',
        'gender',
        'first_name',
        'last_name',
        'username',
        'password',
        'street',
        'number',
        'city',
        'postal',
        'country',
        'user_rights',
        'user_group',
        'hired_at',
        'favorites',
        'bio',
        'interests',
        'work_location',
        'date_of_birth',
        'avatar',
        'cover',
        'last_login',
        'force_password_change',
        'force_password_warning_thrown',
        'last_password_reset',
        'last_pw_reset',
        'reset_pw_key',
        'last_active',
        'modified_at',
        'created_at'
    ]
    if(!user?.is_admin)
        sensitiveFields.push('is_admin')

    const columns = [];
    columns.push({ field: 'name', type: 'string', headerName: t('core.user'), width: 200, renderCell: getName})
    columns.push({ field: 'is_online', type: 'boolean', headerAlign: 'left', align: 'left', headerName: t('attributes.status'), width: 150, renderCell: getOnlineStatus})
    columns.push({ field: 'email', type: 'string', headerName: t('attributes.email'), sortable: false, width: 170, renderCell: getEmail})
    columns.push({ field: 'user_group.name', type: 'string', headerName: t('core.group'), width: 150, renderCell: getGroup})
    columns.push({ field: 'supervisor', type: 'string', headerName: t('core.supervisor'), width: 150, renderCell: getSupervisor})
    columns.push({ field: 'language', type: 'string', headerName: t('attributes.language'), width: 150, renderCell: renderLanguage})
    columns.push({ field: 'created_at', type: 'dateTime', headerAlign: 'left', align: 'left', headerName: t('attributes.created'), width: 150, renderCell: getCreated, valueGetter: data => new Date(data)})

    if(!initialized)
        return (
            <div style={{height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress />
            </div>
        )

    return (
        <ServerSideDataTable
            endpoint='core/users'
            id='users-table'
            title={t('core.users')}
            subheader={t('core.users_subheader')}
            defaultState={[
                {
                    field: 'name',
                    width: 250,
                    pinned: 'left',
                    sorted: null
                },
                {
                    field: 'is_online',
                    width: 150,
                    pinned: null,
                    sorted: null
                },
                {
                    field: 'email',
                    width: 250,
                    pinned: null,
                    sorted: null
                },
                {
                    field: 'user_group.name',
                    width: 220,
                    pinned: null,
                    sorted: null
                },
                {
                    field: 'supervisor',
                    width: 150,
                    pinned: null,
                    sorted: null
                },
                {
                    field: 'created_at',
                    width: 150,
                    pinned: null,
                    sorted: null
                },
            ]}
            withoutCheckbox
            excludeFields={sensitiveFields}
            columns={columns}
            {...props}
        />
    )

    // return (
    //     <DataTable
    //         title={t('core.users')}
    //         subheader={t('core.users_subheader')}
    //         id="users-table"
    //         withoutCheckbox
    //         data={users}
    //         columns={columns}
    //     />
    // )
}

export default UserTable;