import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Chip, Link, Tooltip, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {AvatarGroup} from '@mui/material';
import moment from "moment";
import useApi from "src/omnia/hooks/use-api";
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import LoadingCard from "../../../../elements/loading-card";
import {useSecurityCheck} from "../../../../../hooks/use-security-check";
import {useTranslation} from "react-i18next";
import ServerSideDataTable from "../../../../elements/tables/server-side-data-table";

function GroupsTable(props) {

    const initialized = useSelector(state => state.groups.initialized);
    const getSrc = useCreateSrc();
    const {t} = useTranslation();
    const {hasRights} = useSecurityCheck();
    const users = useSelector(state => state.users.users);

    const getName = (data) => {
        return (
            <Link
                underline="hover"
                variant="h6"
                component={RouterLink}
                color="textPrimary"
                to={"/groon/home/team/" + data.row.id}
            >
                {data.row.name}
            </Link>
        )
    }

    const getMembers = (data) => {

        return (
            <AvatarGroup max={8} spacing="small">
                {users.filter(u => data.row.all_users_associated?.includes(u.id)).map((c, i) => (
                    <Tooltip enterDelay={1000} title={c.name} key={i}>
                        <Avatar
                            src={getSrc(c.avatar)}
                            component={RouterLink}
                            to={"/groon/home/profile/" + c.id}
                        />
                    </Tooltip>
                ))}
            </AvatarGroup>
        )
    }

    const getType = (data) => {
        return (
            <Chip label={t('core.group_' + data.row.type)} />
        )
    }

    const getCreated = (data) => {
        return (
            <Typography>{moment(data.row.created_at).fromNow()}</Typography>
        )
    }

    const columns = [];
    columns.push({field: 'name', type: 'string', headerName: t('core.group'), width: 200, renderCell: getName})
    columns.push({
        field: 'id',
        headerAlign: 'left',
        align: 'left',
        headerName: t('core.members'),
        sortable: false,
        width: 300,
        renderCell: getMembers,
        valueGetter: (data, row) => users.filter(u => row?.users?.includes(u.id) || false)?.map(u => u.name)?.join(', ')
    })
    columns.push({field: 'type', type: 'string', headerName: 'Typ', width: 150, renderCell: getType})
    columns.push({
        field: 'created_at',
        type: 'dateTime',
        headerAlign: 'left',
        align: 'left',
        headerName: t('attributes.created'),
        width: 150,
        renderCell: getCreated,
        valueGetter: data => new Date(data)
    })

    if (!initialized)
        return (
            <LoadingCard height={400}/>
        )

    return (
        <ServerSideDataTable
            endpoint="core/user-groups"
            title={t('core.groups')}
            subheader={t('core.groups_subheader')}
            id="groups-table"
            defaultState={[
                {
                    field: 'name',
                    pinned: 'left',
                    width: 220,
                    sorted: null
                },
                {
                    field: 'type',
                    width: 200,
                    sorted: null,
                    pinned: null
                },
                {
                    field: 'id',
                    width: 500,
                    sorted: null,
                    pinned: null
                },
                {
                    field: 'created_at',
                    width: 150,
                    sorted: 'desc',
                    pinned: 'right'
                }
            ]}
            excludeFields={['modified_at']}
            columns={columns}
            canRemove={hasRights("remove_group")}
            {...props}
        />
    )

}

export default GroupsTable;