import React from "react";
import {Box, Button, Divider, Grid, Stack, TextField, Tooltip, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import LogoSelector from "./logo-selector";
import ColorPicker from "../../../../elements/color-picker";
import createColorPalette from "../../../../../utils/create-color-palette";


const range = ['lightest', 'light', 'main', 'dark', 'darkest', 'contrast_text']
const bgRange = ['default_normal', 'default_contrast', 'paper', 'discrete', 'evident']
const fontRange = ['primary_text', 'secondary_text', 'divider']

const OnColorPicker = ({formik, range, name}) => {

    const { t } = useTranslation();

    return (
        <Stack direction="column" spacing={0.1}>
            {range.map(suffix => (
                <ColorPicker
                    key={'color-' + name + '-' + suffix}
                    withHex={true}
                    size="small"
                    label={t('mgt.settings.color_' + suffix)}
                    color={formik.values?.[name + '_' + suffix] || '#000000'}
                    onChange={(color) => {
                        formik.handleChange({
                            target: {
                                name: name + '_' + suffix,
                                value: color
                            }
                        })
                    }}
                />
            ))}

        </Stack>
    )
}

function SectionColors({formik}){

    const { t } = useTranslation();

    const handleAdjustPaletts = () => {
        if(window.confirm(t('notify.are_you_sure'))){

            const colors = ['primary_color', 'secondary_color', 'accent_color', 'success_color', 'info_color', 'warning_color', 'error_color']
            let data;
            colors.forEach(color => {
                data = createColorPalette(formik.values[color + '_main']);
                // iterate over the keys from details
                Object.keys(data).forEach(key => {
                    formik.setFieldValue(color + '_' + key, data[key]);
                });
            });

        }
    }

    return (
        <>
            <Grid container spacing={2} sx={{p: 2, mb: 2}}>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.colors')}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.color_instructions')}
                    </Typography>
                    <Button variant="outlined" color="primary" onClick={handleAdjustPaletts}>
                        {t('mgt.settings.adjust_palettes')}
                    </Button>
                </Grid>
            </Grid>
            <Divider sx={{my: 6}} />
            <Grid container spacing={2} sx={{p: 2}}>
                <Grid item xs={12} lg={4} >
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.primary_color')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='primary_color'
                        range={range}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.secondary_color')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='secondary_color'
                        range={range}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.accent_color')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='accent_color'
                        range={range}
                    />
                </Grid>
            </Grid>
            <Divider sx={{my: 6}} />
            <Grid container spacing={2} sx={{p: 2}}>
                <Grid item xs={12} lg={3}>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.success_color')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='success_color'
                        range={range}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.info_color')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='info_color'
                        range={range}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.warning_color')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='warning_color'
                        range={range}
                    />
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.error_color')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='error_color'
                        range={range}
                    />
                </Grid>

            </Grid>
            <Divider sx={{my: 6}} />
            <Grid container spacing={2} sx={{p: 2}}>

                <Grid item xs={12} lg={6}>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.light_backgrounds')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='light'
                        range={bgRange}
                    />
                </Grid>
                <Grid item xs={12} lg={6} >
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.dark_backgrounds')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='dark'
                        range={bgRange}
                    />
                </Grid>
            </Grid>
            <Divider sx={{my: 6}} />
            <Grid container spacing={2} sx={{p: 2}}>

                <Grid item xs={12} lg={6}>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.light_fonts_dividers')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='light'
                        range={fontRange}
                    />
                </Grid>
                <Grid item xs={12} lg={6} >
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 1, textAlign: 'center', width: '100%'}}
                    >
                        {t('mgt.settings.dark_fonts_dividers')}
                    </Typography>
                    <OnColorPicker
                        formik={formik}
                        name='dark'
                        range={fontRange}
                    />
                </Grid>
            </Grid>
        </>
    )
}

SectionColors.propTypes = {
    formik: PropTypes.object.isRequired
}

OnColorPicker.propTypes = {
    formik: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired
}

export default SectionColors;