import React from "react";
import {SideNavItem} from "../flying-layout/side-nav-item";
import usePerformTransition from "../../../../hooks/use-perform-transition";
import {useNavigate} from "react-router-dom";

function ToHomeButton(item){

    const navigate = useNavigate();

    const transit = usePerformTransition(() => {
        navigate(item.path);
    })

    return (
        <SideNavItem
            active={false}
            depth={0}
            onClick={transit}
            disabled={item.disabled}
            chip={item.chip || null}
            icon={item?.icon}
            items={item?.items || []}
            tooltip={item?.tooltip}
            label={item?.label}
            title={item?.title}
        />
    )
}

export default ToHomeButton;