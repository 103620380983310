import React, {useEffect, useState} from "react";
import {
    Box,
    Grid,
    CircularProgress, TextField, Card, Fade, DialogTitle, Stack, Typography, Switch, Dialog, Button, Tabs, Tab,
} from "@mui/material";
import useSocket from "src/omnia/hooks/use-socket";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import {APP_SETTING} from "src/setup";
import DetailViewLayout from "../../components/elements/detail-view-layout";
import OverviewTiles from "../../components/elements/overview-tiles";
import AgentCard from "../../components/modules/ai-studio/agents/agent-card";
import {useDialog} from "../../hooks/use-dialog";
import AgentDialog from "../../components/modules/ai-studio/agents/agent-dialog";
import OnIcon from "../../components/elements/icon";
import useOmniaApi from "../../hooks/use-omnia-api";


function Agents({...rest}){
    const { t } = useTranslation();
    const theme = useTheme();
    const dialog = useDialog();
    const { del } = useOmniaApi();
    const [update, setUpdate] = useState(false);

    const handleDelete = (agent) => {
        del('ai/agents', agent).then(() => {
            setUpdate(!update);
        });
    };

    return (
        <DetailViewLayout
            title={t('intelligence.agents.browse_agents')}
            subtitle={t('intelligence.agents.agents_subtitle')}
            viewRights={['agents']}
            actionButton={
                <Button
                    startIcon={<OnIcon iconName="Plus" />}
                    onClick={() => dialog.handleOpen(null)}
                    variant="contained"
                >
                    {t('common.create_new')}
                </Button>
            }
        >
            <Box mt={8}>
                <AgentDialog
                    agent={dialog.data}
                    open={dialog.open}
                    onClose={dialog.handleClose}
                />
                <OverviewTiles
                    spacing={8}
                    endpoint='ai/agents'
                    updateListener={update}
                    renderTile={(agent) => (
                        <AgentCard
                            agent={agent}
                            key={'agent-' + agent?.id}
                            handleOpen={() => dialog.handleOpen(agent)}
                            onDelete={handleDelete}
                        />
                    )}
                />
            </Box>
        </DetailViewLayout>
    )
}

export default Agents;


