import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {enUS, deDE} from "@mui/x-data-grid-pro/locales";
import {
    DataGridPro,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridFooter,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
    Tooltip,
    Link,
    OutlinedInput,
    Fade,
} from "@mui/material";
import {LicenseInfo} from '@mui/x-license-pro';
import Button from "@mui/material/Button";
import Delete from "@mui/icons-material/Delete";
import _ from "lodash";
import useWindowDimensions from "src/omnia/hooks/use-window-dimensions";
import {useDispatch, useSelector} from "react-redux";
import {saveGridState} from "../../../store/actions/datatable-actions";
import {Stack} from "@mui/material";
import debounce from "lodash.debounce";
import {ErrorBoundary} from "react-error-boundary";
import {logRenderingError} from "../../../store/actions/client-actions";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import {useSettings} from "../../../hooks/use-settings";
import {produce} from "immer";
import {APP_SETTING} from "../../../../setup";
import OnIcon from "../icon";
import NoResults from "../no-results";

LicenseInfo.setLicenseKey('d8294c24db4d789fef553d05deea5bc8Tz05MDIxMCxFPTE3NDcyMjQ5NTcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function CustomToolbar(props) {

    const {
        onRemove = null,
        onReload = null,
        hasRemoveOption = true,
        actions = [],
        selectedRows,
        exportName = null
    } = props;

    const {width} = useWindowDimensions();
    const {t} = useTranslation();

    const handledClickAction = (action, selectedRows) => {
        if (action?.action) {

            // Call the specified action
            action?.action?.(selectedRows);

            // Wait 100 milliseconds until reloading to give the "action" some time
            setTimeout(() => {
                if (onReload)
                    onReload();
            }, 100);
        }
    }

    return (
        <>
            <GridToolbarContainer>
                <Stack direction="row" sx={{pb: 0.5, width: '100%'}}>
                    <GridToolbarColumnsButton/>
                    {/*{width > 800 && (<GridToolbarDensitySelector/>)}*/}
                    <GridToolbarFilterButton/>
                    <Box flexGrow={1}/>
                    {hasRemoveOption && (
                        <Button
                            color="primary"
                            startIcon={<Delete/>}
                            size="small"
                            onClick={onRemove}
                        >
                            {t("common.delete")}
                        </Button>
                    )}
                    {width > 800 && (<GridToolbarExport csvOptions={exportName ? {fileName: exportName} : {}}/>)}
                    {actions.map((action, i) => {

                        const actionButton = (
                            <Button
                                key={'action_button_' + i}
                                color="primary"
                                startIcon={action?.icon}
                                size="small"
                                onClick={() => handledClickAction(action, selectedRows)}
                            >
                                {t(action?.name || 'common.action')}
                            </Button>
                        )

                        if (action['tooltip']) {
                            return (
                                <Tooltip title={action?.tooltip || null} placement='top'>
                                    {actionButton}
                                </Tooltip>
                            )
                        } else {
                            return actionButton;
                        }
                    })}
                </Stack>
            </GridToolbarContainer>
            <Divider/>
        </>
    );
}

function CustomPagination({defaultState, onResetLayout}) {

    const {t} = useTranslation();

    const handleReset = () => {
        if (window.confirm(t('notify.are_you_sure'))) {
            onResetLayout();
        }
    }

    return (
        <>
            <Divider/>
            <Stack direction="row" alignItems='center' justifyContent="space-between" sx={{width: '100%'}}>
                {defaultState ? (
                    <Link
                        onClick={handleReset}
                        variant="body2"
                        sx={{ml: 2, cursor: 'pointer'}}
                        color='textPrimary'
                    >
                        {t('common.sst_default_layout')}
                    </Link>
                ) : (
                    <Box/>
                )}
                <GridFooter/>
            </Stack>
        </>
    );
};

CustomToolbar.propTypes = {
    onRemove: PropTypes.func,
    hasRemoveOption: PropTypes.bool,
    actions: PropTypes.array,
    value: PropTypes.string,
    exportName: PropTypes.string,
    onSearch: PropTypes.func,
    onReload: PropTypes.func,
    clearSearch: PropTypes.func
};

function getGridState(state, tableId, defaultState) {

    // Look for saved state backup
    let stateIndex = _.findIndex(state.tableStates, {'id': tableId});

    // Check if there is a saved state
    if (stateIndex !== -1) {
        return state.tableStates[stateIndex]['state'];
    } else {
        return defaultState;
    }

}

function loadingError() {

    const {t} = useTranslation();
    const theme = useTheme();

    return (
        <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
            <Card>
                {/*<CardHeader title="Datenbank" subheader="Es ist ein Problem aufgetreten"/>*/}
                <CardContent>
                    <div style={{height: 250, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <NoResults
                            primary={t("common.sst_table_error")}
                            icon="AlertTriangle"
                        />
                    </div>
                </CardContent>
            </Card>
        </Fade>
    )
}

function DataTable(props) {

    const {
        id = null,
        title = 'Datenbank',
        density = 'standard',
        subheader = null,
        data = [],
        height = '100%',
        columns = [],
        actions = [],
        rowHeight = 55,
        handleRemove = null,
        noBorder = false,
        noHeader = false,
        withoutCheckbox = false,
        withoutToolbar = false,
        isLoading = false,
        onReloadTable,
        onIsRowSelectable = null,
        debounceDuration = 500,
        onGridLiveUpdate = null,
        onSelectionChange,
        paginationModel,
        noCard = false,
        defaultState = null,
        exportName = null,
        ...rest
    } = props;

    const initialGridState = getGridState(useSelector(state => state.datatable), id, defaultState);
    const theme = useTheme();
    const {t} = useTranslation();
    const [query, setQuery] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [cachedCols, setColumns] = useState(null);
    const [languageOption, setLanguageOption] = useState(enUS.components.MuiDataGrid.defaultProps.localeText);
    const dispatch = useDispatch();
    const settings = useSettings();
    const gridApiRef = useGridApiRef();

    const handleResetLayout = () => {
        if (defaultState) {
            gridApiRef.current.restoreState(defaultState);
        } else {
            console.log("No default state provided");
        }
    }

    const stateUpdate = (value) => {
        let state = gridApiRef.current.exportState();
        const updateState = produce(state, draft => {
            draft.searchQuery = value;
        });
        if (onGridLiveUpdate)
            onGridLiveUpdate(updateState, {searchQuery: value});
    }

    const debounceStateUpdate = useCallback(debounce(stateUpdate, 1000), []);

    const handleSearchChange = (e) => {
        setQuery(e.target.value);
        debounceStateUpdate(e.target.value);
    }

    const handleRowSelectable = (data) => {
        if (onIsRowSelectable) {
            return onIsRowSelectable(data.row);
        } else {
            return true;
        }
    }

    const handleReload = () => {
        if (onReloadTable)
            onReloadTable();
    }

    const storeState = () => {
        let state = gridApiRef.current.exportState();
        if (typeof state['preferencePanel'] !== "undefined")
            delete state['preferencePanel'];

        dispatch(saveGridState(id, state));
    }

    const debouncedChangeHandler = useCallback(debounce(storeState, debounceDuration), []);

    const handleDatatableError = (error, info) => {
        dispatch(logRenderingError(error, info, "Datatable"));
    }

    const handleStateChange = (newSlice) => {
        try {
            if (onGridLiveUpdate)
                if(gridApiRef.current)
                    onGridLiveUpdate(gridApiRef.current.exportState(), newSlice);
        } catch (e) {
            console.log('Could not call live update', e);
        }
        debouncedChangeHandler();
    }

    const handleColumnVisibilityChange = (val) => {
        debouncedChangeHandler();
    }

    const onRemoveSelected = () => {
        if (handleRemove)
            handleRemove(selectedRows);
    }

    const handlePageChange = (newPage) => {
        const newPaginationModel = {...paginationModel, page: newPage};
        handleStateChange(newPaginationModel);
    };

    const handlePageSizeChange = (newPageSize) => {
        const newPaginationModel = {...paginationModel, pageSize: newPageSize};
        handleStateChange(newPaginationModel);
    };

    useEffect(() => {
        if (onSelectionChange)
            onSelectionChange(selectedRows);
    }, [selectedRows]);

    useEffect(() => {
        if (settings.language === "de") {
            setLanguageOption(deDE.components.MuiDataGrid.defaultProps.localeText)
        } else {
            setLanguageOption(enUS.components.MuiDataGrid.defaultProps.localeText)
        }
    }, [settings]);

    useEffect(() => {
        setColumns(columns);
    }, [id]);

    if (!id) {
        alert("Created an AIOS table without id");
        return null;
    }

    if (!cachedCols)
        return null;

    const content = (
        <div style={{display: 'flex', flexDirection: 'column', minHeight: 400, height: height}}>
            {(noCard && onGridLiveUpdate) && (
                <>
                    <Stack direction="row-reverse" sx={{width: '100%', p: 1}}>
                        <OutlinedInput
                            sx={{width: '100%', height: 40}}
                            onChange={handleSearchChange}
                            value={query}
                            placeholder={t('common.sst_type_anything')}
                        />
                    </Stack>
                    <Divider />
                </>
            )}
            <DataGridPro
                apiRef={gridApiRef}

                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                onColumnOrderChange={handleStateChange}
                onPinnedColumnsChange={handleStateChange}
                onColumnResize={handleStateChange}
                onSortModelChange={handleStateChange}
                onFilterModelChange={handleStateChange}

                initialState={initialGridState}

                sx={{
                    ...{
                        '& .MuiDataGrid-cell': {
                            display: 'flex',
                            alignItems: 'center',
                        },
                    },
                    ...(noBorder ? {border: 'none', } : {})
                }}

                // Data and columns
                rows={data}
                columns={cachedCols}

                isRowSelectable={handleRowSelectable}
                rowSelectionModel={selectedRows}
                onRowSelectionModelChange={(newSelection) => {
                    setSelectedRows(newSelection);
                }}
                localeText={languageOption}
                rowHeight={rowHeight}
                loading={isLoading}

                slots={{
                    toolbar: (withoutToolbar ? null : CustomToolbar),
                    footer: CustomPagination,

                    noRowsOverlay: () => (
                        <Box style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <NoResults primary={t('common.sst_no_rows')} />
                        </Box>
                    ),
                    noResultsOverlay: () => (
                        <Box style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <NoResults
                                primary={t('common.sst_no_results')}
                                icon="AlertTriangle"
                                iconSize="medium"
                            />
                        </Box>
                    ),
                }}
                slotProps={withoutToolbar ? {} : {
                    toolbar: {
                        onRemove: onRemoveSelected,
                        onReload: handleReload,
                        hasRemoveOption: (handleRemove !== null),
                        actions: actions,
                        selectedRows: selectedRows,
                        exportName: exportName
                    },
                    footer: {
                        defaultState: defaultState,
                        onResetLayout: handleResetLayout
                    }
                }}

                checkboxSelection={!withoutCheckbox}
                density={density}
                disableRowSelectionOnClick

                // Pagination
                pagination={true}
                paginationMode="server"
                onPaginationModelChange={handleStateChange}
                paginationModel={paginationModel}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                pageSizeOptions={[10, 25, 50, 100, 200, 500]}

                {...rest}
            />
        </div>
    )

    if (noCard)
        return content;

    return (
        <ErrorBoundary
            style={{height: '100%', width: '100%'}}
            FallbackComponent={loadingError}
            onError={handleDatatableError}
        >
            <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
                <Card sx={{padding: 0.1}}>
                    {!noHeader && (
                        <>
                            <CardHeader
                                title={title}
                                subheader={subheader}
                                action={onGridLiveUpdate && (
                                    <OutlinedInput
                                        sx={{width: (query && query.length > 30) ? 300 : 200, height: 40}}
                                        onChange={handleSearchChange}
                                        value={query}
                                        placeholder={t('common.sst_type_anything')}
                                    />
                                )}
                            />
                            <Divider/>
                        </>
                    )}
                    <CardContent style={{padding: 0}}>
                        <div style={{height: '100%', width: '100%'}}>
                            {content}
                        </div>
                    </CardContent>
                </Card>
            </Fade>
        </ErrorBoundary>
    );

}

DataTable.propTypes = {
    id: PropTypes.string,
    exportName: PropTypes.string,
    title: PropTypes.string,
    subheader: PropTypes.string,
    onGridLiveUpdate: PropTypes.func,
    density: PropTypes.string,
    defaultState: PropTypes.object,
    data: PropTypes.array,
    actions: PropTypes.array,
    debounceDuration: PropTypes.number,
    columns: PropTypes.array,
    withoutCheckbox: PropTypes.bool,
    noBorder: PropTypes.bool,
    noCard: PropTypes.bool,
    height: PropTypes.string,
    noHeader: PropTypes.bool,
    rowHeight: PropTypes.number,
    isLoading: PropTypes.bool,
    withoutToolbar: PropTypes.bool,
    handleRemove: PropTypes.func,
    onIsRowSelectable: PropTypes.func,
    onReloadTable: PropTypes.func,
    onSelectionChange: PropTypes.func,
    paginationModel: PropTypes.object
};

export default DataTable;