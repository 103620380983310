import {useSelector} from "react-redux";
import {useCallback, useRef} from "react";

export const useSecurityCheck = () => {

    const user = useSelector(state => state.account.user);
    const serviceUser = useSelector(state => state.service.user);
    const moduleData = useSelector(state => state.account.modules);
    const userRef = useRef();
    const serviceUserRef = useRef();
    userRef.current = user;
    serviceUserRef.current = serviceUser;

    const isModuleInstalled = ( module ) => {

        // If no module is passed, return false
        if(!module)
            return false;

        // check if module is a key in moduleData, if not, raise an error
        // eslint-disable-next-line no-prototype-builtins
        if(!moduleData?.hasOwnProperty(module)){
            console.log('Module name not found in installedModuleData: ', module);
            return false;
        }

        // check if the module is installed
        return moduleData[module];
    }

    const hasRights = useCallback(( wantedRights = [], operation = "and" ) => {

        // If no rights are needed, return true
        if(!wantedRights)
            return true;

        if(typeof(wantedRights) === "string"){

            // Simply check the passed right
            return userRef.current?.rights?.includes(wantedRights) || false;

        } else if(typeof(wantedRights) === "object"){

            // Check if there are any rights at all
            if(wantedRights.length === 0)
                return true;

            // Check all passed rights
            let successfulChecks = [];
            for (let i = 0; i < wantedRights.length; i++)
                if((userRef.current?.rights || [])?.includes(wantedRights[i]))
                    successfulChecks.push(wantedRights[i]);

            // Check the success of the operation
            if(operation.toLowerCase() === "or"){
                return successfulChecks.length > 0;
            } else if(operation.toLowerCase() === "and"){
                return successfulChecks.length === wantedRights.length;
            } else {
                return false;
            }
        } else {
            console.log('useSecurityCheck does not recognize the type of rights to check')
            return false;
        }

    }, []);

    return { hasRights, isModuleInstalled };
}