import React, {useCallback, useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Collapse,
    Stack,
    Box,
    Divider,
    Button,
    Pagination,
    CircularProgress,
    TextField,
    MenuItem, Chip
} from '@mui/material';
import debounce from "lodash.debounce";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";
import { produce } from "immer";
import OnIcon from "../icon";
import {useTheme} from "@mui/system";
import NoResults from "../no-results";

const MobileDataRow = ({ row, columns, state, primaryColumn }) => {

    const [expanded, setExpanded] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const { t } = useTranslation();

    const handleShowAll = () => {
        setShowAll(true);
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const fieldOrder = state?.orderedFields || columns.map(c => c.field);

    const additionalColumns = produce(columns, draft => draft.sort((a, b) => {
        try {
            let indexA = fieldOrder.indexOf(a.field);
            let indexB = fieldOrder.indexOf(b.field);

            if (indexA > indexB) {
                return 1;
            } else if (indexA < indexB) {
                return -1;
            } else {
                return 0;
            }
        } catch (e) {
            return 0;
        }
    }));

    const renderCellContent = (column, rowData) => {
        const plainData = rowData?.[column.field] || '';
        const muiPackage = { field: column?.field, row: rowData, data: plainData };
        const data = column.valueGetter ? column.valueGetter(rowData?.[column?.field] || null) : plainData;
        let value = column.renderCell ? column.renderCell({ ...muiPackage, data: data}) : data;

        if((value !== null) && (typeof value === 'object') && !React.isValidElement(value)){
            value = <Chip label="Ein Object" />;
        }

        return value;
    };

    return (
        <Card variant="outlined" sx={{ marginBottom: 2 }}>
            <CardContent onClick={handleExpandClick} style={{padding: 15}}>
                <Stack direction="row" alignItems='center' justifyContent='space-between'>
                    <Box>
                        <Typography variant="h6">
                            {renderCellContent(primaryColumn, row)}
                        </Typography>
                    </Box>
                    <IconButton
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        size="small"
                        sx={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </Stack>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider />
                <CardContent style={{padding: 15}}>
                    <Stack direction="column" spacing={2}>
                        {additionalColumns.map((column) => {

                            if (!showAll && ((state?.columnVisibilityModel?.[column.field] || true) === false))
                                return null;

                            return (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    key={column.field}
                                    spacing={2}
                                >
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {column.headerName || column.field}
                                    </Typography>
                                    <Typography variant="body2" textAlign="right">
                                        {renderCellContent(column, row)}
                                    </Typography>
                                </Stack>
                            )
                        })}
                    </Stack>
                    {(!showAll && (Object.keys(state?.columnVisibilityModel || {}) || []).length > 0) && (
                        <Box mt={2}>
                            <Button fullWidth variant="outlined" onClick={handleShowAll}>
                                {t("Show all")}
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Collapse>
        </Card>
    );
};

const MobileDataGrid = ({ title, subheader, rows, state, columns, rowCount, isLoading, defaultState, onStateUpdate }) => {
    const theme = useTheme();
    const left = state?.pinnedColumns?.left || [];
    const right = state?.pinnedColumns?.right || [];
    const pinned = [...left, ...right];
    const { t } = useTranslation();
    const [searchQuery, setSearchQuery] = useState('');
    const [sortField, setSortField] = useState((state?.sorting?.sortModel?.length || 0) > 0 ? state?.sorting?.sortModel[0]?.field : null);
    const [sortDirection, setSortDirection] = useState((state?.sorting?.sortModel?.length || 0) > 0 ? state?.sorting?.sortModel[0]?.sort : null);

    const stateUpdate = (value) => {
        const updateState = produce(state, draft => {
            draft.searchQuery = value;
        });
        onStateUpdate(updateState, {searchQuery: value});
    }

    const debounceStateUpdate = useCallback(debounce(stateUpdate, 1000), []);

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
        debounceStateUpdate(e.target.value);
    }

    const handleSortChange = () => {
        if (sortField && sortDirection) {
            const updateState = produce(state, draft => {
                if(!draft.sorting)
                    draft.sorting = {};
                draft.sorting.sortModel = [{ field: sortField, sort: sortDirection }];
            });
            onStateUpdate(updateState, [{ field: sortField, sort: sortDirection }]);
        } else {
            const updateState = produce(state, draft => {
                if(!draft.sorting)
                    draft.sorting = {};
                draft.sorting.sortModel = [];
            });
            onStateUpdate(updateState, []);
        }
    };

    const handleSortFieldChange = (event) => {
        setSortField(event.target.value);
        setSortDirection(sortDirection || 'asc');
    };

    const handleSortDirectionChange = (event) => {
        setSortDirection(event.target.value);
    };

    const { page, pageSize } = state?.pagination?.paginationModel || {page: 0, pageSize: 10};

    const pageCount = Math.floor(rowCount / pageSize);

    const handlePageChange = (event, newPage) => {
        onStateUpdate(state, {page: newPage, pageSize: state?.pagination?.paginationModel?.pageSize || 10});
    };

    const primaryCandidates = columns.filter(col => ['name', 'title'].includes(col.field.toLowerCase()));

    const defaultStatePinnedLeft = defaultState?.filter(col => col.pinned === 'left') || [];

    const primaryColumn = defaultStatePinnedLeft?.length > 0
        ? columns?.filter(c => c?.field === defaultStatePinnedLeft[0]?.field)?.[0]
        : (pinned.length === 0)
        ? primaryCandidates.length > 0 ? primaryCandidates[0] : columns[0]
        : produce(columns.filter(col => pinned.includes(col.field)), draft => {})?.[0] || columns[0];

    useEffect(() => {
        handleSortChange();
    }, [sortField, sortDirection]);

    return (
        <>

            <Stack direction="column" spacing={0} sx={{mb: 2}}>
                <Typography variant="h6">
                    {title}
                </Typography>
                <Typography variant="caption">
                    {subheader}
                </Typography>
            </Stack>

            <Stack direction="column" spacing={2} sx={{ mb: 6}}>
                <Stack direction="row" spacing={2}>
                    <TextField
                        select
                        fullWidth
                        label={t("common.sort_field")}
                        value={sortField}
                        onChange={handleSortFieldChange}
                    >
                        {columns.map((column) => (
                            <MenuItem key={column.field} value={column.field}>
                                {column.headerName}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label={t("common.sort_direction")}
                        value={sortDirection}
                        onChange={handleSortDirectionChange}
                    >
                        <MenuItem value="asc">{t("common.ascending")}</MenuItem>
                        <MenuItem value={null}>{t("common.no_sorting")}</MenuItem>
                        <MenuItem value="desc">{t("common.descending")}</MenuItem>
                    </TextField>
                </Stack>
                <TextField
                    fullWidth
                    label={t("common.search")}
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                />
            </Stack>

            {rows.map((row) => (
                <MobileDataRow
                    key={row.id}
                    row={row}
                    state={state?.columns || []}
                    primaryColumn={primaryColumn}
                    columns={columns}
                />
            ))}
            {rows.length === 0 && (
                <>
                    {isLoading ? (
                        <div style={{
                            height: 200,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress/>
                        </div>
                    ) : (
                        <div style={{
                            height: 250,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <NoResults
                                primary={t("common.sst_no_rows")}
                                icon="SearchRefraction"
                            />
                        </div>
                    )}
                </>
            )}
            <Pagination
                sx={{width: '100%'}}
                variant='outlined'
                count={pageCount}
                boundaryCount={1}
                page={page}
                onChange={handlePageChange}
                color="primary"
                showFirstButton
                showLastButton
                size="medium"
                hidePrevButton
                hideNextButton
            />

        </>
    );
};

export default MobileDataGrid;
