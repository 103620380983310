import {useTheme} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useLocation} from "react-router-dom";
import {useCallback, useEffect, useRef} from "react";
import {useSettings} from "./use-settings";
import debounce from "lodash.debounce";
import {useDispatch, useSelector} from "react-redux";
import {setNavBarMode} from "../store/actions/client-actions";
import {useAppMode} from "./use-app-mode";

export const useIsMobile = () => {
    const theme = useTheme();
    const location = useLocation();
    const settings = useSettings();
    const dispatch = useDispatch();
    const navBarMode = useSelector(state => state.client.navBarMode);
    const appMode = useAppMode();
    const navBarModeRef = useRef();
    navBarModeRef.current = navBarMode;

    const fullscreenPathNames = [
        '/groon/home/chat',
        '/groon/home/emails',
        '/groon/analytics/datahub',
        '/groon/resources/orders',
        '/groon/resources/invoices',
        '/groon/spaces/add'
    ];

    // Checks whether the current path is a fullscreen view
    const isFullWidthView = fullscreenPathNames.filter(fp => location.pathname?.includes(fp))?.length > 0;

    // Mobile screen sizes are typically below 'sm'
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Tablet screen sizes are typically between 'sm' and 'md'
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    // Desktop screen sizes are typically above 'md'
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    // Large desktops are above xl
    const isLargeDesktop = useMediaQuery((theme) => theme.breakpoints.up('xl'))

    // Shortcut variable for existing uses
    const isFlying = navBarMode === 'flying';

    const updateNavBarMode = (newMode) => {
        if(navBarModeRef.current !== newMode){
            dispatch(setNavBarMode(newMode));
            navBarModeRef.current = newMode;
        }
    }

    const debouncedChangeHandler = useCallback(debounce(updateNavBarMode, 100), []);

    useEffect(() => {
        if(settings.isInitialized){
            if(isMobile){
                debouncedChangeHandler('mobile');
            } else if(appMode.layout !== 'vertical') {
                if(isFullWidthView){
                    if(isDesktop){
                        debouncedChangeHandler('flying-topbar');
                    } else {
                        debouncedChangeHandler('topbar');
                    }
                } else {
                    if(isLargeDesktop){
                        debouncedChangeHandler('flying');
                    } else if(isDesktop){
                        debouncedChangeHandler('flying-topbar');
                    } else {
                        debouncedChangeHandler('topbar');
                    }
                }
            } else {
                debouncedChangeHandler('topbar');
            }
        }

    }, [isFullWidthView, isLargeDesktop, isDesktop, isMobile, settings.isInitialized, appMode.layout]);

    return { isMobile, isTablet, isDesktop, isFlying, navBarMode }
}