import React from "react";
import {Stack, Typography} from "@mui/material";
import OnIcon from "../../../elements/icon";
import ClickableInput from "../../../elements/clickable-input";
import {useTranslation} from "react-i18next";
import {useDialog} from "../../../../hooks/use-dialog";
import UserStatusDialog from "./user-status-dialog";
import {useTheme} from "@mui/system";
import {useSelector} from "react-redux";
import moment from "moment";

function UserStatusSelector(){
    const { t } = useTranslation();
    const theme = useTheme();
    const statusDialog = useDialog();
    const user = useSelector((state) => state.account.user);
    const statusUntil = moment(user?.status_until);
    const now = moment();
    const hasStatus = ((user?.status_until === null) || (user?.status_until && statusUntil.isAfter(now))) && (user?.status_text !== '') && (user?.status_text !== null);

    return (
        <>
            <UserStatusDialog open={statusDialog.open} onClose={statusDialog.handleClose} />
            <ClickableInput onClick={statusDialog.handleOpen}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {(hasStatus && user?.status_emoji) ? (
                            <Typography sx={{ fontSize: 18, lineHeight: 1 }}>
                                {user?.status_emoji}
                            </Typography>
                        ) : <OnIcon iconName="FaceHappy" size="small" sx={{color: theme.palette.text.secondary}} />}
                        <Typography variant="body1" color={user?.status_text ? 'textPrimary' : 'textSecondary'}>
                            {hasStatus ? user?.status_text : t('core.set_status')}
                        </Typography>
                    </Stack>
                    {hasStatus && (
                        <OnIcon iconName="Edit03" size="tiny" sx={{color: theme.palette.text.secondary}} />
                    )}
                </Stack>
            </ClickableInput>
        </>
    )
}

export default UserStatusSelector;