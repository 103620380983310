import React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import useCrmFormik from "../../../hooks/formik/use-crm-formik";
import useOmniaApi from "../../../hooks/use-omnia-api";
import ContextForm from "./context-form";
import {useNotifications} from "../../../hooks/use-notifications";
import SaveButton from "../../elements/save-button";
import PropTypes from "prop-types";
import {useIsMobile} from "../../../hooks/use-is-mobile";

function AddContext({open, onClose, onDone = null, ...rest}) {

    const {t} = useTranslation();
    const {notify} = useNotifications();
    const {post} = useOmniaApi();
    const { isMobile } = useIsMobile();

    const handleSubmit = (values, helpers) => {
        helpers.setSubmitting(true);
        post('crm/contexts', values).then(response => {
            notify(t("notify.success"), 'success');
            onClose();
            helpers.resetForm();
            if (onDone)
                onDone?.(response);
        }).finally(() => {
            helpers.setSubmitting(false);
        })
    }

    const {contextFormik} = useCrmFormik(null, handleSubmit);

    const onCancel = () => {
        onClose();
        setTimeout(() => {
            contextFormik.resetForm();
        }, 200);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile}
            fullWidth={true}
            maxWidth="md"
            {...rest}
        >
            <DialogTitle>
                <Typography variant="h4">
                    {t('dialogs.create_context.title')}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {t('dialogs.create_context.description')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box mt={1}>
                    <ContextForm
                        formik={contextFormik}
                        minimal={true}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    {contextFormik?.values?.kind !== null ? (
                        <Button variant="outlined" onClick={() => contextFormik.resetForm()}>
                            {t('common.back')}
                        </Button>
                    ) : (
                        <Button variant="outlined" onClick={onCancel}>
                            {t('common.cancel')}
                        </Button>
                    )}
                    {contextFormik?.values?.kind !== null && (
                        <SaveButton formik={contextFormik} />
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

AddContext.propTypes = {
    onDone: PropTypes.func,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}

export default AddContext;