import React from "react";
import {useTranslation} from "react-i18next";
import OnlineIndicator from "../analytics/dashboards/online-indicator";
import {Tooltip} from "@mui/material";

function AiModelStateIndicator({model, size = "medium"}){

    const { t } = useTranslation();

    const indicatorColor = {
        online_on_gpu: 'online',
        online_outside: 'online',
        groon_ai_offline: 'busy',
        not_enough_ram: 'busy',
        connector_offline: 'busy',
        integration_error: 'busy',
        connector_recent_errors: 'away',
        configuration_error: 'busy',
    }

    if(Object.keys(indicatorColor).includes(model?.state)){
        return (
            <Tooltip title={t('intelligence.models.states.' + model.state + '_info', {name: model?.connector?.name || '--'})}>
                <OnlineIndicator status={indicatorColor?.[model?.state]} size={size} />
            </Tooltip>
        )
    }

    return null;
}

export default AiModelStateIndicator;