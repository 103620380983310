import React, {
    useCallback,
    useEffect,
    useRef,
    useMemo,
    useState,
    memo
} from "react";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    Divider,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Stack,
    Tooltip,
    Skeleton,
} from "@mui/material";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import useOmniaApi from "../../../hooks/use-omnia-api";
import useRenderObjects from "../../../hooks/data-rendering/use-render-objects";
import {registerSearchResults} from "../../../store/actions/account-actions";
import {coreAppsConfig} from "../../../config";
import {APP_SETTING} from "../../../../setup";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import useGroonSettings from "../../../hooks/use-groon-settings";
import NoResults from "../no-results";
import OnIcon from "../icon";

function longestCommonSubstringLength(str1, str2) {
    let max = 0;
    const len1 = str1.length;
    const len2 = str2.length;
    const dp = Array.from({length: len1 + 1}, () => new Array(len2 + 1).fill(0));

    for (let i = 1; i <= len1; i++) {
        for (let j = 1; j <= len2; j++) {
            if (str1[i - 1] === str2[j - 1]) {
                dp[i][j] = dp[i - 1][j - 1] + 1;
                max = Math.max(max, dp[i][j]);
            }
        }
    }
    return max;
}

function findBestBufferKeyMatch(targetKey, buffer) {
    const allKeys = Object.keys(buffer || {});
    if (!allKeys.length) return null;

    let bestKey = null;
    let bestScore = 0;

    for (const candidateKey of allKeys) {
        const score = longestCommonSubstringLength(
            targetKey.replace("_[]", ""),
            candidateKey.replace("_[]", "")
        );
        if (score > bestScore) {
            bestScore = score;
            bestKey = candidateKey;
        }
    }

    // If the match is too short, skip it
    if (bestScore < 3) return null;

    return bestKey;
}

function SearchResultsSkeleton() {
    const theme = useTheme();

    return (
        <Box sx={{p: 2}}>
            {[...Array(3)].map((_, idx) => (
                <Stack key={idx} direction="row" alignItems="center" spacing={2} sx={{mb: 2}}>
                    <Skeleton variant="circular" width={40} height={40}/>
                    <Skeleton variant="text" width="70%"/>
                </Stack>
            ))}

            <Stack direction="row" spacing={2} sx={{mb: 3}}>
                {[...Array(3)].map((_, idx) => (
                    <Skeleton
                        key={idx}
                        variant="rectangular"
                        width="100%"
                        height={100}
                        sx={{borderRadius: `${theme?.config?.card_radius}px`}}
                    />
                ))}
            </Stack>

            <Skeleton
                variant="rectangular"
                width="100%"
                height={60}
                sx={{borderRadius: `${theme?.config?.card_radius}px`}}
            />
        </Box>
    );
}

function NoSearchResults() {
    const {t} = useTranslation();
    return (
        <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{width: "100%", height: 300}}
        >
            <NoResults primary={t("common.sst_no_results")} icon="FileSearch01"/>
        </Stack>
    );
}

const SearchResultsList = memo(function SearchResultsList(props) {

    const {
        results,
        popover,
        isLoading,
        inputEl,
        onStoreSearch,
        hasAnyResults,
    } = props;

    const listRef = useRef(null);
    const {t} = useTranslation();
    const [currentlySelected, setCurrentlySelected] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const renderObjects = useRenderObjects(currentlySelected, popover.handleClose);
    const navigate = useNavigate();

    const handleNavigate = useCallback((link, newTab = false) => {
        popover.handleClose();
        if (newTab) {
            window.open(link, "_blank", "noopener,noreferrer");
        } else {
            navigate(link);
        }
    }, [navigate, popover]);

    const flattenedResults = useMemo(() => {
        const flatList = [];
        Object.keys(results).forEach(key => {
            const res = results[key];
            if (Array.isArray(res) && res.length > 0) {
                res.forEach(item => {
                    flatList.push({kind: key, data: item});
                });
            }
        });
        return flatList;
    }, [results]);

    const scrollToItem = (index) => {
        if (flattenedResults[index]) {
            const {kind, data} = flattenedResults[index];
            const list = listRef.current;
            if (list) {
                const item = list.querySelector(`[data-kind="${kind}"][data-id="${data?.id || data?.value || data?.path}"]`);
                if (item) {
                    item.scrollIntoView({block: 'nearest', behavior: 'smooth'});
                }
            }
        }
    };

    const handleKeyDown = useCallback((e) => {
        if (flattenedResults.length === 0) return;
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedIndex(prev => {
                const newIndex = (prev + 1) % flattenedResults.length;
                setCurrentlySelected(flattenedResults[newIndex]);
                scrollToItem(newIndex);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedIndex(prev => {
                const newIndex = (prev - 1 + flattenedResults.length) % flattenedResults.length;
                setCurrentlySelected(flattenedResults[newIndex]);
                scrollToItem(newIndex);
                return newIndex;
            });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            popover.handleClose();
            if (currentlySelected?.data?.object_link) {
                handleNavigate(currentlySelected?.data?.object_link);
                onStoreSearch(currentlySelected?.kind, currentlySelected?.data?.id);
            } else if (listRef.current) {
                const {kind, data} = currentlySelected;
                const item = listRef.current.querySelector(
                    `[data-kind="${kind}"][data-id="${data?.id || data?.value || data?.path}"]`
                );
                if (item) {
                    item.click();
                }
            }
        }
    }, [flattenedResults, currentlySelected, handleNavigate]);

    useEffect(() => {
        if (flattenedResults.length > 0) {
            setSelectedIndex(0);
            setCurrentlySelected(flattenedResults[0]);
            scrollToItem(0);
        } else {
            setSelectedIndex(-1);
            setCurrentlySelected(null);
        }
    }, [JSON.stringify(flattenedResults)]);

    useEffect(() => {
        const currentInput = inputEl.current;
        if (currentInput) {
            currentInput.addEventListener('keydown', handleKeyDown);
            return () => {
                currentInput.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [handleKeyDown]);

    useEffect(() => {
        if (listRef.current && Object.keys(results).length > 0) {
            listRef.current.scrollTo({top: 0, behavior: "smooth"});
        }
    }, [results]);

    if (!results) return null;

    return (
        <List sx={{p: 0, maxHeight: 600, overflowY: "auto"}} ref={listRef}>
            {/* Show skeleton if we're loading and have no fallback */}
            {isLoading && !hasAnyResults ? (
                <SearchResultsSkeleton/>
            ) : (
                <>
                    {hasAnyResults ? (
                        Object.keys(results).map((key) => {
                            const res = results[key];
                            if (Array.isArray(res) && res.length > 0) {
                                // "paths" is singled out for a slightly different render
                                if (key === "paths") {
                                    return (
                                        <React.Fragment key={key}>
                                            <Divider sx={{mt: 2}}/>
                                            {renderObjects("paths", res)}
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <React.Fragment key={key}>
                                            <Divider sx={{my: 2}}/>
                                            <ListItem>
                                                <ListItemText
                                                    primary={t("core.search." + key)}
                                                    primaryTypographyProps={{variant: "overline"}}
                                                />
                                            </ListItem>
                                            {renderObjects(key, res)}
                                        </React.Fragment>
                                    );
                                }
                            }
                            return null;
                        })
                    ) : (
                        <NoSearchResults/>
                    )}
                </>
            )}
        </List>
    );
});

function SearchDialog({popover, sx = {}}) {
    const {t} = useTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const {get, post} = useOmniaApi({autoError: false});
    const {hasRights} = useSecurityCheck();
    const configurationSettings = useGroonSettings();
    const [body, setBody] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [activeSearchFilters, setActiveSearchFilters] = useState([]);
    const [disabledSearchFilters, setDisabledSearchFilters] = useState([]);
    const inputEl = useRef(null);
    const requestIdRef = useRef(0);
    const disabledFiltersRef = useRef([]);
    disabledFiltersRef.current = disabledSearchFilters;

    const bufferedResults = useSelector((state) => state.account.searchResults);

    const softwareName = theme?.config?.software_name || "AIOS";

    const settingsRoutes = useMemo(() => {
        return configurationSettings?.flatMap((obj) => obj.settings)?.map((i) => {
            return {
                title: t(i?.label),
                icon: i?.icon,
                path: "/groon/home/settings#" + i?.value,
                permissions: i?.rights || [],
            };
        });
    }, [t, configurationSettings]);

    const specialRoutes = useMemo(() => {
        return [
            {
                title: t("layout.connections.contexts"),
                icon: "AlignHorizontalCentre01",
                path: "/groon/connections/contexts",
                permissions: ["crm_contexts"],
            },
            {
                title: t("layout.marketing.campaigns"),
                icon: "Announcement01",
                path: "/groon/marketing/campaigns",
                permissions: ["performance_marketing"],
            },
            {
                title: t("core.chat"),
                icon: "MessageChatSquare",
                path: "/groon/home/chat",
                permissions: ["social_intranet"],
            },
            {
                title: t("layout.assistants"),
                icon: "MessageSmileCircle",
                path: "/groon/home/assistants",
                permissions: [],
            },
            {
                title: t("layout.profile"),
                icon: "User01",
                path: "/groon/home/profile",
                permissions: ["social_intranet"],
            },
            {
                title: t("attributes.organization"),
                icon: "Building07",
                path: "/groon/home",
                permissions: ["manage_organization"],
            },
            {
                title: t("common.themes"),
                icon: "Brush03",
                path: "/groon/home/themes",
                permissions: ["theme_management"],
            },
        ].concat(settingsRoutes || []);
    }, [t, settingsRoutes]);

    const additionalApps = Object.values(APP_SETTING?.navigation || {});

    const transformNavigationData = useCallback((navigationConfig) => {
        const result = [];
        const seenPaths = new Set();

        const traverse = (item) => {
            if (item.title && item.path) {
                if (!seenPaths.has(item.path)) {
                    seenPaths.add(item.path);
                    result.push({
                        title: t(item.title),
                        icon: item.icon || "Link02",
                        path: item.path,
                        disabled: item?.disabled || false,
                        permissions: Array.isArray(item.permissions) ? item.permissions : [],
                    });
                }
            }
            if (Array.isArray(item.items)) {
                item.items.forEach((child) => traverse(child));
            }
        };

        if (Array.isArray(navigationConfig)) {
            navigationConfig.forEach(traverse);
        } else {
            Object.keys(navigationConfig).forEach((key) => traverse(navigationConfig[key]));
        }

        return result;
    }, [t]);

    const allSections = useMemo(() => {
        const navData = transformNavigationData({
            ...coreAppsConfig,
            ...additionalApps,
        });
        return navData
            .concat(additionalApps)
            .concat(specialRoutes)
            ?.filter((p) => p?.path);
    }, [transformNavigationData, specialRoutes]);

    const cacheKey = useMemo(() => {
        const enabledFilters = activeSearchFilters.filter(
            (item) => !disabledSearchFilters.includes(item)
        );
        return body + "_" + JSON.stringify(enabledFilters).replace(/\s+/g, "");
    }, [body, activeSearchFilters, disabledSearchFilters]);

    const storedResults = useMemo(() => {
        if (!bufferedResults || !cacheKey) {
            return {}
        }

        // (1) exact match
        if (bufferedResults[cacheKey]) {
            return bufferedResults[cacheKey];
        }
        // (2) fallback substring best match
        const bestKey = findBestBufferKeyMatch(cacheKey, bufferedResults);
        if (bestKey) {
            return bufferedResults[bestKey] || {};
        }
        return {};
    }, [bufferedResults, cacheKey]);

    const pathHits = useMemo(() => {
        return allSections?.filter((item) => {
            const rightsOkay = hasRights(item.permissions || []);
            return rightsOkay && item.title?.toLowerCase().includes(body.toLowerCase());
        });
    }, [allSections, body, hasRights]);

    const results = useMemo(() => {
        return {
            paths: pathHits?.slice(0, 8), // limit to 8
            ...storedResults,
        };
    }, [pathHits, storedResults]);

    const hasAnyResults = useMemo(() => {
        return Object.values(results).some((val) => Array.isArray(val) && val.length > 0);
    }, [results]);

    const handleSearch = useCallback(async (searchTerm, disabledFilters) => {
        if (!searchTerm) {
            // If the search is cleared, no need to do anything
            dispatch(registerSearchResults("", {}));
            return;
        }
        const currentRequestId = ++requestIdRef.current;
        try {
            const response = await get("setup/search", {
                search: searchTerm,
                disabled_object_filters: disabledFilters,
            });
            // Only apply if this is still the latest request
            if (currentRequestId === requestIdRef.current && response) {
                const searchActive = response?.active_object_filters || [];
                const searchDisabled = response?.disabled_object_filters || [];

                // If server responds with these filters, update them
                setActiveSearchFilters([...searchActive, ...searchDisabled]);

                const key = response?.query + "_" + JSON.stringify(searchActive).replace(/\s+/g, "");
                dispatch(registerSearchResults(key, response?.results));
            }
        } catch (err) {
            console.error("Search error", err);
        }
    }, [dispatch, get]);

    const debouncedSearch = useMemo(() => debounce((searchTerm, disabledFilters) => {
                handleSearch(searchTerm, disabledFilters);
            }, 500), [handleSearch]);

    const toggleDisableFilter = useCallback((filter) => {
            setDisabledSearchFilters((prev) => {
                // Check if it already exists
                if (prev.includes(filter)) {
                    return prev.filter((f) => f !== filter);
                } else {
                    return [...prev, filter];
                }
            });
        }, [setDisabledSearchFilters]);

    const handleChange = useCallback((event) => {
        const val = event.target.value;
        setBody(val);
        // Clear filters if empty
        if (!val) {
            setActiveSearchFilters([]);
            setDisabledSearchFilters([]);
        }
    }, []);

    const storeChosenSearchResult = useCallback((objectKind, objectId) => {
            post("setup/search_result", {
                kind: objectKind,
                id: objectId,
                query: body,
            }).catch((errors) => console.log("Error storing search usage:", errors));
        }, [post, body]);

    const handleDialogContentClick = useCallback((e) => {
            const clickable = e.target.closest("[data-kind][data-id]");
            if (clickable) {
                const objectKind = clickable.getAttribute("data-kind");
                const objectId = clickable.getAttribute("data-id");
                storeChosenSearchResult(objectKind, objectId);
                popover.handleClose();
            }
        }, [storeChosenSearchResult, popover]);

    useEffect(() => {
        if (!body) {
            setLoading(false);
            return;
        }
        setLoading(true);
        debouncedSearch(body, disabledFiltersRef.current);
    }, [body, debouncedSearch]);

    useEffect(() => {
        if (bufferedResults && body) {
            // If there's a stored result for this body, we can stop loading
            const enabledFilters = activeSearchFilters.filter(
                (item) => !disabledSearchFilters.includes(item)
            );
            const maybeKey = body + "_" + JSON.stringify(enabledFilters).replace(/\s+/g, "");
            if (bufferedResults[maybeKey]) {
                setLoading(false);
            }
        }
    }, [bufferedResults, body, activeSearchFilters, disabledSearchFilters]);

    useEffect(() => {
        if (popover.open && inputEl.current) {
            inputEl.current.focus();
            // Optionally select existing text
            if (body) {
                inputEl.current.setSelectionRange(0, body.length);
            }
        }
    }, [popover.open]);

    useEffect(() => {
        // Some results have focus() themselves and "steal" it from the input field
        if(popover.open && inputEl.current)
            inputEl.current.focus();
    }, [popover.open, JSON.stringify(results)])

    return (
        <Dialog
            open={popover.open}
            onClose={popover.handleClose}
            maxWidth="sm"
            fullWidth={true}
            PaperProps={{sx: {borderRadius: "28px"}}}
            keepMounted
            disableAutoFocus={false}
            disableEnforceFocus={false}
        >
            <DialogContent sx={{p: 0}} onClick={handleDialogContentClick}>
                <Box sx={{p: 1}}>
                    <OutlinedInput
                        fullWidth
                        autoFocus
                        inputRef={inputEl}
                        value={body}
                        onChange={handleChange}
                        sx={{height: 45, borderRadius: "25px", ...sx}}
                        placeholder={softwareName + " " + t("common.search")}
                        endAdornment={
                            <InputAdornment position="end">
                                <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                                    {activeSearchFilters
                                        ?.sort((a, b) => a.localeCompare(b))
                                        .map((filter) => (
                                            <Chip
                                                key={"filter-" + filter}
                                                onDelete={() => {
                                                    toggleDisableFilter(filter);
                                                    // Trigger new search right away:
                                                    debouncedSearch(body, [
                                                        ...disabledFiltersRef.current,
                                                        filter,
                                                    ]);
                                                }}
                                                color={
                                                    disabledSearchFilters?.includes(filter) ? "default" : "primary"
                                                }
                                                deleteIcon={
                                                    <Tooltip
                                                        title={
                                                            disabledSearchFilters.includes(filter)
                                                                ? t("common.activate")
                                                                : t("common.deactivate")
                                                        }
                                                    >
                                                        {disabledSearchFilters.includes(filter) ? (
                                                            <OnIcon iconName="CheckCircle"/>
                                                        ) : (
                                                            <OnIcon iconName="XCircle"/>
                                                        )}
                                                    </Tooltip>
                                                }
                                                label={filter}
                                            />
                                        ))}

                                    <Button
                                        color="default"
                                        variant="contained"
                                        sx={{minWidth: 40, height: 25, fontSize: 12}}
                                        onClick={popover.handleClose}
                                    >
                                        esc
                                    </Button>
                                </Stack>
                            </InputAdornment>
                        }
                    />
                </Box>

                {body !== "" && (
                    <SearchResultsList
                        popover={popover}
                        inputEl={inputEl}
                        results={results}
                        onStoreSearch={storeChosenSearchResult}
                        isLoading={isLoading}
                        hasAnyResults={hasAnyResults}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
}

SearchResultsList.propTypes = {
    results: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    hasAnyResults: PropTypes.bool,
    renderObjects: PropTypes.func.isRequired,
};

SearchDialog.propTypes = {
    popover: PropTypes.shape({
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    }).isRequired,
    sx: PropTypes.object,
};

export default SearchDialog;
