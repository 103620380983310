import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Box,
    IconButton, InputAdornment,
    ListItem,
    ListItemButton,
    Menu, MenuItem, OutlinedInput,
    Stack, Tooltip,
    Typography
} from "@mui/material";
import OverviewList from "../../elements/overview-list";
import {useLocation, useNavigate} from "react-router-dom";
import SmartPopper from "../../elements/smart-popper";
import PropTypes from "prop-types";
import {useSettings} from "../../../hooks/use-settings";
import {useIsMobile} from "../../../hooks/use-is-mobile";
import {MoreHoriz as MoreVertIcon} from "@mui/icons-material";
import ShareButton from "../../elements/sharing/share-button";
import RemoveSharableObject from "../../elements/sharing/remove-sharable-object";
import OnIcon from "../../elements/icon";
import useApi from "../../../hooks/use-api";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useAppMode} from "../../../hooks/use-app-mode";

const ChatListItem = ({chat, onOpen, onChange, onRemove, ...rest}) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const optionsRef = useRef(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [updatedName, setUpdatedName] = useState(chat?.name);
    const { put } = useApi();

    const handleOpenMenu = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpen(true);
    }

    const handleKeyInput = (event) => {
        if(event.key === 'Enter'){
            handleSave();
        }
    }

    const handleChangeName = (event) => {
        setUpdatedName(event.target.value);
    }

    const handleSave = () => {
        setIsSaving(true);
        put('core/assistant-chats', {id: chat.id, name: updatedName}).then((updatedChat) => {
            setIsEditing(false);
            onChange(updatedChat);
        }).finally(() => {
            setIsSaving(false);
        });
    }

    const handleCloseMenu = (event) => {
        if(event){
            event.preventDefault();
            event.stopPropagation();
        }
        setOpen(false);
    }

    const handleRemove = () => {
        handleCloseMenu();
        onRemove?.(chat.id);
    }

    const handleRename = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsEditing(true);
        handleCloseMenu();
    }

    const handleChatClick = (event) => {
        if(open)
            return;
        event.preventDefault();
        event.stopPropagation();
        onOpen(chat?.id);
    }

    return (
        <>
            {isEditing ? (
                <Box p={1}>
                    <OutlinedInput
                        fullWidth
                        size='small'
                        autoFocus
                        defaultValue={chat?.name}
                        onChange={handleChangeName}
                        onKeyDown={handleKeyInput}
                        endAdornment={(
                            <InputAdornment position="end">
                                <IconButton onClick={handleSave} disabled={isSaving}>
                                    <OnIcon iconName="Check" size="small" />
                                </IconButton>
                            </InputAdornment>
                        )}
                    />
                </Box>
            ) : (
                <ListItem
                    sx={{p: 0}}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={handleChatClick}
                    {...rest}
                >
                    <ListItemButton>
                        <Typography color='text.primary' variant="subtitle1">{chat?.name}</Typography>
                    </ListItemButton>
                    {hover && (
                        <div style={{position: 'absolute', right: 5, top: '50%', transform: 'translateY(-50%)'}}>
                            <IconButton
                                aria-controls={"chat-menu-" + chat.id}
                                ref={optionsRef}
                                aria-haspopup="true"
                                onClick={(event) => handleOpenMenu(event)}
                                size="small"
                            >
                                <MoreVertIcon/>
                            </IconButton>
                            <Menu
                                id={"chat-menu-" + chat.id}
                                keepMounted
                                anchorEl={optionsRef.current}
                                open={open}
                                onClose={handleCloseMenu}
                            >
                                {open && (
                                    <ShareButton
                                        entity={chat}
                                        withIcon={true}
                                        endpoint='core/assistant-chats'
                                        onClose={handleCloseMenu}
                                    />
                                )}
                                {chat?.can_edit && (
                                    <Tooltip title={t('common.tooltips.rename_this_chat')} placement="left">
                                        <MenuItem onClick={handleRename}>
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <OnIcon iconName="Edit01" size="small" />
                                                <Typography>
                                                    {t('common.rename')}
                                                </Typography>
                                            </Stack>
                                        </MenuItem>
                                    </Tooltip>
                                )}
                                <RemoveSharableObject
                                    entity={chat}
                                    withIcon={true}
                                    endpoint='core/assistant-chats'
                                    onRemove={handleRemove}
                                />
                            </Menu>
                        </div>
                    )}
                </ListItem>
            )}
        </>
    )
}

function HistoryPopover({popover, onClick, posPercentage, ...other}){
    const navigate = useNavigate();
    const { isFlying } = useIsMobile();
    const { t } = useTranslation();
    const location = useLocation();
    const { post } = useOmniaApi();
    const appMode = useAppMode();
    const [ trigger, setTrigger ] = useState(0);

    const handleOpenChat = (chatId) => {
        navigate('/groon/home/ai-chats/' + chatId);
        popover.handleClose();
        onClick?.();
    }

    const handleRemoveAll = () => {
        if(window.confirm(t('notify.are_you_sure'))){
            post('core/assistant-chats/remove_all_chats').then(() => {
                setTrigger(prev => prev + 1);
            })
        }
    }

    useEffect(() => {
        popover.handleClose();
    }, [location.pathname]);

    return (
        <SmartPopper
            popover={popover}
            placement={isFlying ? 'right' : 'bottom-left'}
            minWidth={250}
            desktopPopover={true}
            sx={(appMode.layout === 'vertical') ? {p: 0, borderRadius: 0} : {}}
            {...other}
        >
            <OverviewList
                endpoint='core/assistant-chats'
                query={{assistant__isnull: false, thread_of__isnull: true, ordering: '-last_message_at', i: trigger}}
                pageSize={50}
                autoFocusSearch={false}
                withSearch={true}
                groupByTime='created_at'
                height={appMode.layout === 'vertical' ? '100vh' : '50vh'}
                topItem={(
                    <Stack sx={{p: 2}} direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">
                            Chats
                        </Typography>
                        <Stack direction="row">
                            <IconButton onClick={handleRemoveAll} size="small">
                                <OnIcon size="small" iconName="Trash01" />
                            </IconButton>
                            <IconButton onClick={popover.handleClose} size="small">
                                <OnIcon size="small" iconName="XClose" />
                            </IconButton>
                        </Stack>
                    </Stack>
                )}
                renderItem={(item, index, setElements) => (
                    <ChatListItem
                        chat={item}
                        key={index}
                        onChange={(updatedItem) => setElements(prev => {
                            return prev.map((element) => {
                                if(element.id === updatedItem.id){
                                    return updatedItem;
                                }
                                return element;
                            });
                        })}
                        onRemove={(chatId) => {
                            setElements(prev => prev.filter((element) => element.id !== chatId));
                        }}
                        onOpen={handleOpenChat}
                    />
                )}
            />
        </SmartPopper>
    )

}

HistoryPopover.propTypes = {
    popover: PropTypes.object,
    posPercentage: PropTypes.number,
    onClick: PropTypes.func
}

ChatListItem.propTypes = {
    chat: PropTypes.object.isRequired,
    onOpen: PropTypes.func,
    onRemove: PropTypes.func
}

export default HistoryPopover;