import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Avatar, Box,
    Checkbox,
    Chip, CircularProgress, InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, OutlinedInput,
    Stack, SvgIcon,
    Typography
} from "@mui/material";
import OnIcon from "../icon";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import SearchMdIcon from "@untitled-ui/icons-react/build/esm/SearchMd";
import NoResults from "../no-results";

function SelectList(props) {

    const {
        options,
        onSelect,
        onDeselect,
        listStyles = {},
        values,
        noResultsWarning = null,

        // Misc
        loading = false,

        // Height
        height,
        maxHeight = 450,
        minHeight = null,

        // Search
        withSearch = false,
        autoFocusSearch = true,

        ...rest
    } = props;

    const theme = useTheme();
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const { t } = useTranslation();

    const handleSearchChange = (event) => {
        setQuery(event.target.value);
    }

    const renderChip = (element) => {
        return element?.chip ? (typeof element?.chip === 'string' ? (
            <Chip
                size="small"
                disabled={element?.disabled}
                label={element?.chip}
            />
        ) : element?.chip) : null
    }

    useEffect(() => {
        setResults(options.filter(option => (option?.title + ' ' + option?.subheader + ' ' + option?.chip).toLowerCase().includes(query.toLowerCase())));
    }, [options, JSON.stringify(query)]);

    if (loading)
        return (
            <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    ...(height
                        ? { height: height === 'auto' ? 400 : height }
                        : maxHeight
                            ? { height: maxHeight < 400 ? maxHeight : 400 }
                            : { height: minHeight > 400 ? minHeight : 400 }),
                    ...(minHeight ? { minHeight } : {}),
                }}
                {...rest}
            >
                <CircularProgress size={20} />
            </Stack>
        )

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ...(height ? { height } : { maxHeight }),
                    ...(minHeight ? { minHeight } : {}),
                }}
            >
                {withSearch && (
                    <Box p={2}>
                        <OutlinedInput
                            defaultValue=""
                            fullWidth
                            sx={{ height: 45 }}
                            autoFocus={autoFocusSearch}
                            size="small"
                            inputProps={{ onChange: handleSearchChange }}
                            name="itemName"
                            placeholder={t('common.search')}
                            startAdornment={(
                                <InputAdornment position="start">
                                    <SvgIcon fontSize="small">
                                        <SearchMdIcon />
                                    </SvgIcon>
                                </InputAdornment>
                            )}
                        />
                    </Box>
                )}
                {results?.length === 0 ? (
                    <Box sx={{ flexGrow: 1 }}>
                        <NoResults
                            primary={noResultsWarning || t("common.sst_no_results")}
                            icon="SearchRefraction"
                            justifyContent="center"
                            alignItems="center"
                            sx={{ width: '100%', height: '100%', textAlign: 'center' }}
                        />
                    </Box>
                ) : (
                    <List sx={{ flexGrow: 1, overflow: 'auto', ...listStyles }} {...rest}>
                        {results?.map(element => (
                            <ListItem
                                key={element?.value}
                                disablePadding
                                secondaryAction={element?.avatar ? (
                                    <Checkbox
                                        edge="end"
                                        checked={values?.includes(element?.value)}
                                        tabIndex={-1}
                                        disableRipple
                                    />
                                ) : renderChip(element)}
                            >
                                <ListItemButton
                                    disabled={element?.disabled}
                                    selected={values?.includes(element?.value)}
                                    onClick={() => {
                                        if(values?.includes(element?.value)) {
                                            onDeselect(element);
                                        } else {
                                            onSelect(element);
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <Stack direction="row" spacing={1}>
                                            {element?.avatar ? typeof element?.chip === 'string' ? (
                                                <Avatar sx={{width: 30, height: 30}} src={element?.avatar?.view} />
                                            ) : element?.avatar : (
                                                <Checkbox
                                                    edge="start"
                                                    checked={values?.includes(element?.value)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                />
                                            )}
                                        </Stack>
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={(
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="h6">
                                                    {element?.title}
                                                </Typography>
                                                {element?.avatar && renderChip(element)}
                                            </Stack>
                                        )}
                                        primaryTypographyProps={{
                                            variant: 'h6'
                                        }}
                                        secondary={element?.subheader}
                                        secondaryTypographyProps={{
                                            noWrap: true,
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
        </>
    );
}

SelectList.propTypes = {

    // Core
    options: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onDeselect: PropTypes.func.isRequired,
    noResultsWarning: PropTypes.string,

    // Advanced
    listStyles: PropTypes.object,
    withSearch: PropTypes.bool,
    loading: PropTypes.bool,
    autoFocusSearch: PropTypes.bool,

    // Height
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minHeight: PropTypes.number,
    maxHeight: PropTypes.number,

};

export default SelectList;
