import React, {useMemo} from 'react';
import {
    Dialog,
    Stack,
    DialogContent,
    TextField,
    Button,
    DialogActions,
    Typography,
    DialogTitle
} from '@mui/material';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import SaveButton from "../../../elements/save-button";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import ShareWithGroupStack from "../../../elements/sharing/share-with-group-stack";

const DashboardSchema = Yup.object().shape({
    name: Yup.string()
        .max(40, 'Dashboard name too long')
        .required('Dashboard name is required'),
});

const DashboardDialog = ({ open, handleClose, onSubmit, initialData }) => {
    const isEditMode = Boolean(initialData);
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();

    const stableInitialData = useMemo(() => initialData ? initialData : {}, [JSON.stringify(initialData)]);

    const formik = useFormik({
        initialValues: stableInitialData,
        validationSchema: DashboardSchema,
        enableReinitialize: true,
        onSubmit: onSubmit
    });

    return (
        <Dialog fullScreen={isMobile} open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <Typography variant="h4">
                    {isEditMode ? t('core.analytics.rename') : t('core.analytics.new_dashboard')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    onChange={formik.handleChange}
                    margin="dense"
                    value={formik.values.name}
                    name="name"
                    label={t('attributes.name')}
                    fullWidth
                    error={formik.errors.name && formik.touched.name}
                    helperText={formik.errors.name && formik.touched.name && formik.errors.name}
                />
                <ShareWithGroupStack formik={formik} />
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    <Button onClick={handleClose} color="primary" variant="outlined">
                        {t('common.cancel')}
                    </Button>
                    <SaveButton formik={formik} />
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

DashboardDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    onSubmit: PropTypes.func,
    initialData: PropTypes.object
};

export default DashboardDialog;
