import React, {useEffect, useState, memo} from "react";
import {
    Badge,
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Fab,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Typography
} from "@mui/material";
import ChannelIcon from "@untitled-ui/icons-react/build/esm/MessageChatSquare";
import {useDispatch, useSelector} from "react-redux";
import ChatWindow from "./ChatWindow";
import PropTypes from "prop-types";
import {useIsMobile} from "src/omnia/hooks/use-is-mobile";
import {updateAssistantOpen} from "src/omnia/store/actions/messages-actions";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import useOmniaApi from "../../../../../hooks/use-omnia-api";
import {useAppMode} from "../../../../../hooks/use-app-mode";
import {ChatMessageAdd} from "../chat/chat-message-add";
import ChatAssistantViewer from "../../assistant/chat-assistant-viewer";
import CloseIcon from "@untitled-ui/icons-react/build/esm/XClose";
import Avatar from "@mui/material/Avatar";
import BlurredBox from "../../../../elements/blurred-box";
import {useSettings} from "../../../../../hooks/use-settings";

function ChatBarContent({miniMessages, thread, handleOpenChat}){

    const {user} = useSelector((state) => state.account);
    const [tooltip, setTooltip] = useState("")

    useEffect(() => {
        if(thread.users.length > 2){
            setTooltip(thread['title']);
        } else {
            const notUser = thread.users.filter(u => u.id !== user.id);
            if(notUser.length > 0){
                setTooltip(notUser[0].name);
            } else {
                setTooltip('');
            }
        }
    }, [thread, user]);

    if(thread.users.length > 2){
        return (
            <Tooltip enterDelay={1000}
                title={thread['title']}
                placement="left"
            >
                <Badge
                    color="error"
                    badgeContent={thread['unread_count']}
                    overlap="circular"
                    sx={{
                        '& .MuiBadge-badge': {
                            zIndex: 1351
                        }
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Fab
                        color="primary"
                        onClick={() => handleOpenChat(thread)}
                        aria-label="add"
                    >
                        <ChannelIcon />
                    </Fab>
                </Badge>
            </Tooltip>
        )
    }

    return (
        <Tooltip enterDelay={1000}
            title={tooltip}
            placement="left"
        >
            <Badge
                color="error"
                badgeContent={thread['unread_count']}
                overlap="circular"
                sx={{
                    '& .MuiBadge-badge': {
                        zIndex: 1351
                    }
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Fab
                    color="primary"
                    onClick={() => handleOpenChat(thread)}
                    aria-label="add"
                >
                    <Avatar
                        src={thread.users.filter(u => u.id !== user.id)?.[0]?.avatar?.view || null}
                        to={'/groon/home/profile/' + thread.users.filter(u => u.id !== user.id)?.[0]?.id}
                        sx={{width: 45, height: 45}}
                    >

                    </Avatar>
                </Fab>
            </Badge>
        </Tooltip>
    )

}

const AssistantChatWindow = memo(() => {

    const theme = useTheme();
    const { t } = useTranslation();
    const chatOpen = useSelector(state => state.messages.assistantOpen);
    const dispatch = useDispatch();
    const user = useSelector(state => state.account.user);
    const { uploadStatus, post } = useOmniaApi({autoError: false});
    const [ isSending, setIsSending ] = useState(false);
    const [ name, setName ] = useState('AIOS Assistent');
    const settings = useSettings();
    const appMode = useAppMode();
    const { isMobile } = useIsMobile();

    const handleCloseChat = () => {
        dispatch(updateAssistantOpen(false));
    }

    const handleSend = (values) => {
        return new Promise((resolve, reject) => {
            setIsSending(true);
            post('core/assistant-chats/' + user?.current_assistant_chat + '/new_message', values).then(message => {
                resolve(message);
            }).catch(errors => {
                reject(errors);
            }).finally(() => {
                setIsSending(false);
            });
        });
    }

    const handleStopTyping = () => {
        // TODO: send this in the chat channel
    }

    const handleTyping = () => {
        // TODO: send this in the chat channel
    }

    if(!chatOpen || !isMobile)
        return null;

    const ChatHeader = (props) => {
        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                {...props}
            >
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Typography
                        variant="h6"
                        color="text.primary"
                    >
                        {name || 'AIOS Assistent'}
                    </Typography>
                </Stack>
                <IconButton size="small" onClick={handleCloseChat}>
                    <SvgIcon fontSize="small">
                        <CloseIcon />
                    </SvgIcon>
                </IconButton>
            </Stack>
        )
    }

    const ChatFooter = (props) => {
        return (
            <ChatMessageAdd
                placeholder={t('core.how_can_i_help')}
                onSend={handleSend}
                size="small"
                uploadStatus={uploadStatus}
                smallVersion={true}
                sending={isSending}
                onTyping={handleTyping}
                onStopTying={handleStopTyping}
                {...props}
            />
        )
    }

    if(isMobile)
        return (
            <Dialog
                open={true}
                fullScreen={true}
            >
                <DialogTitle>
                    <ChatHeader sx={{height: '100%', width: '100%'}} />
                </DialogTitle>
                <Divider />
                <DialogContent style={{paddingLeft: 0, paddingRight: 0}}>
                    <ChatAssistantViewer
                        onAssistantNameChange={name => setName(name)}
                        smallVersion={true}
                        sx={{pb: 5, pt: 10}}
                    />
                </DialogContent>
                <BlurredBox sx={{position: 'fixed', bottom: 0, height: 70, p: 0.5, width: '100%'}}>
                    <ChatFooter />
                </BlurredBox>
            </Dialog>
        )

    return (
        <Card style={{
            position: 'fixed',
            backgroundColor: theme.palette.background.default,
            width: 350,
            zIndex: 1500,
            bottom: 30,
            right: appMode.layout === 'vertical' ? 30 : 78,
            boxShadow: theme.shadows[21],
        }}>
            <ChatHeader sx={{p: 2}} />
            <Divider />
            <ChatAssistantViewer
                onAssistantNameChange={name => setName(name)}
                smallVersion={true}
                height={400}
            />
            <ChatFooter />
        </Card>
    );

}, () => true);

function ChatBar() {
    const user = useSelector((state) => state.account.user);
    const { isBackend } = useAppMode();

    if (!user || !isBackend)
        return null;

    return (
        <>
            <ChatWindow />
            <AssistantChatWindow />
        </>
    )
}

ChatBarContent.propTypes = {
    miniMessages: PropTypes.array,
    thread: PropTypes.object,
    handleOpenChat: PropTypes.func
}

export default ChatBar;