import {
    Button, Card, CardContent, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    Switch,
    TextField, Tooltip,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import useIntelligenceFormik from "../../../../hooks/formik/use-intelligence-formik";
import SmartSelector from "../../../elements/smart-selector";
import {useTranslation} from "react-i18next";
import SaveButton from "../../../elements/save-button";
import {produce} from "immer";
import {Box} from "@mui/system";
import {useFormik} from "formik";
import * as Yup from "yup";
import AiModelImage from "../ai-model-image";
import AiModelSelector from "../ai-model-selector";


function AgentDialog({ agent, onClose, open, ...rest }) {
    const { t } = useTranslation();
    const { put } = useOmniaApi();
    const [isEditing, setIsEditing] = useState(!agent);

    useEffect(() => {
        setIsEditing(!agent);
    }, [agent]);

    const onSubmit = (values, helpers) => {
        put('ai/agents', values).then((data) => {
            helpers.resetForm({values: data});
        }).finally(() => {
            helpers.setSubmitting(false);
            setIsEditing(false);
            onClose();
        });
    }

    const agentFormik = useCallback(useFormik({
        initialValues: agent ? produce(agent, draft => {
            if (draft) {
                draft.llm = draft.llm?.id;
                draft.actions = draft.actions?.map(action => action.id);
            }
        }) : {
            name: '',
            description: '',
            briefing: '',
            llm: null,
            actions: [],
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('form.is_required', { field: t('attributes.name') })),
            description: Yup.string().nullable(),
            briefing: Yup.string().nullable(),
            llm: Yup.number().nullable().required(t('form.is_required', { field: t('attributes.ai_model') })),
            actions: Yup.array().nullable(),
        }),
        onSubmit: onSubmit
    }), [agent, onSubmit]);

    const handleClose = () => {
        setIsEditing(false);
        agentFormik.resetForm();
        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack
                        direction="column"
                        spacing={1}
                        // justifyContent="flex-end"
                        alignItems="left"
                    >
                        <Typography variant="h4">
                            {isEditing ? t('common.edit') : agentFormik.values?.name}
                        </Typography>
                        {isEditing && (
                            <Typography variant="subtitle1">
                                {t('intelligence.agents.edit_agent_description')}
                            </Typography>
                        )}
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                        alignItems="center"
                    >

                    </Stack>
                </Stack>
            </DialogTitle>
            <DialogContent
                style={{
                    height: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 0,
                }}
            >
                {isEditing ? (
                    <Box mt={4} padding={2}>
                        <Grid container spacing={2} sx={{mt: 2, mb: 2}}>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{mt: 2}}
                                    autoFocus={true}
                                    fullWidth
                                    label={t('attributes.name')}
                                    {...agentFormik.getFieldProps('name')}
                                    value={agentFormik.values.name}
                                    error={agentFormik.touched.name && Boolean(agentFormik.errors.name)}
                                    helperText={agentFormik.touched.name && agentFormik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{mt: 2}}
                                    autoFocus={true}
                                    fullWidth
                                    label={t('attributes.description')}
                                    {...agentFormik.getFieldProps('description')}
                                    value={agentFormik.values.description}
                                    error={agentFormik.touched.description && Boolean(agentFormik.errors.description)}
                                    helperText={agentFormik.touched.description && agentFormik.errors.description}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{mt: 2}}
                                    autoFocus={true}
                                    fullWidth
                                    multiline={true}
                                    rows={4}
                                    label={t('attributes.briefing')}
                                    {...agentFormik.getFieldProps('briefing')}
                                    value={agentFormik.values.briefing}
                                    error={agentFormik.touched.briefing && Boolean(agentFormik.errors.briefing)}
                                    helperText={agentFormik.touched.briefing && agentFormik.errors.briefing}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <AiModelSelector
                                    formik={agentFormik}
                                    field={'llm'}
                                    // label={t('attributes.ai_model')}
                                />
                                {/*<SmartSelector*/}
                                {/*    endpoint='ai/ai_models'*/}
                                {/*    values={agentFormik.values.llm}*/}
                                {/*    handleChange={agentFormik.handleChange}*/}
                                {/*    multiple={false}*/}
                                {/*    name='llm'*/}
                                {/*    label={t('attributes.ai_model')}*/}
                                {/*/>*/}
                            </Grid>
                            <Grid item xs={6}>
                                <SmartSelector
                                    endpoint='core/workflow-actions'
                                    query={{for_assistants: true}}
                                    values={agentFormik.values.actions}
                                    handleChange={agentFormik.handleChange}
                                    multiple={true}
                                    name='actions'
                                    label={t('attributes.actions')}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Stack
                        direction="column"
                        spacing={2}
                        padding={2}
                        sx={{width: '100%'}}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Typography variant="h6">
                                {t('attributes.description')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Typography variant="subtitle1">
                                {agent?.description}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Typography variant="h6">
                                {t('attributes.briefing')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Typography variant="subtitle1">
                                {agent?.briefing || t('intelligence.agents.no_briefing')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Typography variant="h6">
                                {t('attributes.ai_model')}
                            </Typography>
                        </Stack>
                        {agent?.llm ? (
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <AiModelImage aiModel={agent?.llm} sizePixels={15} />
                                <Typography variant="body1" color="textPrimary">
                                    {agent?.llm?.name}
                                </Typography>
                            </Stack>
                        ) : (
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="body1" color="textSecondary">
                                    {t('intelligence.assistants.no_language_model_set')}
                                </Typography>
                            </Stack>
                        )}
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Typography variant="h6">
                                {t('attributes.actions')}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            {agent?.actions?.map(action => (
                                <Chip key={action.id} label={action.name} />
                            ))}
                        </Stack>
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                {isEditing ? (
                    <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                        <Button onClick={handleClose} variant="outlined" color="primary">
                            {t("common.cancel")}
                        </Button>
                        <SaveButton
                            formik={agentFormik}
                        />
                    </Stack>
                ) : (
                    <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                        <Button onClick={handleClose} variant="outlined" color="primary">
                            {t("common.cancel")}
                        </Button>
                        <Button onClick={() => setIsEditing(true)} variant="contained" color="primary">
                            {t("common.edit")}
                        </Button>
                    </Stack>
                )}
            </DialogActions>

        </Dialog>

    );
}

export default AgentDialog;
