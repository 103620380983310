import React, { useState } from "react";
import {
    Pagination,
    Box,
    Grid,
    Stack,
    Typography, Fade
} from "@mui/material";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import usePagination from "../../../hooks/use-pagination";
import {LoadingButton, Skeleton} from "@mui/lab";
import {APP_SETTING} from "../../../../setup";
import useGenerateVisibility from "../../../hooks/use-generate-visibility";
import OnIcon from "../icon";
import NoResults from "../no-results";

function TilesOverview(props) {

    const {
        endpoint,
        query = {},
        isVisible = null,
        dense = false,
        veryDense = false,
        wide = false,
        variant = 'pagination',
        renderTile,
        onlyShowIfNotEmpty = false,
        showLoading = true,
        loadingCardHeight = 200,
        loadingCardAmount = 5,
        pageSize = 12,
        spacing = 2,
        delay = 25,
        noResultsWarning = null,
        ...rest
    } = props;

    const theme = useTheme();
    const {t} = useTranslation();
    const [page, setPage] = useState(1);
    const { elements, initialLoading, loading, pages, isMore } = usePagination({endpoint, page, query, pageSize});
    const { visible } = useGenerateVisibility(elements?.length || 0, delay);
    const loadingVisibilities = useGenerateVisibility(loadingCardAmount, 0);

    const loadingCards = Array.from({length: loadingCardAmount}, () => ({}));

    if (initialLoading && !onlyShowIfNotEmpty)
        if(showLoading){
            return (
                <Grid
                    container={true}
                    spacing={2}
                >
                    {loadingCards.map((element, index) => (
                        <Fade
                            key={index}
                            in={loadingVisibilities.visible[index]}
                            timeout={APP_SETTING?.transitionDuration || 500}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={wide ? 6 : (dense ? 4 : (veryDense ? 3 : 6))}
                                xl={wide ? 6 : (dense ? 3 : (veryDense ? 2 : 4))}
                            >
                                <Skeleton variant="rounded" sx={{height: loadingCardHeight}} />
                            </Grid>
                        </Fade>
                    ))}
                </Grid>
            )
        } else {
            return null;
        }

    if(onlyShowIfNotEmpty && elements.length === 0) return null;

    return (
        <Box id='tiles-overview' {...rest}>
            {elements.length === 0 ? (
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent='center'
                    sx={{height: '100%', width: '100%', minHeight: 300}}
                >
                    <NoResults
                        primary={noResultsWarning || t('common.sst_no_results')}
                        icon="SearchRefraction"
                    />
                </Stack>
            ) : (
                <Grid
                    container={true}
                    spacing={spacing}
                >
                    {elements.filter(e => isVisible ? isVisible(e) : true).map((element, index) => (
                        <Fade
                            key={index}
                            in={visible[index]}
                            timeout={APP_SETTING?.transitionDuration || 500}
                        >
                            <Grid
                                item={true}
                                xs={12}
                                sm={6}
                                // md={4}
                                lg={wide ? 6 : (dense ? 4 : (veryDense ? 3 : 6))}
                                xl={wide ? 6 : (dense ? 3 : (veryDense ? 2 : 4))}
                            >
                                {renderTile(element, index)}
                            </Grid>
                        </Fade>
                    ))}

                    {(variant === 'pagination' && (pages > 1)) && (
                        <Grid item xs={12}>
                            <Pagination
                                count={pages}
                                page={page}
                                defaultPage={1}
                                onChange={(e, value) => setPage(value)}
                            />
                        </Grid>
                    )}

                    {(variant === 'loadMore' && isMore) && (
                        <Grid item xs={12} sx={{mt: 4}}>
                            <Stack spacing={2} direction="row" justifyContent="center">
                                <LoadingButton
                                    variant="contained"
                                    onClick={() => setPage(page + 1)}
                                    loading={loading}
                                >
                                    {t('common.load_more')}
                                </LoadingButton>
                            </Stack>
                        </Grid>
                    )}

                </Grid>
            )}

        </Box>
    )
}

TilesOverview.propTypes = {
    endpoint: PropTypes.string.isRequired,
    renderTile: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(["loadMore", "pagination"]),
    query: PropTypes.object,
    pageSize: PropTypes.number,
    delay: PropTypes.number,
    loadingCardHeight: PropTypes.number,
    loadingCardAmount: PropTypes.number,
    noResultsWarning: PropTypes.string,
    isVisible: PropTypes.func,
    spacing: PropTypes.number,
    dense: PropTypes.bool,
    veryDense: PropTypes.bool,
    onlyShowIfNotEmpty: PropTypes.bool,
    showLoading: PropTypes.bool,
    wide: PropTypes.bool,
}

export default TilesOverview;