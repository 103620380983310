import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Box, Divider,
    Slide,
    Stack,
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {useLocation} from "react-router";
import {useIsMobile} from "src/omnia/hooks/use-is-mobile";
import {FlyingNavSection} from "./flying-nav-section";
import {TopNav} from "../flying-layout/top-nav";
import {useSelector} from "react-redux";
import {useSettings} from "../../../../hooks/use-settings";

export const FlyingNav = (props) => {
    const theme = useTheme();
    const {sections = [], onMobileNavOpen, mobileNavActive} = props;
    const {navBarMode, isMobile} = useIsMobile();
    const location = useLocation();
    const settings = useSettings();
    const [selectedMode, setSelectedMode] = useState(sections?.[0] || null);
    const [showTopNav, setShowTopNav] = useState(false);
    const [position, setPosition] = useState(navBarMode);
    const chatOpen = useSelector((state) => state.messages.assistantOpen);
    const [verticalPosition, setVerticalPosition] = useState(chatOpen);

    const styles = {
        "evident": {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.neutral[900] : theme.palette.background.evident,
            borderColor: 'transparent',
            disabledColor: theme.palette.mode === 'dark' ? theme.palette.neutral[500] : theme.palette.neutral[500],
            color: theme.palette.common.white,
        },
        "discreet": {
            backgroundColor: theme.palette.background.discrete,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.neutral[700] : theme.palette.neutral[200],
            disabledColor: theme.palette.mode === 'dark' ? theme.palette.neutral[600] : theme.palette.neutral[400],
            color: theme.palette.mode === 'dark' ? theme.palette.neutral[100] : theme.palette.text.primary,
        },
        "blend-in": {
            backgroundColor: theme.palette.background.default,
            borderColor: theme.palette.mode === 'dark' ? theme.palette.neutral[700] : theme.palette.neutral[200],
            disabledColor: theme.palette.mode === 'dark' ? theme.palette.neutral[600] : theme.palette.neutral[400],
            color: theme.palette.mode === 'dark' ? theme.palette.neutral[100] : theme.palette.text.primary,
        }
    }

    useEffect(() => {
        if (sections?.filter(s => location.pathname?.includes(s?.path))?.length === 1) {
            setSelectedMode(sections?.filter(s => location.pathname?.includes(s?.path))?.[0]);
        }
    }, [location.pathname, sections]);

    useEffect(() => {
        setShowTopNav(false);
        const timer = setTimeout(() => {
            setShowTopNav(true);
            setPosition(navBarMode);
            setVerticalPosition(chatOpen);
        }, 500);
        return () => clearTimeout(timer);
    }, [navBarMode, chatOpen]);

    return (
        <>
            <TopNav
                onMobileNavOpen={onMobileNavOpen}
                mobileNavActive={mobileNavActive}
                styles={styles}
            />
            {['flying', 'flying-topbar'].includes(position) && (

                <Box
                    sx={{
                        position: 'fixed',
                        borderRadius: '30px',
                        left: '14px',
                        top: (position === 'flying') ? verticalPosition ? 'calc(50% - 200px)' : '50%' : '14px',
                        transform: (position === 'flying') ? 'translateY(-50%)' : 'none',
                        backdropFilter: 'blur(6px)',
                        zIndex: (theme) => theme.zIndex.appBar,
                    }}
                >
                    <Slide direction={position === 'flying' ? "right" : "down"} in={showTopNav} mountOnEnter unmountOnExit>
                        <Stack
                            alignItems="center"
                            direction={(position === 'flying') ? "column" : "row"}
                            justifyContent="space-between"
                            spacing={2}
                            sx={{
                                borderRadius: isMobile ? 0 : '30px',
                                borderColor: styles?.[settings.navColor]?.borderColor || theme.palette.divider,
                                borderWidth: 1,
                                color: styles?.[settings.navColor]?.color || theme.palette.text.primary,
                                borderStyle: 'solid',
                                backgroundColor: styles?.[settings.navColor]?.backgroundColor || theme.palette.background.default,
                                boxShadow: theme.palette.mode === 'light' ? '0px 0px 40px rgba(0, 0, 0, 0.1)' : '0px 0px 40px rgba(255, 255, 255, 0.1)',
                                p: 0.5
                            }}
                        >
                            <Stack
                                alignItems="center"
                                direction={(position === 'flying') ? "column" : "row"}
                            >
                                {sections?.map((section, index) => (
                                    <>
                                        {section?.isDivider ? (
                                            <Divider
                                                key={'divider-' + index}
                                                orientation={position === 'flying' ? "horizontal" : "vertical"}
                                                sx={{m: 1}}
                                                color={styles?.[settings.navColor]?.borderColor || theme.palette.divider}
                                                flexItem
                                            />
                                        ) : (
                                            <FlyingNavSection
                                                section={section}
                                                posPercentage={(index + 1) / selectedMode?.items?.length}
                                                key={'section-' + index}
                                            />
                                        )}
                                    </>
                                ))}
                            </Stack>
                        </Stack>
                    </Slide>
                </Box>

            )}
        </>
    )

};

FlyingNav.propTypes = {
    color: PropTypes.oneOf(['blend-in', 'discreet', 'evident']),
    onClose: PropTypes.func,
    open: PropTypes.bool,
    onMobileNavOpen: PropTypes.bool,
    mobileNavActive: PropTypes.bool,
    sections: PropTypes.array
};
