import React, {useEffect, useState} from "react";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Dialog,
    Stack,
    Box, CircularProgress, Avatar,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import SaveButton from "../../../elements/save-button";
import {useSelector} from "react-redux";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {useNotifications} from "../../../../hooks/use-notifications";
import IntegrationConfig from "./integration-config";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import SelectionTiles from "../../../elements/selection-tiles";
import OnIcon from "../../../elements/icon";

const defaultSettings = {name: '', config: {}};

function AddIntegrationDialog({open, onClose, onAdded, connector = null, title = null, subtitle = null, connectorBaseClasses = []}){
    const { notify, notifySuccess } = useNotifications();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(connector);
    const [connectors, setConnectors] = useState([]);
    const user = useSelector(state => state.account.user);
    const [settings, setSettings] = useState(defaultSettings);
    const { isMobile } = useIsMobile();
    const { post, get } = useOmniaApi();

    const handleSubmit = () => {
        setLoading(true);
        if(selected?.authenticator === 'Config Authenticator'){
            post('core/connectors/' + selected?.id + '/create_config_integration', settings).then((data) => {
                onClose?.();
                notifySuccess(t);
                setSettings(defaultSettings);
                onAdded?.(data);
            }).finally(() => {
                setLoading(false);
            })
        } else if(selected?.authenticator === 'Token Authenticator'){
            post('core/connectors/' + selected?.id + '/create_token_integration', settings).then((data) => {
                onClose?.();
                notifySuccess(t);
                setSettings(defaultSettings);
                onAdded?.(data);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            notify(t('mgt.connectors.no_authenticator'), "error");
            setLoading(false);
        }
    }

    useEffect(() => {
        if(connectorBaseClasses){
            setLoading(true);
            get('core/connectors', {base_classes: connectorBaseClasses, is_turned_on: true}).then(data => {
                setConnectors(data);
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [JSON.stringify(connectorBaseClasses)]);

    useEffect(() => {
        if(open && selected){
            const initialName = t('mgt.connectors.service_from_user', {name: selected?.name, user: user?.first_name});
            setSettings((prev) => ({...prev, name: initialName, config: {}}));
        }
    }, [open, selected]);

    useEffect(() => {
        setSelected(connector);
    }, [connector]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            fullScreen={isMobile}
            maxWidth="md"
        >
            <DialogTitle>
                <Typography variant="h4">
                    {title || (selected?.name ? t('dialogs.add_integration.title_name', {name: selected?.name}) : t('dialogs.add_integration.title'))}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {subtitle || t('dialogs.add_integration.description')}
                </Typography>
            </DialogTitle>
            <DialogContent
                style={{
                    height: 600,
                    padding: 0,
                    paddingTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{flex: 1, overflow: 'hidden', px: 2, my: 2}}>
                    {selected ? (
                        <Box sx={{height: '100%', overflow: 'auto', padding: 2}} px={2}>
                            <IntegrationConfig
                                connector={selected}
                                settings={settings}
                                setSettings={setSettings}
                            />
                        </Box>
                    ) : (
                        <Box sx={{height: '100%', overflow: 'auto', padding: 2}} px={2}>
                            {loading ? (
                                <Stack sx={{height: '100%', width: '100%'}} alignItems="center" justifyContent="center">
                                    <CircularProgress />
                                </Stack>
                            ) : (
                                <SelectionTiles
                                    options={connectors?.map(c => ({
                                        label: c?.name,
                                        value: c,
                                        icon: (
                                            <Avatar
                                                alt='connector-logo'
                                                src={c?.logo_ui || null}
                                                sx={{width: 34, height: 34}}
                                            >
                                                <OnIcon iconName={c?.icon_ui || 'IntersectCircle'}/>
                                            </Avatar>
                                        )
                                    }))}
                                    onSelect={(option) => {
                                        setSelected(option?.value);
                                    }}
                                />
                            )}
                        </Box>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={1} sx={{width: '100%'}} justifyContent="space-between">
                    {(selected && !connector) ? (
                        <Button variant="outlined" onClick={() => setSelected(null)}>
                            {t('common.back')}
                        </Button>
                    ) : (
                        <Button variant="outlined" onClick={onClose}>
                            {t('common.cancel')}
                        </Button>
                    )}
                    {selected && (
                        <SaveButton
                            onClick={handleSubmit}
                            label={t('common.continue')}
                            loading={loading}
                        />
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

AddIntegrationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    connector: PropTypes.object,
}

export default AddIntegrationDialog;