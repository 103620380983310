/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { produce } from 'immer';
import {
    ADD_THREAD,
    OPEN_SIDEBAR,
    INITIALIZE_THREADS,
    MARK_THREAD_AS_SEEN,
    REMOVE_THREAD,
    MUTE_THREAD,
    UNMUTE_THREAD,
    CLOSE_SIDEBAR,
    OPEN_MINI_THREAD,
    CLOSE_MINI_THREAD,
    ADD_NEW_MESSAGE,
    UPDATE_ASSISTANT_OPEN,
    UPDATE_ASSISTANT_UNREAD
} from 'src/omnia/store/actions/messages-actions';

const initialState = {
    // Threads
    threads: [],
    threadsLoaded: false,

    // Helpers
    openMiniThread: null,  // Open chat
    assistantOpen: false,  // Open assistant chat
    sidebarOpen: true,  // Open sidebar

    // Unread counts
    unread: 0,
    unreadAI: 0
};

const getAggregatedUnread = (state) => {
    let unread = 0;
    for(let i = 0; i < state.threads.length; i++){
        unread += state.threads[i]['unread_count'];
    }
    return unread;
}

const messagesReducer = (state = initialState, action) => {

    let tmp = null;
    let mergeSet = null;

    switch (action.type) {

        case UPDATE_ASSISTANT_UNREAD: {
            return produce(state, draft => {
                draft.unreadAI = state.assistantOpen ? 0 : action.payload;
            });
        }

        case UPDATE_ASSISTANT_OPEN: {
            return produce(state, draft => {
                draft.assistantOpen = action.payload;
            });
        }

        case ADD_NEW_MESSAGE:
            let { newMess, isRead } = action.payload;
            return produce(state, draft => {

                // Update the unread count if the latest message is not the same as the last message
                let index = state.threads.findIndex(t => t.id === newMess.thread);
                if(index !== -1){
                    if(state.threads[index].last_message?.id !== newMess.id){
                        if(!isRead)
                            draft.threads[index]['unread_count'] += 1;
                        draft.threads[index].last_message = newMess;
                    }

                }

                // Update the global unread count
                draft.unread = getAggregatedUnread(draft);
            });

        case OPEN_MINI_THREAD:
            let { openThread } = action.payload;
            return produce(state, draft => {

                for(let i = 0; i < state.threads.length; i++){
                    if(state.threads[i].id === openThread?.id){
                        draft.threads[i]['unread_count'] = 0;
                    }
                }

                draft.openMiniThread = openThread;
                draft.unread = getAggregatedUnread(draft);
            })

        case CLOSE_MINI_THREAD:
            return produce(state, draft => {
                draft.openMiniThread = null;
            })

        case INITIALIZE_THREADS:
            let { threads } = action.payload;

            mergeSet = state.threads
                .filter(t => !threads.map(a => a.id).includes(t.id))
                .concat(threads)
                .sort((a, b) => {
                    if((typeof(a.last_message) !== "undefined") && (typeof(b.last_message) !== "undefined")){
                        if((a.last_message !== null) && (b.last_message !== null)){
                            a = (new Date(a.last_message.created_at)).getTime();
                            b = (new Date(b.last_message.created_at)).getTime();
                            return ((a > b)) ? -1 : 1 ;
                        }
                    }
                    return 1;
                });

            // produce new state
            return produce(state, draft => {
                draft.threads = mergeSet;
                draft.threadsLoaded = true;
                draft.unread = getAggregatedUnread(draft);
            })

        case ADD_THREAD:
            let { thread } = action.payload;

            return produce(state, draft => {
                let threadIndex = state.threads.indexOf(state.threads.find(t => t.id === thread.id));
                if(threadIndex === -1){
                    draft.threads = state.threads.concat([thread]).sort((a, b) => {
                        if((typeof(a.last_message) !== "undefined") && (typeof(b.last_message) !== "undefined")){
                            if((a.last_message !== null) && (b.last_message !== null)){
                                a = (new Date(a.last_message.created_at)).getTime();
                                b = (new Date(b.last_message.created_at)).getTime();
                                return ((a > b)) ? -1 : 1 ;
                            }
                        }
                        return 1;
                    });
                } else {
                    draft.threads[threadIndex] = thread;
                }
            })

        case REMOVE_THREAD:
            return produce(state, draft => {
                draft.threads = state.threads.filter(t => t.id !== action.payload.id);
            })

        case MUTE_THREAD:
            tmp = state.threads.indexOf(state.threads.find(t => t.id === action.payload.id));
            if(tmp === -1)
                return state;
            return produce(state, draft => {
                draft.threads[tmp]['muted'] = true;
            })

        case UNMUTE_THREAD:
            tmp = state.threads.indexOf(state.threads.find(t => t.id === action.payload.id));
            if(tmp === -1)
                return state;
            return produce(state, draft => {
                draft.threads[tmp]['muted'] = false;
            })

        case MARK_THREAD_AS_SEEN:
            return produce(state, draft => {
                let index = state.threads.findIndex(t => t.id === action.payload);
                if(index === -1)
                    return state;
                draft.threads[index]['unread_count'] = 0;
                draft.unread = state.unread - state.threads[index]['unread_count'];
            })

        case CLOSE_SIDEBAR:
            return produce(state, draft => {
                draft.sidebarOpen = false;
            })

        case OPEN_SIDEBAR:
            return produce(state, draft => {
                draft.sidebarOpen = true;
            })

        default: {
            return state;
        }

    }

};

export default messagesReducer;