import React from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Typography} from "@mui/material";
import PropTypes from "prop-types";

function UserStatus({ user = null, withUntil = false, ...props }) {
    const { t } = useTranslation();
    const statusUntil = moment(user?.status_until);
    const now = moment();
    const statusActive = ((user?.status_until === null) || (user?.status_until && statusUntil.isAfter(now))) && (user?.status_text !== '') && (user?.status_text !== null);

    // Find out a good notation
    let untilText;
    if (statusUntil.isSame(now, 'day')) {
        untilText = statusUntil.format('HH:mm'); // e.g., "19:23"
    } else if (statusUntil.isBefore(now.clone().endOf('week'))) {
        untilText = statusUntil.format('dddd'); // e.g., "Sunday"
    } else {
        untilText = statusUntil.format('DD.MM.'); // e.g., "12.03."
    }

    // Check if statusUntil is in the past
    let finalStatus = user?.status_emoji + ' ' + user?.status_text;
    if (!statusActive) {
        finalStatus = user?.position;
    }

    // Show the status and until
    return (
        <Typography noWrap sx={{width: '100%'}} {...props}>
            {finalStatus}
            {(statusActive && user?.status_until && withUntil) && (' - ' + t('common.until') + ' ' + untilText)}
        </Typography>
    )
}

UserStatus.propTypes = {
    user: PropTypes.object,
    withUntil: PropTypes.bool
}

export default UserStatus;