import React from "react";
import SearchDialog from "../../elements/search-dialog";
import SearchButton from "../components/search-button";
import usePopover from "../../../hooks/use-popover";
import ModelsSidebarSection from "../../modules/ai-studio/models-sidebar-section";
import DashboardsSidebarSection from "../../modules/analytics/dashboards-sidebar-section";
import ContextsSidebarSection from "../../modules/connections/context/contexts-sidebar-section";
import CampaignsSidebarSection from "../../modules/marketing/campaigns/campaigns-sidebar-list";
import SpacesSidebarSection from "../../modules/spaces/spaces-sidebar-list";
import SitesSidebarSection from "../../modules/website/sites-sidebar-list";
import ToHomeButton from "./vertical-layout/to-home-button";

function CustomSidebarItems(props){

    const { superCustomAction, depth, ...rest } = props;

    const popover = usePopover();
    const offset = depth === 0 ? 0 : (depth - 1) * 16;

    if(superCustomAction === 'search')
        return (
            <>
                <SearchDialog popover={popover}/>
                <SearchButton
                    depth={depth}
                    offset={offset}
                    onClick={popover.handleOpen}
                />
            </>
        )

    if(superCustomAction === 'ai-model-list')
        return (
            <ModelsSidebarSection />
        )

    if(superCustomAction === 'dashboard-list')
        return (
            <DashboardsSidebarSection />
        )

    if(superCustomAction === 'contexts-list')
        return (
            <ContextsSidebarSection />
        )

    if(superCustomAction === 'campaigns-list')
        return (
            <CampaignsSidebarSection />
        )

    if(superCustomAction === 'spaces-list')
        return (
            <SpacesSidebarSection />
        )

    if(superCustomAction === 'sites-list')
        return (
            <SitesSidebarSection />
        )

    if(superCustomAction === 'to-home')
        return (
            <ToHomeButton {...props} />
        )

    return null;
}

export default CustomSidebarItems;