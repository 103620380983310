import React from "react";
import {Chip} from "@mui/material";
import {useTranslation} from "react-i18next";

function EmailStateChip({email, ...rest}){

    const { t } = useTranslation();

    const colorMap = {
        draft: 'default',
        planned: 'info',
        sending: 'default',
        re_attempting: 'warning',
        failed: 'error',
        sent: 'success',
        opened: 'success',
        clicked: 'success',
        external: 'default'
    }

    const prefix = email?.state_confidence < 100 ? (parseInt(email?.state_confidence) + '% ') : '';

    if(!email || email?.state === 'external')
        return null;

    return (
        <Chip
            label={prefix + t('connections.customer.email_states.' + email?.state)}
            color={colorMap?.[email?.state] || 'default'}
            {...rest}
        />
    )
}

export default EmailStateChip;