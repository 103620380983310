import React, {useEffect, useState} from 'react';
import { Box, Card, Typography, CardActionArea, CardContent, Fade, CardMedia } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import BoardContent from "./board-content";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router";
import {APP_SETTING} from "../../../../../setup";

function BoardCard({ board, onReload, onClose, preOpen = false, feature, selected = false, showPreview = true, ...rest }){

    const [boardOpen, setBoardOpen] = useState(preOpen);
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();

    const handleCloseBoard = () => {
        if(location.pathname.includes('groon/spaces/list')){
            setBoardOpen(false);
        } else {
            if(typeof id !== "undefined")
                navigate('/groon/spaces/view/' + board.space + '/content');
        }
        if(onClose)
            onClose();
    }

    const handleOpenBoard = () => {
        if(location.pathname.includes('groon/spaces/list')){
            setBoardOpen(true);
        } else {
            navigate('/groon/spaces/view/' + board.space + '/content/board_' + board.id);
        }
    }

    useEffect(() => {
        if(!location.pathname.includes('groon/spaces/list')){
            if(typeof id !== "undefined" && id === 'board_' + board.id){
                setBoardOpen(true);
            } else {
                setBoardOpen(false);
            }
        }
    }, [location.pathname, id]);

    return (
        <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
            <Box {...rest} style={{cursor: 'pointer', height: '100%'}}>
                <BoardContent
                    open={boardOpen}
                    onReload={onReload}
                    feature={feature}
                    board={board}
                    handleCloseBoard={handleCloseBoard}
                />
                <Card
                    sx={{
                        height: '100%',
                        border: selected
                            ? "2px solid"
                            : "none",
                        borderColor: selected
                            ? "primary.main"
                            : "divider",
                    }}
                >
                    <CardActionArea onClick={handleOpenBoard}>
                        {showPreview && (
                            <CardMedia
                                component="img"
                                height="140"
                                image="/assets/images/spaces/content/kanban_header.jpg"
                                title="Kanban Board"
                            />
                        )}
                        <CardContent sx={{p: 2}}>
                            <Typography
                                color="textSecondary"
                                variant="overline"
                                style={{lineHeight: 1.2}}
                            >
                                {t("Kanban Board")}
                            </Typography>
                            <Typography noWrap gutterBottom variant="subtitle1">
                                {(board.name.length > 20) ? board.name.substr(0, 20) + '...' : board.name}
                            </Typography>
                            <Typography noWrap variant="body2" color="textSecondary">
                                {(board.space_name.length > 20) ? board.space_name.substr(0, 17) + '...' : board.space_name}
                            </Typography>
                            <Typography noWrap variant="body2" color="textSecondary">
                                {t("common.last_active_from_now", {fromNow: moment(board.modified_at).fromNow()})}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </Fade>
    )
}

BoardCard.propTypes = {
    showSpace: PropTypes.bool,
    board: PropTypes.object,
    showPreview: PropTypes.bool,
    className: PropTypes.string,
    feature: PropTypes.number,
    preOpen: PropTypes.bool,
    onReload: PropTypes.func,
    onClose: PropTypes.func,
}

export default BoardCard;