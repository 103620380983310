import { Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/system";
import OverridingThemeProvider from "../../../../elements/theme-provider/overriding-theme-provider";

const DEFAULT_LOGOS = {
    desktop_light: '/assets/GROON_WORD_WHITE.svg',
    desktop_dark: '/assets/GROON_WORD_BLACK.svg',
    mobile_light: '/assets/GROON_SYMBOL_WHITE.svg',
    mobile_dark: '/assets/GROON_SYMBOL_BLACK.svg',
}

function LogoSelector({ variant = 'light', location = 'desktop', value, onChange, name }) {

    const [preview, setPreview] = useState(value ? value?.view : DEFAULT_LOGOS[`${location}_${variant}`]);
    const { t } = useTranslation();
    const fileInputRef = useRef(null);

    const handleDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const url = URL.createObjectURL(file);
        setPreview(url);
        onChange({
            target: {
                name: name,
                value: file
            }
        });
    }, [name, onChange]);

    const { getInputProps } = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        accept: 'image/png, image/svg+xml',
    });

    const selectFile = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        // Update the preview when the `value` prop changes
        if(value) {
            if(value?.view){
                setPreview(value?.view);
            } else {
                setPreview(URL.createObjectURL(value));
            }
        } else {
            // Reset to default theme logo based on variant
            setPreview(DEFAULT_LOGOS[`${location}_${variant}`]);
        }
    }, [value, variant]);

    return (
        <OverridingThemeProvider mode={variant === 'light' ? 'dark' : 'light'}>
            <Card>
                <CardHeader
                    title={t(variant === 'light' ? "mgt.settings.light_version" : "mgt.settings.dark_version")}
                    action={(
                        <Button
                            color="primary"
                            variant="text"
                            onClick={selectFile}
                        >
                            {t('common.select')}
                        </Button>
                    )}
                />
                <CardContent>
                    <Stack
                        direction="column"
                        sx={{width: '100%', height: '100%'}}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <img src={preview} alt="logo" style={{width: 'auto', height: 100}}/>
                    </Stack>
                </CardContent>
                <input
                    {...getInputProps()}
                    style={{display: "none"}}
                    ref={fileInputRef}
                    type="file"
                />
            </Card>
        </OverridingThemeProvider>
    );
}

LogoSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any, // Changed to optional as initial value can be from theme
    name: PropTypes.string.isRequired,
    variant: PropTypes.string,
    location: PropTypes.string,
};

export default LogoSelector;
