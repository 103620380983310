import PropTypes from 'prop-types';
import {Avatar, Box, Card, IconButton, Link, Popover, Stack, SvgIcon, Tooltip, Typography} from '@mui/material';
import moment from "moment";
import {useSelector} from "react-redux";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useCreateSrc} from "../../../../../hooks/use-create-src";
import {Link as RouterLink} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PostFileItem from "../post-card/post-file-item";
import {useTheme} from "@mui/system";
import {useIsMobile} from "../../../../../hooks/use-is-mobile";
import HtmlWrapper from "../../../../elements/html-wrapper";
import ImageCard from "../../../../elements/file-representations/image-card";
import OnIcon from "../../../../elements/icon";
import {useTranslation} from "react-i18next";
import useOmniaApi from "../../../../../hooks/use-omnia-api";
import {useNotifications} from "../../../../../hooks/use-notifications";
import removeHtmlTags from "../../../../../utils/remove-html-tags";

export const ChatMessage = (props) => {

    const {
        message,
        thread,

        contentType,
        position,
        body,
        isEmoji,
        files = [],
        smallVersion = false,

        lastUserMessage = false,
        firstUserMessage = false,
        onLoaded = () => {},
        ...other
    } = props;

    const {t} = useTranslation();
    const theme = useTheme();
    const users = useSelector(state => state.users.users);
    const {isMobile} = useIsMobile();
    const getSrc = useCreateSrc();
    const {del} = useOmniaApi();
    const {notify} = useNotifications();
    const [anchorEl, setAnchorEl] = useState(null);
    const [copied, setCopied] = useState(false);
    const authorAvatar = users.filter(u => u.id === message.sender.id).length > 0 ? getSrc(users.find(u => u.id === message.sender.id).avatar) : null;
    const containerRef = useRef();

    const handleDelete = () => {
        if (window.confirm(t('notify.are_you_sure'))) {
            del('connect/messages', message.id);
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(removeHtmlTags(body)).then(function () {
            setCopied(true);
            setTimeout(() => {
                handlePopoverClose();
            }, 500);
        }).catch(function (error) {
            notify(t('notify.error'), 'error');
        });
    }

    const handleDownload = () => {
        window.location.href = body?.view?.replace('highest', 'original');
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const showAvatar = (thread.is_group_chat && (position === "left")) && !smallVersion && firstUserMessage;

    const pushMeAway = (thread.is_group_chat && (position === "left")) && !smallVersion && !firstUserMessage;

    const open = Boolean(anchorEl);

    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (files && files.length > 0) {
            onLoaded();
        }
    }, [files]);

    if (!(body || files?.length > 0))
        return null;

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: position === 'right' ? 'flex-end' : 'flex-start',
                }}
                {...other}
            >
                <Stack
                    alignItems="flex-start"
                    direction={position === 'right' ? 'row-reverse' : 'row'}
                    spacing={2}
                    sx={{
                        maxWidth: 'min(100%, 500px)',
                        ml: position === 'right' ? 'auto' : 0,
                        mr: position === 'left' ? 'auto' : 0
                    }}
                >
                    {showAvatar && (
                        <Avatar
                            src={authorAvatar || undefined}
                            sx={{
                                height: 32,
                                width: 32
                            }}
                        />
                    )}
                    <Box sx={{
                        display: 'flex',
                        flexGrow: 1,
                        pl: (pushMeAway ? '42px' : 0),
                        flexDirection: 'column',
                        width: '100%'
                    }} id="MessageHolder">
                        {isEmoji ? (
                            <Box style={{
                                fontSize: smallVersion ? 40 : 50,
                                textAlign: position,
                            }}>
                                {body}
                            </Box>
                        ) : (
                            <>
                                {((contentType === 'image') && firstUserMessage && (position === "left")) && (
                                    <Box
                                        sx={{
                                            px: 1.75,
                                            py: smallVersion ? 0 : 0.75,
                                            pt: 0.75,
                                        }}
                                    >
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            component={RouterLink}
                                            to={"/groon/home/profile/" + message.sender.id}
                                            sx={{
                                                cursor: 'pointer',
                                                fontWeight: 600,
                                                fontSize: smallVersion ? 12 : 13,
                                            }}
                                        >
                                            {message.sender.name}
                                        </Link>
                                    </Box>
                                )}

                                {(thread.is_group_chat && contentType === 'text') && (
                                    <Box>
                                        {(firstUserMessage && (position === "left")) && (
                                            <Box sx={{
                                                px: 1.75,
                                                py: smallVersion ? 0 : 0.75,
                                                pt: 0.75,
                                            }}>
                                                <Link
                                                    underline="hover"
                                                    color="inherit"
                                                    component={RouterLink}
                                                    to={"/groon/home/profile/" + message.sender.id}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        fontWeight: 600,
                                                        fontSize: smallVersion ? 12 : 13,
                                                    }}
                                                >
                                                    {message.sender.name}
                                                </Link>
                                            </Box>
                                        )}
                                    </Box>
                                )}

                                <Box onContextMenu={handleContextMenu}>
                                    {contentType === 'image' && (
                                        <Box
                                            sx={{width: '100%'}}
                                            id="ImageParent"
                                            ref={containerRef}
                                        >
                                            <ImageCard
                                                image={body}
                                                onLoaded={onLoaded}
                                                quality='medium'
                                                containerRef={containerRef}
                                                sx={{maxWidth: 500}}
                                            />
                                        </Box>
                                    )}
                                    {contentType === 'text' && (
                                        <Card
                                            sx={{
                                                backgroundColor: position === 'right' ? 'primary.main' : 'background.paper',
                                                color: position === 'right' ? 'primary.contrastText' : 'text.primary',
                                                borderRadius: theme?.config?.message_radius + 'px',
                                            }}
                                        >
                                            {body && (
                                                <Typography
                                                    sx={{
                                                        px: 1.5,
                                                        py: 0.75
                                                    }}
                                                    color="inherit"
                                                    variant={(smallVersion || isMobile) ? "body2" : "body1"}
                                                >
                                                    <HtmlWrapper
                                                        html={body}
                                                        styles={{
                                                            color: (position === 'right') ? theme.palette.primary.contrastText : theme.palette.text.primary,
                                                            fontSize: (smallVersion || isMobile) ? theme.config?.font_body2_size : theme.config?.font_subtitle1_size
                                                        }}
                                                    />
                                                </Typography>
                                            )}
                                            {files && (files.length > 0) && (
                                                <Box mt={body ? 2 : 0}>
                                                    <PerfectScrollbar>
                                                        <Box style={{maxHeight: 500}}>
                                                            {files.map((file, i) => {
                                                                return (
                                                                    <PostFileItem
                                                                        key={message.id + '-file-' + i}
                                                                        simpleIcon={true}
                                                                        file={file}
                                                                        showDivider={i < files.length - 1}
                                                                        sx={{color: (position === "right" ? theme.palette.primary.contrastText : theme.palette.text.primary)}}
                                                                    />
                                                                )
                                                            })}
                                                        </Box>
                                                    </PerfectScrollbar>
                                                </Box>
                                            )}
                                        </Card>
                                    )}
                                </Box>

                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: position === 'right' ? 'left' : 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'center',
                                        horizontal: position,
                                    }}
                                >
                                    <Stack direction="row" spacing={0} sx={{p: 0.5}}>
                                        {position === 'right' && (
                                            <Tooltip enterDelay={1000} title={t('common.delete')} placement="top">
                                                <IconButton onClick={handleDelete}>
                                                    <SvgIcon fontSize="small">
                                                        <OnIcon iconName="Trash01"/>
                                                    </SvgIcon>
                                                </IconButton>
                                            </Tooltip>
                                        )}

                                        {contentType === 'text' && (
                                            <Tooltip enterDelay={1000} title={copied ? t('common.copied') : t('common.copy')}
                                                     placement="top">
                                                <IconButton onClick={handleCopy}>
                                                    <SvgIcon fontSize="small">
                                                        <OnIcon iconName="Copy01"/>
                                                    </SvgIcon>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                        {contentType === 'image' && (
                                            <Tooltip enterDelay={1000} title={t('common.tooltips.download')} placement="top">
                                                <IconButton onClick={handleDownload}>
                                                    <SvgIcon fontSize="small">
                                                        <OnIcon iconName="Download01"/>
                                                    </SvgIcon>
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Stack>
                                </Popover>

                            </>
                        )}
                    </Box>
                </Stack>
            </Box>
            {/*<Box>*/}
            {lastUserMessage && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
                    }}
                    pl={position === 'right' ? 0 : ((smallVersion || !thread.is_group_chat) ? 1 : 7)}
                    pr={position === 'left' ? 0 : 1}
                >
                    <Typography
                        color="text.secondary"
                        noWrap
                        variant="caption"
                    >
                        {moment(message.created_at).fromNow()}
                    </Typography>
                </Box>
            )}
            {/*</Box>*/}

        </>
    );
};

ChatMessage.propTypes = {
    message: PropTypes.object.isRequired,
    thread: PropTypes.object.isRequired,
    contentType: PropTypes.oneOf(['text', 'image']).isRequired,
    position: PropTypes.oneOf(['left', 'right']).isRequired,
    body: PropTypes.string,
    isEmoji: PropTypes.bool,
    files: PropTypes.array,
    smallVersion: PropTypes.bool,
    lastUserMessage: PropTypes.bool,
    firstUserMessage: PropTypes.bool,
    onLoaded: PropTypes.func,
};
