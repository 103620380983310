import React, {useCallback} from "react";
import {
    Avatar,
    Box,
    Card,
    Chip,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import OnIcon from "../../components/elements/icon";
import UserOnlineDot from "../../components/modules/home/UserOnlineDot";
import SelectionTiles from "../../components/elements/selection-tiles";
import TaskListItem from "../../components/modules/home/my-tasks/task-list-item";
import {Swiper, SwiperSlide} from "swiper/react";
import HoverableFileCard from "../../components/elements/file-representations/files-overview/hoverable-file-card";
import ContextCard from "../../components/modules/connections/context/context-card";
import SpaceCard from "../../components/modules/spaces/space-card";
import KPIChart from "../../components/modules/analytics/data-hub/kpi-chart";
import AiModelImage from "../../components/modules/ai-studio/ai-model-image";
import AiModelStateChip from "../../components/modules/ai-studio/ai-model-state-chip";
import {OrderListTable} from "../../components/modules/resources/orders/order-list-table";
import StackAvatars from "../../components/modules/home/StackAvatars";
import moment from "moment/moment";
import {NumericFormat} from "react-number-format";
import BoardCard from "../../components/modules/spaces/board-card";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import useCrmFormik from "../formik/use-crm-formik";
import UserHeader from "../../components/modules/home/user/user-header";
import {MailItem} from "../../components/modules/home/email/mail-item";

function useRenderObjects(currentlySelected = null, onNavigate = null) {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {eventIcons} = useCrmFormik();

    const handleOpenOrder = (order) => {
        handleNavigate('/groon/resources/order/' + order);
    }

    const handleNavigate = (link, newTarget = false) => {
        onNavigate?.();
        if (newTarget) {
            window.open(link, '_blank', 'noopener,noreferrer');
        } else {
            navigate(link);
        }
    };

    return useCallback((kind, results, variant = 'list-item') => {

        // TODO: include variant

        // Paths
        if (kind === 'paths') {
            return (
                <ListItem sx={{p: 2}}>
                    <SelectionTiles
                        options={results?.map(o => ({
                            icon: o.icon,
                            path: o.path,
                            label: o.title,
                            description: o?.description || null,
                            disabled: o?.disabled || false
                        }))}
                        onSelect={option => handleNavigate(option.value)}
                        iconSize="small"
                        dataKind="paths"
                        selected={(currentlySelected?.kind === kind) ? currentlySelected?.data?.path : null}
                        lowestCol={3}
                        tileHeight={90}
                    />
                </ListItem>
            )
        }

        // Classical Objects
        if (kind === 'crm_contact') {
            return results?.map(contact => (
                <ListItem
                    key={contact.id}
                    sx={{p: 0}}
                    secondaryAction={(
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                            {contact?.email && (
                                <Tooltip title={t('core.search.write_email_tooltip')}>
                                    <IconButton
                                        data-kind="contacts"
                                        data-id={contact.id}
                                        onClick={() => handleNavigate('/groon/connections/contact/' + contact?.id + '/events#writeEmail')}
                                    >
                                        <OnIcon iconName="Mail01"/>
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title={t('core.search.add_event_tooltip')}>
                                <IconButton
                                    data-kind="contacts"
                                    data-id={contact.id}
                                    onClick={() => handleNavigate('/groon/connections/contact/' + contact?.id + '/events#addNote')}
                                >
                                    <OnIcon iconName="CalendarPlus01"/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    )}
                >
                    <ListItemButton
                        selected={(currentlySelected?.data?.id === contact?.id) && (currentlySelected?.kind === kind)}
                        data-kind="contacts"
                        data-id={contact.id}
                        onClick={() => handleNavigate(contact?.object_link)}
                    >
                        <ListItemAvatar>
                            <Avatar src={contact?.avatar?.view}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={contact?.name}
                            secondary={contact?.position || contact?.email}
                        />
                    </ListItemButton>
                </ListItem>
            ))
        }
        if (kind === 'core_email') {
            return results?.map(email => (
                <MailItem
                    onClick={onNavigate}
                    email={email}
                    withActions={false}
                    key={'email-' + email?.id}
                />
            ))
        }
        if (kind === 'core_user') {
            return results?.map(user => (
                <ListItem
                    key={user.id}
                    sx={{p: 0}}
                    secondaryAction={(
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                            <Tooltip title={t('core.search.write_message_tooltip')}>
                                <IconButton
                                    data-kind="users"
                                    data-id={user.id}
                                    onClick={() => handleNavigate("/groon/home/chat/user_" + user?.id)}
                                >
                                    <OnIcon iconName="MessageCircle01"/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    )}
                >
                    <ListItemButton
                        selected={(currentlySelected?.data?.id === user?.id) && (currentlySelected?.kind === kind)}
                        data-kind="users" data-id={user.id} onClick={() => handleNavigate(user?.object_link)}
                    >
                        <UserHeader userId={user?.id} />
                        {/*<ListItemAvatar>*/}
                        {/*    <Avatar src={user?.avatar?.view}/>*/}
                        {/*</ListItemAvatar>*/}
                        {/*<ListItemText*/}
                        {/*    primary={(*/}
                        {/*        <Stack direction="center" spacing={2} alignItems="center">*/}
                        {/*            <Typography variant="h6">*/}
                        {/*                {user?.name}*/}
                        {/*            </Typography>*/}
                        {/*            <Box sx={{px: 1}}>*/}
                        {/*                <UserOnlineDot userId={user?.id} />*/}
                        {/*            </Box>*/}
                        {/*        </Stack>*/}
                        {/*    )}*/}
                        {/*    secondary={user?.position || user?.email}*/}
                        {/*/>*/}
                    </ListItemButton>
                </ListItem>
            ))
        }
        if (kind === 'core_assistantsavedprompt') {
            return (
                <ListItem sx={{p: 2}}>
                    <SelectionTiles
                        options={results?.map(o => ({icon: 'MessageSmileCircle', value: o.id, label: o.name}))}
                        onSelect={option => handleNavigate(option.value)}
                        selected={(currentlySelected?.kind === kind) ? currentlySelected?.data?.id : null}
                        iconSize="small"
                        lowestCol={3}
                        tileHeight={90}
                    />
                </ListItem>
            )
        }
        if (kind === 'cms_site') {
            return results?.map(website => (
                <ListItem
                    key={website.id}
                    sx={{p: 0}}
                    secondaryAction={(
                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                            <Tooltip title={t('core.search.view_website')}>
                                <IconButton
                                    data-kind="sites"
                                    data-id={website.id}
                                    onClick={() => handleNavigate(website?.view_url, true)}
                                >
                                    <OnIcon iconName="Eye"/>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    )}
                >
                    <ListItemButton
                        selected={(currentlySelected?.data?.id === website?.id) && (currentlySelected?.kind === kind)}
                        data-kind="sites" data-id={website.id} onClick={() => handleNavigate(website?.object_link)}>
                        <ListItemText
                            primary={website?.title}
                            secondary={website?.kind}
                        />
                    </ListItemButton>
                </ListItem>
            ))
        }
        if (kind === 'core_task') {
            return (
                <Box pb={2}>
                    {results?.map(task => (
                        <TaskListItem
                            key={'task-' + task.id}
                            task={task}
                            selected={(currentlySelected?.data?.id === task?.id) && (currentlySelected?.kind === kind)}
                            dataKind="tasks"
                        />
                    ))}
                </Box>
            )
        }
        if (kind === 'core_file') {
            return (
                <Swiper
                    spaceBetween={10}
                    slidesPerView={Math.min(results?.length, 3)}
                    style={{padding: 15}}
                >
                    {results.map(file => (
                        <SwiperSlide key={file.id || file.name}>
                            <div data-kind="files" data-id={file.id} style={{cursor: 'pointer'}}>
                                <HoverableFileCard
                                    file={file}
                                    selected={(currentlySelected?.data?.id === file?.id) && (currentlySelected?.kind === kind)}
                                    allowDeletion={false}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )
        }
        if (kind === 'crm_registrationcontext') {
            return (
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    style={{padding: 15}}
                >
                    {results.map(context => (
                        <SwiperSlide key={context.id || context.name}>
                            <div data-kind="contexts" data-id={context.id} style={{cursor: 'pointer'}}>
                                <ContextCard
                                    context={context}
                                    selected={(currentlySelected?.data?.id === context?.id) && (currentlySelected?.kind === kind)}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )
        }
        if (kind === 'pm_space') {
            return (
                <Swiper
                    spaceBetween={10}
                    slidesPerView={Math.min(results?.length, 2)}
                    style={{padding: 15}}
                >
                    {results.map(project => (
                        <SwiperSlide key={project.id || project.name} style={{padding: 2}}>
                            <div data-kind="projects" data-id={project.id} style={{cursor: 'pointer'}}>
                                <SpaceCard
                                    project={project}
                                    noDetails={true}
                                    selected={(currentlySelected?.data?.id === project?.id) && (currentlySelected?.kind === kind)}
                                    height={100}
                                    onMembershipChange={() => {
                                    }}
                                    detailLimit={2}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )
        }
        if (kind === 'core_omniakpi') {
            return (
                <Stack
                    direction="column"
                    sx={{width: '100%', p: 2}}
                    spacing={2}
                >
                    {results.map(kpi => (
                        <Card
                            key={kpi.id || kpi.name}
                            data-kind={kind}
                            data-id={kpi?.id}
                            sx={{
                                p: 2,
                                backgroundColor: 'transparent',
                                boxShadow: 0,
                                transition: (theme) => theme.transitions.create(['background-color, box-shadow'], {
                                    easing: theme.transitions.easing.easeInOut,
                                    duration: 200
                                }),
                                border: (currentlySelected?.data?.id === kpi?.id) && (currentlySelected?.kind === kind)
                                    ? "2px solid"
                                    : "1px solid",
                                borderColor: (currentlySelected?.data?.id === kpi?.id) && (currentlySelected?.kind === kind)
                                    ? "primary.main"
                                    : "divider",
                                '&:hover': {
                                    backgroundColor: 'background.paper',
                                    boxShadow: 5
                                }
                            }}
                            variant="outlined"
                        >
                            <KPIChart
                                kpi={kpi}
                                small={true}
                                withTitle={true}
                                height={150}
                            />
                        </Card>
                    ))}
                </Stack>
            )
        }
        if (kind === 'ai_aimodel') {
            return results?.map(model => (
                <ListItem key={model.id} sx={{p: 0}}>
                    <ListItemButton
                        selected={(currentlySelected?.data?.id === model?.id) && (currentlySelected?.kind === kind)}
                        data-kind="models" data-id={model.id} onClick={() => handleNavigate(model?.object_link)}>
                        <ListItemAvatar>
                            <AiModelImage aiModel={model} sizePixels={25}/>
                        </ListItemAvatar>
                        <ListItemText
                            primary={model?.object_name}
                            secondary={model?.subline}
                            primaryTypographyProps={{noWrap: true}}
                            secondaryTypographyProps={{noWrap: true}}
                        />
                        <AiModelStateChip model={model}/>
                    </ListItemButton>
                </ListItem>
            ))
        }
        if (kind === 'tms_order') {
            return (
                <OrderListTable
                    noDividers={true}
                    orders={results}
                    onOpenDrawer={handleOpenOrder}
                    minimal={true}
                />
            )
        }
        if (kind === 'crm_event') {
            return results?.map(event => (
                <ListItem
                    key={event.id}
                    sx={{p: 0}}
                    secondaryAction={(
                        <StackAvatars
                            direction="row"
                            avatars={event?.users}
                            limit={6}
                        />
                    )}
                >
                    <ListItemButton
                        selected={(currentlySelected?.data?.id === event?.id) && (currentlySelected?.kind === kind)}
                        data-kind="events"
                        data-id={event.id}
                        onClick={() => {
                            if (event?.contacts?.length > 0)
                                handleNavigate('/groon/connections/contact/' + event?.contacts?.[0]?.id + '/events')
                        }}
                    >
                        <ListItemIcon>
                            {eventIcons?.[event?.type || 'other']}
                        </ListItemIcon>
                        <ListItemText primary={event?.title}
                                      secondary={moment(event?.time).format('DD.MM.YYYY HH:mm')}/>
                    </ListItemButton>
                </ListItem>
            ))
        }
        if (kind === 'tms_offer') {
            return results?.map(offer => (
                <ListItem
                    key={offer.id}
                    sx={{p: 0}}
                    secondaryAction={(
                        <Stack direction="row" alignItems="center" justifyContent="flex-end">
                            {offer?.order?.contact && (
                                <Chip
                                    label={offer?.order?.contact?.entity_type === 'person' ? (offer?.order?.contact?.first_name + ' ' + offer?.order?.contact?.last_name) : offer?.order?.contact?.entity_name}/>
                            )}
                        </Stack>
                    )}
                >
                    <ListItemButton
                        selected={(currentlySelected?.data?.id === offer?.id) && (currentlySelected?.kind === kind)}
                        data-kind="offers" data-id={offer.id} onClick={() => handleNavigate(offer?.object_link)}>
                        <ListItemText
                            primary={offer?.object_name}
                            secondary={(
                                <NumericFormat
                                    value={parseInt(offer?.total_gross) / 100}
                                    suffix={' €'}
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                />
                            )}
                        />
                    </ListItemButton>
                </ListItem>
            ))
        }
        if (kind === 'pm_board') {
            return (
                <Swiper
                    spaceBetween={10}
                    slidesPerView={Math.min(results?.length, 2)}
                    style={{padding: 15}}
                >
                    {results.map(board => (
                        <SwiperSlide key={board.id || board.name} style={{padding: 2}}>
                            <div data-kind="boards" data-id={board.id} style={{cursor: 'pointer'}}>
                                <BoardCard
                                    board={board}
                                    showPreview={false}


                                    selected={(currentlySelected?.data?.id === board?.id) && (currentlySelected?.kind === kind)}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )
        }

        // Fallback
        return results?.map(sharableObject => (
            <ListItem key={sharableObject.id} sx={{p: 0}}>
                <ListItemButton
                    selected={(currentlySelected?.data?.id === sharableObject?.id) && (currentlySelected?.kind === kind)}
                    data-kind={kind}
                    data-id={sharableObject.id}
                    onClick={() => handleNavigate(sharableObject?.object_link)}
                >
                    <ListItemText
                        primary={sharableObject?.object_name}
                        secondary={sharableObject?.type || sharableObject?.kind || sharableObject?.subline || sharableObject?.description}
                        primaryTypographyProps={{noWrap: true}}
                        secondaryTypographyProps={{noWrap: true}}
                    />
                </ListItemButton>
            </ListItem>
        ))

    }, [JSON.stringify(currentlySelected)]);

}

export default useRenderObjects;