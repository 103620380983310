import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";

const useSmFormik = (initialData, onSubmit, enableReinitialize = false) => {
    const {t} = useTranslation();
    const FACEBOOK_CAMPAIGN_OBJECTIVES = [
        'APP_INSTALLS',
        'BRAND_AWARENESS',
        'CONVERSIONS',
        'EVENT_RESPONSES',
        'LEAD_GENERATION',
        'LINK_CLICKS',
        'LOCAL_AWARENESS',
        'MESSAGES',
        'OFFER_CLAIMS',
        'OUTCOME_APP_PROMOTION',
        'OUTCOME_AWARENESS',
        'OUTCOME_ENGAGEMENT',
        'OUTCOME_LEADS',
        'OUTCOME_SALES',
        'OUTCOME_TRAFFIC',
        'PAGE_LIKES',
        'POST_ENGAGEMENT',
        'PRODUCT_CATALOG_SALES',
        'REACH',
        'STORE_VISITS',
        'VIDEO_VIEWS'
    ];

    const AD_CATEGORIES = [
        'EMPLOYMENT',
        'HOUSING',
        'CREDIT',
        'ISSUES_ELECTIONS_POLITICS', 'ONLINE_GAMBLING_AND_GAMING']


    // Use useFormik directly instead of using useCallback
    const adSetFormik = useFormik({
        initialValues: initialData ? initialData : {
            name: '',
        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(1000, t('form.max_length', {characters: 1000})).required(t('form.is_required', {field: t('attributes.name')})),

        }),
        onSubmit: onSubmit
    });

    const adFormik = useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            ad_set: '',

        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(1000, t('form.max_length', {characters: 1000})).required(t('form.is_required', {field: t('attributes.name')})),
            ad_set: Yup.string().required(t('form.is_required', {field: t('attributes.add_grp')})),
        }),
        onSubmit: onSubmit
    });

    const googleAdsManagerAccountFormik = useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            reference_id: '',
            developer_token: '',
            is_active: true

        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(1000, t('form.max_length', {characters: 255})).required(t('form.is_required', {field: t('attributes.name')})),
            reference_id: Yup.string().max(1000, t('form.max_length', {characters: 255})).required(t('form.is_required', {field: t('attributes.reference_id')})),
            developer_token: Yup.string().max(1000, t('form.max_length', {characters: 255})).required(t('form.is_required', {field: t('attributes.developer_token')})),
        }),
        validateOnMount: false,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit: onSubmit
    });

    const campaignFormik = useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            start_date: null, // Use null for empty date fields
            end_date: null,
        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .max(1000, t('form.max_length', {characters: 1000}))
                .required(t('form.is_required', {field: t('attributes.name')})),
            start_date: Yup.date()
                .nullable()
                .notRequired(),
            end_date: Yup.date()
                .nullable()
                .notRequired()
                .min(Yup.ref('start_date'), t('form.enddate'))

        }),
        onSubmit: onSubmit
    });

    const googleCampaignFormik = useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            customer_id: '',
            status: '',
            advertising_channel: '',
            bidding_strategy_type:'',
            budget_name: '',
            budget_amount: '',
            target_google_search: true,
            target_search_network: true,
        },
        enableReinitialize: false,
        onSubmit: onSubmit

    })

    const googleAdsAccountsFormik = useFormik({
        initialValues: initialData ? initialData : {
            customer_id: '',
        },
        enableReinitialize: false,
        onSubmit: onSubmit

    })

    const facebookCampaignFormik = useFormik({
            initialValues: {
                status: "PAUSED",
                objective: "",
                ad_categories: []
            },
            enableReinitialize: false,
            onSubmit: onSubmit
    })


    return {
        FACEBOOK_CAMPAIGN_OBJECTIVES,
        AD_CATEGORIES,
        adFormik,
        adSetFormik,
        campaignFormik,
        facebookCampaignFormik,
        googleCampaignFormik,
        googleAdsAccountsFormik,
        googleAdsManagerAccountFormik
    };
}

export default useSmFormik;
