import React from "react";
import PropTypes from 'prop-types';
import {Box, IconButton, Link, ListItem, ListItemAvatar, ListItemText, Typography} from '@mui/material';
import OnIcon from "./icon";
import { Link as RouterLink } from 'react-router-dom';

export const PropertyListItem = (props) => {
    const {
        align = 'vertical',
        children,
        disableGutters,
        to = null,
        value,
        label,
        avatar = null,
        onRemove = null,
        ...other
    } = props;

    return (
        <ListItem
            sx={{
                px: disableGutters ? 0 : 3,
                py: 1
            }}
            secondaryAction={onRemove && (
                <IconButton onClick={onRemove}>
                    <OnIcon iconName="Trash01" size="small" />
                </IconButton>
            )}
            {...other}
        >
            {avatar && (
                <ListItemAvatar>
                    {avatar}
                </ListItemAvatar>
            )}
            <ListItemText
                disableTypography
                primary={to ? (
                    <Link
                        sx={{ minWidth: align === 'vertical' ? 'inherit' : 180, lineHeight: 1.5 }}
                        variant="body1"
                        to={to}
                        color="textPrimary"
                        underline="hover"
                        component={RouterLink}
                    >
                        {label}
                    </Link>
                ) : (
                    <Typography
                        sx={{ minWidth: align === 'vertical' ? 'inherit' : 180, lineHeight: 1.5 }}
                        variant="body1"
                    >
                        {label}
                    </Typography>
                )}
                secondary={(
                    <Box
                        sx={{
                            flex: 1,
                            mt: 0
                        }}
                    >
                        {children || (
                            <Typography
                                color="textSecondary"
                                variant="body1"
                                sx={{lineHeight: 1.5}}
                            >
                                {value}
                            </Typography>
                        )}
                    </Box>
                )}
                sx={{
                    display: 'flex',
                    flexDirection: align === 'vertical' ? 'column' : 'row',
                    my: 0
                }}
            />
        </ListItem>
    );
};

PropertyListItem.propTypes = {
    align: PropTypes.oneOf(['horizontal', 'vertical']),
    children: PropTypes.node,
    avatar: PropTypes.node,
    disableGutters: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
    value: PropTypes.string,
    to: PropTypes.string
};