import React from "react";
import PropTypes from "prop-types"
import {Box, Card} from "@mui/material";
import useRenderObjects from "../../../../hooks/data-rendering/use-render-objects";

function AttachedElement({object, ...rest}){

    const renderObjects = useRenderObjects();
    const objectReference = object?.object_reference || null;

    // Split objectReference by `-` and assign the first part objectKind and the second part objectId
    const [objectKind, objectId] = objectReference?.split('-') || [null, null];

    return (
        <Box {...rest}>
            <Card>
                {renderObjects(objectKind, [object])}
            </Card>
        </Box>
    )
}

AttachedElement.propTypes = {
    object: PropTypes.object.isRequired
}

export default AttachedElement;