import React, { useState, useEffect } from "react";
import { Box, LinearProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";

const SimulateLoading = ({ onMiddle, onComplete, stopSimulation }) => {
    const [progress, setProgress] = useState(0);
    const [bufferProgress, setBufferProgress] = useState(0);
    const [middleTriggered, setMiddleTriggered] = useState(false);

    useEffect(() => {
        if (stopSimulation) {
            setProgress(0); // Reset or halt progress
            setBufferProgress(0); // Reset buffer progress
            return;
        }

        const randomDuration = Math.random() * (60000 - 40000) + 40000; // Random duration between 40 and 60 seconds
        const interval = 500; // Update every 100ms
        const totalSteps = randomDuration / interval; // Total number of updates
        const baseIncrement = 100 / totalSteps; // Base increment per step

        const timer = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(timer);
                    onComplete();
                    return 100;
                }

                // Add some randomness to the progress increment
                const randomFactor = Math.random() * 0.5 + 0.75; // Between 0.75x and 1.25x base speed
                const nextProgress = prev + baseIncrement * randomFactor;

                if (!middleTriggered && nextProgress >= 50) {
                    setMiddleTriggered(true);
                    onMiddle(); // Trigger onMiddle once at 50%
                }

                return Math.min(nextProgress, 100); // Cap at 100%
            });

            // Update buffer progress separately
            setBufferProgress((prev) => Math.min(prev + baseIncrement * 1.5, 100)); // Buffer progresses faster
        }, interval);

        return () => clearInterval(timer);
    }, [onMiddle, onComplete, middleTriggered, stopSimulation]);

    return (
        <Box
            sx={{
                width: "100%",
                textAlign: "center",
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
            }}
        >
            <Typography variant="body1" color="textSecondary">
                {Math.round(progress)}% Complete
            </Typography>
            <LinearProgress
                variant="buffer"
                value={progress}
                valueBuffer={bufferProgress}
                sx={{ width: "100%" }}
            />
        </Box>
    );
};

export default SimulateLoading;

SimulateLoading.propTypes = {
    onMiddle: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    stopSimulation: PropTypes.bool.isRequired,
};
