import React, {useEffect, useMemo, useState} from "react";
import {
    Grid,
    Box,
    MenuItem,
    Stack,
    TextField,
    Typography,
    Button,
    DialogTitle, DialogContent, DialogActions, Dialog, Chip, Switch
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "../../../hooks/use-is-mobile";
import SelectionTiles from "../selection-tiles";
import {Check, Hash, AlignCenter, List} from "react-feather";
import PropTypes from "prop-types";
import {produce} from "immer";
import {NumericFormat} from "react-number-format";

function SettingsItemDialog({field, open, onClose, onDone, onChange, onRemove, ...rest}) {

    const {t} = useTranslation();
    const {isMobile} = useIsMobile();
    const [values, setValues] = useState(field);
    const [currentOption, setCurrentOption] = useState({ datatype: 'string' });

    const FIELD_TYPES = [
        {
            value: 'number',
            disabled: false,
            label: t('dialogs.settings_items.item.number'),
            description: t('dialogs.settings_items.item.number_description'),
            icon: Hash
        },
        {
            value: 'text',
            disabled: false,
            label: t('dialogs.settings_items.item.text'),
            description: t('dialogs.settings_items.item.text_description'),
            icon: AlignCenter
        },
        {
            value: 'switch',
            disabled: false,
            label: t('dialogs.settings_items.item.boolean'),
            description: t('dialogs.settings_items.item.boolean_description'),
            icon: Check
        },
        {
            value: 'select',
            disabled: false,
            label: t('dialogs.settings_items.item.select'),
            description: t('dialogs.settings_items.item.select_description'),
            icon: List
        },
    ];

    const handleChange = (event) => {
        setValues({...values, [event.target.name]: event.target.value});
    }

    const isNameOkay = useMemo(() => {
        return values?.name && !values?.name.includes(' ');
    }, [values]);

    useEffect(() => {
        setValues(field);
    }, [field]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile}
            fullWidth={true}
            maxWidth={field ? "md" : "sm"}
            {...rest}
        >
            <DialogTitle>
                <Typography variant="h4">
                    {field ? t('dialogs.settings_items.title') : t('dialogs.settings_items.add_name_title')}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {field ? t('dialogs.settings_items.description') : t('dialogs.settings_items.add_name_description')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box mt={1}>
                    {field ? (
                        <Grid container spacing={2}>
                            {field?.type ? (
                                <>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={t('attributes.name')}
                                            value={values?.name}
                                            disabled
                                            onChange={handleChange}
                                            fullWidth
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={t('attributes.label')}
                                            value={values?.label}
                                            name="label"
                                            onChange={handleChange}
                                            fullWidth
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('attributes.description')}
                                            multiline
                                            name="description"
                                            value={values?.description}
                                            rows={4}
                                            onChange={handleChange}
                                            type="text"
                                        />
                                    </Grid>
                                    {field?.type === 'number' && (
                                        <>
                                            <Grid item xs={3}>
                                                <NumericFormat
                                                    customInput={TextField}
                                                    fullWidth
                                                    placeholder={''}
                                                    name="default"
                                                    value={values?.default || ''}
                                                    onValueChange={(values) => {
                                                        handleChange({target: {name: 'default', value: values.floatValue}});
                                                    }}
                                                    min={values?.min || -99999999999}
                                                    max={values?.max || 99999999999}
                                                    label={t('attributes.default')}
                                                    decimalScale={values?.decimals}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <NumericFormat
                                                    customInput={TextField}
                                                    fullWidth
                                                    placeholder={''}
                                                    name="min"
                                                    value={values?.min || ''}
                                                    onValueChange={(values) => {
                                                        handleChange({target: {name: 'min', value: values.floatValue}});
                                                    }}
                                                    label={t('attributes.min')}
                                                    decimalScale={values?.decimals}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <NumericFormat
                                                    customInput={TextField}
                                                    fullWidth
                                                    placeholder={''}
                                                    name="max"
                                                    value={values?.max || ''}
                                                    onValueChange={(values) => {
                                                        handleChange({target: {name: 'max', value: values.floatValue}});
                                                    }}
                                                    label={t('attributes.max')}
                                                    decimalScale={values?.decimals}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <NumericFormat
                                                    customInput={TextField}
                                                    fullWidth
                                                    placeholder={''}
                                                    name="decimals"
                                                    value={values?.decimals || ''}
                                                    onValueChange={(values) => {
                                                        handleChange({target: {name: 'decimals', value: values.floatValue}});
                                                    }}
                                                    label={t('attributes.decimals')}
                                                    min={0}
                                                    max={10}
                                                    decimalScale={0}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {field?.type === 'text' && (
                                        <Grid item xs={12}>
                                            <TextField
                                                label={t('attributes.default')}
                                                value={values?.default}
                                                name="default"
                                                onChange={handleChange}
                                                fullWidth
                                                type="text"
                                            />
                                        </Grid>
                                    )}
                                    {field?.type === 'switch' && (
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                select
                                                label={t('attributes.default')}
                                                value={values?.default}
                                                onChange={handleChange}
                                                name="default"
                                            >
                                                <MenuItem value="true">
                                                    {t('common.true')}
                                                </MenuItem>
                                                <MenuItem value="false">
                                                    {t('common.false')}
                                                </MenuItem>
                                            </TextField>
                                        </Grid>
                                    )}
                                    {(field?.type === 'select' || field?.type === 'smart-select') && (
                                        <>
                                            <Grid item xs={3}>
                                            <TextField
                                                fullWidth
                                                label={t('attributes.value')}
                                                value={currentOption?.value || ''}
                                                onChange={(event) => {
                                                    setCurrentOption(produce(currentOption, draft => {
                                                        draft.value = event.target.value;
                                                    }));
                                                }}
                                                name="optionValue"
                                            />
                                        </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    fullWidth
                                                    label={t('attributes.label')}
                                                    value={currentOption?.label || ''}
                                                    onChange={(event) => {
                                                        setCurrentOption(produce(currentOption, draft => {
                                                            draft.label = event.target.value;
                                                        }));
                                                    }}
                                                    name="optionLabel"
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    label={t('common.datatype')}
                                                    value={currentOption?.datatype || ''}
                                                    onChange={(event) => {
                                                        setCurrentOption(produce(currentOption, draft => {
                                                            draft.datatype = event.target.value;
                                                        }));
                                                    }}
                                                    name="datatype"
                                                >
                                                    <MenuItem value="float">
                                                        {t('common.float')}
                                                    </MenuItem>
                                                    <MenuItem value="int">
                                                        {t('common.integer')}
                                                    </MenuItem>
                                                    <MenuItem value="string">
                                                        {t('common.string')}
                                                    </MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={3} container justifyContent="center">
                                                <Button
                                                    fullWidth
                                                    variant="outlined"
                                                    onClick={() => {
                                                        // Add to options, options is an array of dicts
                                                        let optionAdded = false;

                                                        setValues(produce(values, (draft) => {

                                                            // Check if currentOption is defined properly
                                                            if (!currentOption?.value || !currentOption?.label || !currentOption?.datatype) {
                                                                return;
                                                            }

                                                            // Cast options
                                                            const castedOption = {
                                                                ...currentOption,
                                                                value:
                                                                    currentOption.datatype === 'float'
                                                                        ? parseFloat(currentOption.value) || 0
                                                                        : currentOption.datatype === 'int'
                                                                            ? parseInt(currentOption.value, 10) || 0
                                                                            : currentOption.value?.toString() || '',
                                                            };

                                                            if (!draft.options) draft.options = [];

                                                            // Add option if it does not exist
                                                            if (!draft.options.find((option) => option.value === castedOption.value)) {
                                                                draft.options.push({
                                                                    value: castedOption.value,
                                                                    label: castedOption.label,
                                                                });
                                                                optionAdded = true;
                                                            }
                                                        }));

                                                        // Only reset currentOption if the option was successfully added
                                                        if (optionAdded) {
                                                            setCurrentOption({ value: '', label: '', datatype: currentOption.datatype });
                                                        }
                                                    }}
                                                >
                                                    {t('common.add')}
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Stack direction="row" spacing={1} justifyContent={"flex-start"}>
                                                    {values?.options?.map((option, index) => (
                                                        <Chip
                                                            key={index}
                                                            label={option.value}
                                                            onDelete={() => {
                                                                setValues(produce(values, draft => {
                                                                    draft.options = draft.options.filter(o => o.value !== option.value);
                                                                }))
                                                            }}
                                                        />
                                                    ))}
                                                </Stack>
                                            </Grid>

                                        </>
                                    )}
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <SelectionTiles
                                        options={FIELD_TYPES}
                                        onSelect={(option) => {
                                            onChange(produce(values, draft => {
                                                draft.type = option.value;
                                            }))
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <>
                            <TextField
                                name="name"
                                label={t('attributes.name')}
                                value={values?.name}
                                onChange={handleChange}
                                fullWidth
                            />
                            {values?.name && values?.name.includes(' ') && (
                                <Typography variant="caption" color="error">
                                    {t('dialogs.settings_items.name_whitespace_error')}
                                </Typography>
                            )}
                            {values?.name && values?.name.includes('.') && (
                                <Typography variant="caption" color="warning">
                                    {t('dialogs.settings_items.name_dot_warning')}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    <Stack direction="row" justifyContent="left" sx={{width: '100%'}} spacing={2}>
                        <Button variant="outlined" onClick={onClose}>
                            {t('common.cancel')}
                        </Button>
                        {(field && onRemove) && (
                            <Button variant="outlined" color="error" onClick={() => onRemove(field)}>
                                {t('common.delete')}
                            </Button>
                        )}
                    </Stack>
                    {isNameOkay && (
                        <Button variant="contained" onClick={() => {
                            onChange(values);
                            if (field)
                                onClose();
                        }}>
                            {field ? t('common.add') : t('common.continue')}
                        </Button>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );

}

SettingsItemDialog.propTypes = {
    field: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDone: PropTypes.func,
}

export default SettingsItemDialog;
