import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import SelectionTiles from "../../../elements/selection-tiles";
import SaveButton from "../../../elements/save-button";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import useSmFormik from "../../../../hooks/formik/use-sm-formik";
import {DatePicker} from "@mui/x-date-pickers-pro";
import PropTypes from "prop-types";
import SelectList from "../../../elements/select-list/select-list";
import SimulateLoading from "../../../elements/simulate-loading";

function AddCampaignDialog({open, onClose, onDone}) {

    const {t} = useTranslation();
    const [choice, setChoice] = useState(null);
    const {get, post} = useOmniaApi();
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [availableCampaigns, setAvailableCampaigns] = useState([]);
    const [progress, setProgress] = useState(false);
    const [resultMessage, setResultMessage] = useState(null);
    const [errorOccurred, setErrorOccurred] = useState(false);

    const handleSubmit = (values, helpers) => {
        helpers.setSubmitting(true);
        post("sm/campaigns", values)
            .then((data) => {
                onDone?.(data);
                onClose();
                helpers.resetForm();
            })
            .finally(() => {
                helpers.setSubmitting(false);
            });
    };

    const {campaignFormik} = useSmFormik(null, handleSubmit);

    const handleNewChoice = (option) => {
        setChoice(option?.value);
    };

    const handleSelect = (option) => {
        const value = option.value;
        setSelectedCampaigns([...selectedCampaigns, value]);
    };

    const handleImportCampaigns = () => {
        if (selectedCampaigns.length === 0) {
            alert(t("notify.select_atleast_one"));
        } else {
            setProgress(true); // Show progress bar
        }
    };

    const handleCompleteLoading = async () => {
        try {
            await post('sm/platform-campaigns/import_campaigns', {selected_campaigns: selectedCampaigns});
            setResultMessage({type: 'success', message: t('Import successful')});
        } catch (error) {
            setErrorOccurred(true);
            setResultMessage({type: 'error', message: t('Error occurred')});
        }
    };

    const handleDeselect = (option) => {
        setSelectedCampaigns(selectedCampaigns.filter((item) => item !== option.value));
    }

    const CHOICES = [
        {
            label: t('marketing.campaigns.create_campaign'),
            value: 'create',
            icon: 'Plus',
            description: t('marketing.campaigns.create_campaign_info')
        },
        {
            label: t('marketing.campaigns.load_campaign'),
            value: 'load',
            icon: 'Download02',
            description: t('marketing.campaigns.load_campaign_info')
        }
    ];

    useEffect(() => {
        if (open) {
            setChoice(null); // Reset choice when the dialog is opened
        }
    }, [open])

    useEffect(() => {
        if (choice === 'load') {
            get('sm/platform-campaigns').then((data) => {
                const inactive_campaigns = data
                    .filter((campaign) => campaign.state !== 'REMOVED')
                    .filter((campaign) => !campaign.is_active);

                data = inactive_campaigns.map((campaign) => ({
                    value: campaign.id,
                    title: campaign.name,
                    chip: campaign.state
                }));

                setAvailableCampaigns(data);
            });
        }
    }, [choice]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Typography variant="h4">
                    {choice === 'load'
                        ? t('dialogs.load_existing_campaign.title')
                        : t('dialogs.create_new_campaign.title')}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {choice === 'load'
                        ? t('dialogs.load_existing_campaign.description')
                        : t('dialogs.create_new_campaign.description')}
                </Typography>
            </DialogTitle>
            {progress ? (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '200px',
                        padding: 3,
                        gap: 2,
                    }}
                >
                    <Typography variant="h6" color="textSecondary">
                        {errorOccurred ? resultMessage?.message : t('marketing.campaigns.importing_campaigns')}
                    </Typography>
                    <SimulateLoading
                        onMiddle={async () => {
                            await handleCompleteLoading();
                        }}
                        onComplete={() => {
                            if (!errorOccurred) {
                                setProgress(false);
                                onClose();
                            }
                        }}
                        stopSimulation={errorOccurred}
                    />
                </Box>
            ) : (
                <>
                    <DialogContent style={{padding: 0}}>
                        {!choice ? (
                            <Box sx={{p: 2}}>
                                <SelectionTiles
                                    options={CHOICES}
                                    onSelect={handleNewChoice}
                                    tileHeight={200}
                                />
                            </Box>
                        ) : (
                            <>
                                {choice === 'create' && (
                                    <Grid container spacing={2} sx={{mt: 1, p: 2}}>
                                        <Grid item xs={12}>
                                            <TextField
                                                sx={{mt: 2}}
                                                autoFocus
                                                fullWidth
                                                label={t('attributes.name')}
                                                {...campaignFormik.getFieldProps('name')}
                                                error={
                                                    campaignFormik.touched.name &&
                                                    Boolean(campaignFormik.errors.name)
                                                }
                                                helperText={
                                                    campaignFormik.touched.name &&
                                                    campaignFormik.errors.name
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <DatePicker
                                                minDate={new Date()}
                                                label={t('attributes.start_date')}
                                                yearsOrder="desc"
                                                format="dd.MM.yyyy"
                                                views={['year', 'month', 'day']}
                                                slotProps={{
                                                    textField: {fullWidth: true}
                                                }}
                                                value={campaignFormik.values.start_date || null}
                                                onChange={(newValue) =>
                                                    campaignFormik.setFieldValue('start_date', newValue)
                                                }
                                                onBlur={() =>
                                                    campaignFormik.setFieldTouched('start_date')
                                                }
                                            />
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <DatePicker
                                                minDate={new Date()}
                                                label={t('attributes.end_date')}
                                                yearsOrder="desc"
                                                format="dd.MM.yyyy"
                                                views={['year', 'month', 'day']}
                                                slotProps={{
                                                    textField: {fullWidth: true}
                                                }}
                                                value={campaignFormik.values.end_date || null}
                                                onChange={(newValue) =>
                                                    campaignFormik.setFieldValue('end_date', newValue)
                                                }
                                                onBlur={() =>
                                                    campaignFormik.setFieldTouched('end_date')
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {choice === 'load' && (
                                    <SelectList
                                        options={availableCampaigns}
                                        values={selectedCampaigns}
                                        onSelect={handleSelect}
                                        onDeselect={handleDeselect}
                                    />
                                )}
                            </>
                        )}
                    </DialogContent>
                    {choice && (
                        <DialogActions>
                            <Stack direction="row" justifyContent="space-between" spacing={2} sx={{width: '100%'}}>
                                <Button onClick={() => setChoice(null)}>{t('common.back')}</Button>
                                {choice === 'create' && (
                                    <SaveButton
                                        formik={campaignFormik}

                                        // onClick={campaignFormik.handleSubmit}
                                        // loading={campaignFormik.isSubmitting}
                                    />
                                )}
                                {choice === 'load' && (
                                    <SaveButton
                                        label={t('common.buttons.import')}
                                        onClick={handleImportCampaigns}
                                    />
                                )}
                            </Stack>
                        </DialogActions>
                    )}
                </>
            )}
        </Dialog>
    );
}

AddCampaignDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDone: PropTypes.func
};

export default AddCampaignDialog;
