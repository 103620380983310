import React, {useEffect, useState} from "react";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import SelectList from "../../../elements/select-list/select-list";
import {useTheme} from "@mui/system";
import PropTypes from "prop-types";

function SelectExternalModel({onSelectionChange}){

    const { get } = useOmniaApi();
    const theme = useTheme();
    const [ selected, setSelected ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ models, setModels ] = useState([]);

    const handleSelect = (option) => {
        setSelected(prev => [...prev, option]);
    }

    const handleDeselect = (option) => {
        setSelected(prev => prev.filter(o => o.value !== option.value));
    }

    useEffect(() => {
        get('ai/ai_models/available_models').then(data => {
            setModels(data);
        }).finally(() => {
            setTimeout(() => {
                setLoading(false);
            }, 250);
        })
    }, []);

    useEffect(() => {
        onSelectionChange(selected.map(s => s.data));
    }, [selected]);

    return (
        <SelectList
            loading={loading}
            withSearch={true}
            height={400}
            options={models?.map(m => {
                return {
                    value: m.connector?.id + ':' + m.integration_id + ':' + m.name,
                    avatar: m.logo ? <img alt='logo' style={{height: 20, width: 20}} src={m.logo + (theme.palette.mode === 'light' ? '-dark.png' : '-light.png')} /> : null,
                    title: m.name,
                    subheader: m.connector?.name + ' (' + m.integration_name + ')',
                    data: m
                }
            })}
            values={selected.map(s => s.value)}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
        />
    )
}

SelectExternalModel.propTypes = {
    onSelectionChange: PropTypes.func
}

export default SelectExternalModel;