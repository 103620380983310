import React from "react";
import {Chip, Link, Stack, Typography} from "@mui/material";
import OnIcon from "../icon";
import PropTypes from "prop-types";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import {Link as RouterLink} from "react-router-dom";
import {PropertyListItem} from "../property-list-item";

function SharingStatus(props){

    const {
        entity = null,
        variant = 'typography',
        propertyListItemAlign = 'horizontal',
        minimal = false
    } = props;

    const theme = useTheme();
    const { t } = useTranslation();

    if(!entity)
        return null;

    if(variant === 'typography'){
        return entity?.is_owner ? (entity?.only_for_me ? (
            <Stack direction="row" alignItems="center" spacing={1}>
                <OnIcon iconName="Lock01" size="tiny" sx={{color: theme.palette.text.secondary}} />
                {!minimal && (
                    <Typography variant="caption" color="textSecondary" noWrap>
                        {t('common.only_for_me')}
                    </Typography>
                )}
            </Stack>
        ) : (
            <Stack direction="row" alignItems="center" spacing={1}>
                <OnIcon iconName="Share01" size="tiny" sx={{color: theme.palette.text.secondary}} />
                {!minimal && (
                    <Typography variant="caption" color="textSecondary" noWrap>
                        {t('common.shared_with_others')}
                    </Typography>
                )}
            </Stack>
        )) : (
            <Stack direction="row" alignItems="center" spacing={1}>
                <OnIcon iconName="Download01" size="tiny" sx={{color: theme.palette.text.secondary}} />
                {!minimal && (
                    <Typography variant="caption" color="textSecondary" noWrap>
                        {t('common.shared_by_name', {name: entity?.owner?.name})}
                    </Typography>
                )}
            </Stack>
        )
    }

    if(variant === 'chip'){
        return (
            <Chip
                icon={<OnIcon iconName={(entity?.is_owner && entity?.only_for_me) ? 'Lock01' : 'Share01'} size="tiny" sx={{color: theme.palette.text.primary}} />}
                label={
                    entity?.is_owner
                        ? (entity?.only_for_me ? t('common.only_for_me') : t('common.shared_with_others'))
                        : t('common.shared_by_name', {name: entity?.owner?.name})
                }
            />
        )
    }

    if(variant === 'property-list-item'){
        return (
            <PropertyListItem
                align={propertyListItemAlign}
                disableGutters
                label={t('common.sharing_visibility')}
            >
                <Typography color="textSecondary" variant="body1" noWrap>
                    {entity?.is_owner
                        ? (entity?.only_for_me ? t('common.only_for_me') : t('common.shared_with_others'))
                        : t('common.shared_by_name', {name: entity?.owner?.name})}
                </Typography>
            </PropertyListItem>
        )
    }

    return null;

}

SharingStatus.propTypes = {
    entity: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(['typography', 'chip', 'property-list-item']),
    propertyListItemAlign: PropTypes.oneOf(['horizontal', 'vertical'])
}

export default SharingStatus;