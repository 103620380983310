import React, {useState, useEffect, useRef} from "react";
import {Box, Card, CircularProgress, Container, List, Stack, Typography} from "@mui/material";
import PropTypes from "prop-types";
import useSocket from "src/omnia/hooks/use-socket";
import { produce } from "immer";
import moment from "moment";
import Message from "./chat-assistant-message";
import {useSelector} from "react-redux";
import {useTheme} from "@mui/system";

function ChatAssistantViewer(props) {

    const {
        chatId = null,
        smallVersion = false,
        onAssistantNameChange = null,
        maxWidth = false,
        onUpdate = null,
        height = null,
        pb = 20,
        ...rest
    } = props;

    const theme = useTheme();
    const [assistant, setAssistant] = useState(null);
    const [messages, setMessages] = useState([]);
    const [initial, setInitial] = useState(true);
    const [scrolledChunks, setScrolledChunks] = useState(true);
    const messagesEndRef = useRef(null);
    const [messageChunks, setMessageChunks] = useState(null);
    const [canStream, setCanStream] = useState(false);
    const [runningProcedures, setrunningProcedures] = useState([]);
    const user = useSelector(state => state.account.user);

    const chat = chatId ? chatId : user?.current_assistant_chat || null;

    const appendMessages = (newMessages) => {
        setMessages(prev => produce(prev, draft => {
            newMessages.forEach(newMessage => {
                const existingIndex = draft.findIndex(m => m.id === newMessage.id);
                if (existingIndex !== -1) {
                    draft[existingIndex] = newMessage;
                } else {
                    draft.push(newMessage);
                }
            });
            draft.sort((a, b) => (new Date(b.sent_at)).getTime() < (new Date(a.sent_at)).getTime() ? 1 : -1);
        }));
        setTimeout(() => {
            setInitial(false);
        }, (newMessages?.length * 30) + 250);
    };

    const scrollDown = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({behavior: 'instant'});
        }
    }

    const {isConnected} = useSocket('assistant/' + chat, (data) => {

        if (data['kind'] === 'messages') {
            appendMessages(data['data']);
        }

        if( data['kind'] === 'message-chunk'){
            setMessageChunks(prev => {
                if(prev === null){
                    return data['chunk'];
                } else {
                    return prev + data['chunk'];
                }
            })
            setScrolledChunks(prev => {
                if(prev < 50){
                    scrollDown();
                }
                return prev + 1;
            });
        }

        if (data['kind'] === 'assistant') {
            setAssistant(data['data']);
            onAssistantNameChange?.(data['data']?.['name']);
        }

        if (data['kind'] === 'assistant_name') {
            onAssistantNameChange?.(data['data']);
        }

        if (data['kind'] === 'message') {
            appendMessages([data['data']]);
            setMessageChunks(null);
        }

        if (data['kind'] === 'model_can_stream') {
            setCanStream(data['data']);
        }

        if (data['kind'] === 'chat') {
            if (onUpdate) {
                onUpdate(data['data']);
            }
        }

        if (data['kind'] === 'start-action') {
            setrunningProcedures(prev => prev.concat([{
                id: data['id'],
                title: data['title'],
                agent: data['agent']
            }]));
            scrollDown();
        }

        if (data['kind'] === 'update-action') {
            setrunningProcedures(prev => prev.map(a => {
                if (a.id === data['id']) {
                    return {
                        id: a['id'],
                        title: data['title'],
                        agent: data['agent']
                    }
                }
                return a;
            }));
        }

        if (data['kind'] === 'end-action') {
            setrunningProcedures(prev => prev.filter(a => a.id !== data['id']));
        }

    }, true);

    useEffect(() => {
        scrollDown();
    }, [messages?.filter(m => m.role === 'user')?.length]);

    useEffect(() => {
        if(canStream){
            setScrolledChunks(0);
        } else {
            scrollDown();
        }
    }, [canStream, messages?.filter(m => m.role === 'assistant')?.length]);

    useEffect(() => {
        setInitial(true);
        setCanStream(false);
        setMessages([]);
    }, [chatId]);

    if (!isConnected) {
        return (
            <div style={{height: height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {/*<CircularProgress/>*/}
            </div>
        )
    }

    const messageStack = (
        <Stack spacing={0} sx={{maxWidth: '100%'}}>
            {messages.map((message, index) => (
                <Message
                    key={'message-' + index}
                    index={index}
                    omitAnimation={canStream}
                    initial={initial}
                    smallVersion={smallVersion}
                    assistant={assistant}
                    message={message}
                    hideReactions={runningProcedures?.length > 0}
                    lastUserMessage={
                        (index === messages.length - 1) ||
                        (
                            (messages[index].sender_name !== messages[index + 1].sender_name) ||
                            (
                                (((new Date(messages[index + 1].sent_at)) - (new Date(messages[index].sent_at))) > 300000) &&
                                ((moment(messages[index + 1].sent_at).fromNow()) !== (moment(messages[index].sent_at).fromNow()))
                            )
                        )
                    }
                    firstUserMessage={
                        (index === 0) ||
                        (
                            (messages[index].sender_name !== messages[index - 1].sender_name) ||
                            (
                                (((new Date(messages[index].sent_at)) - (new Date(messages[index - 1].sent_at))) > 300000) &&
                                ((moment(messages[index].sent_at).fromNow()) !== (moment(messages[index - 1].sent_at).fromNow()))
                            )
                        )
                    }
                />
            ))}

            {(canStream && messageChunks) && (
                <Message
                    key={'message-' + messages.length}
                    index={messages.length}
                    initial={initial}
                    omitAnimation={true}
                    smallVersion={smallVersion}
                    assistant={assistant}
                    hideReactions={true}
                    message={{
                        id: -1,
                        sender: null,
                        sent_at: new Date().toISOString(),

                        // Instead of plain messageChunks, return one without `|^|` in the string
                        message: messageChunks.replace(/\|\^\|/g, ''),

                        role: 'assistant',
                        answered: true,
                        chat: null,
                        is_invisible: false,
                        files: [],
                        read_by: [],
                    }}
                    lastUserMessage={false}
                    firstUserMessage={true}
                />
            )}

            <Box sx={{py: smallVersion ? 1 : 2}} id="AssistantProcedureNotes">
                {runningProcedures.map(procedure => (
                    <Card
                        key={'procedure-' + procedure.id}
                        sx={{
                            p: 2,
                            mb: 2,
                            display: 'inline-block',
                            maxWidth: 'fit-content',
                            borderRadius: theme?.config?.message_radius + 'px',
                        }}
                    >
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <CircularProgress size={20} thickness={5}/>
                            <Typography variant="overline" color="textSecondary">
                                {procedure?.agent}: {procedure?.title || 'Warten ...'}
                            </Typography>
                        </Stack>
                    </Card>
                ))}
            </Box>

            <div ref={messagesEndRef}/>
        </Stack>
    )

    if(height){
        return (
            <List sx={{height: height, overflow: 'auto', px: 2}}>
                {messageStack}
            </List>
        )
    }

    return (
        <Container maxWidth={maxWidth} sx={{pb: pb}} {...rest}>
            {messageStack}
        </Container>
    )
}

ChatAssistantViewer.propTypes = {
    smallVersion: PropTypes.bool,
    pb: PropTypes.number,
    chatId: PropTypes.number,
    onUpdate: PropTypes.func,
    onAssistantNameChange: PropTypes.func,
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', null]),
    height: PropTypes.number
}

export default ChatAssistantViewer;