import React, { useEffect, useCallback } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { usePopover } from "src/omnia/hooks/use-popover";
import { AppsPopover } from "./apps-popover";
import { useIsMobile } from "../../../../hooks/use-is-mobile";
import { useNavigate } from "react-router-dom";
import OnIcon from "../../../elements/icon";
import { useAppMode } from "../../../../hooks/use-app-mode";
import { useTranslation } from "react-i18next";

export const AppsButton = () => {
    const popover = usePopover();
    const appMode = useAppMode();
    const { t } = useTranslation();
    const { isFlying } = useIsMobile();
    const navigate = useNavigate();

    const handleOpenApp = useCallback(() => {
        navigate(appMode.availableApps[0].path);
    }, [navigate, appMode.availableApps]);

    // Only add the hotkey listener when there are multiple apps (i.e. when the popover exists)
    useEffect(() => {
        if (appMode.availableApps.length <= 1) return;

        const handleKeyDown = (event) => {
            // Check for Shift + Command (Meta) + K
            if (event.shiftKey && (event.metaKey || event.ctrlKey) && event.key === 'k') {
                event.preventDefault();
                // Simulate a click on the IconButton by using its ref as the anchor
                if (popover.anchorRef.current) {
                    popover.handleOpen({ currentTarget: popover.anchorRef.current });
                }
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [appMode.availableApps, popover]);

    if (appMode.availableApps.length === 0) return null;

    if (appMode.availableApps.length === 1)
        return (
            <Tooltip
                enterDelay={1000}
                title={t(appMode.availableApps[0].title)}
                placement={isFlying ? "left" : "bottom"}
            >
                <IconButton onClick={handleOpenApp}>
                    <OnIcon iconName={appMode.availableApps[0].icon} />
                </IconButton>
            </Tooltip>
        );

    return (
        <>
            <Tooltip
                enterDelay={1000}
                title={t("common.tooltips.show_app_selector")}
                placement={isFlying ? "left" : "bottom"}
            >
                <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
                    <OnIcon iconName="Grid01" />
                </IconButton>
            </Tooltip>
            <AppsPopover popover={popover} />
        </>
    );
};
