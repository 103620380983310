import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {
    Box,
    Dialog,
    Grid,
    Typography,
    Menu,
    Stack,
    IconButton,
    Button, DialogActions, DialogContent,
} from '@mui/material';
import {Trash as DeleteIcon} from 'src/omnia/icons/trash'
import {Archive as ArchiveIcon} from 'src/omnia/icons/archive';
import {Check as CheckIcon} from 'src/omnia/icons/check';
import {DocumentText as DocumentTextIcon} from 'src/omnia/icons/document-text';
import {Eye as EyeIcon} from 'src/omnia/icons/eye';
import {EyeOff as EyeOffIcon} from 'src/omnia/icons/eye-off';
import {Users as UsersIcon} from 'src/omnia/icons/users';
import {
    deleteCard,
    updateCard,
    updateAttachments,
    addChecklist,
} from 'src/omnia/store/actions/kanban-actions';
import Details from './details';
import Attachments from './attachments';
import Checklist from './checklist';
import NewComment from './new-comment';
import Comment from './comment';
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {useDropzone} from "react-dropzone";
import useApi from "src/omnia/hooks/use-api";
import {Close as CloseIcon} from "@mui/icons-material";
import {KanbanCardAction} from "./kanban-action-button";
import {useTheme} from "@mui/system";
import LabelSelector from "src/omnia/components/modules/spaces/space-labels/label-selector";
import {useLocation, useNavigate} from "react-router";
import {useIsMobile} from "src/omnia/hooks/use-is-mobile";
import { produce } from "immer";
import {useFormik} from "formik";
import SaveButton from "../../../../../elements/save-button";

function CardEditModal({card, list}) {

    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const members = useSelector(state => state.kanban.members);
    const boardId = useSelector(state => state.kanban.boardId);
    const [comments, setComments] = useState([]);
    const [allMember, setAllMember] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const users = useSelector(state => state.users.users);
    const user = useSelector(state => state.account.user);
    const navigate = useNavigate();
    const [addMemberOpen, setAddMemberOpen] = useState(false);
    const {isMobile} = useIsMobile();
    const addMemberRef = useRef(null);
    const addLabelRef = useRef(null);
    const {put, post, del, get, uploadStatus} = useApi();
    const fileInputRef = useRef(null);
    const location = useLocation();
    const [isTransporting, setIsTransporting] = useState(false);

    const handleDeleteComment = (id) => {
        setComments(prev => prev.filter(c => c.id !== id));
    }

    const handleToggleDetails = () => {
        setShowDetails(prev => !prev);
    }

    const handleDrop = useCallback((acceptedFiles) => {
        setIsTransporting(true);
        put('pm/project-board-list-cards/' + card.id + '/attachments', {'files': acceptedFiles}).then(data => {
            dispatch(updateAttachments(boardId, data));
        }).finally(() => setIsTransporting(false));
    }, []);

    const {getInputProps} = useDropzone({
        onDrop: handleDrop
    });

    const addFiles = () => {
        fileInputRef.current.click();
    }

    const handleAddMember = (member) => {
        // dispatch add member
        post('pm/project-board-list-cards/' + card.id + '/add_member', {member: member.id}).then(card => {
            dispatch(updateCard(boardId, card));
        });
    }

    const handleRemoveMember = (member) => {
        // dispatch remove member
        post('pm/project-board-list-cards/' + card.id + '/remove_member', {member: member.id}).then(card => {
            dispatch(updateCard(boardId, card));
        })
    }

    const closeAddMember = () => {
        setAddMemberOpen(false);
    }

    const openAddMember = () => {
        setAddMemberOpen(true);
    }

    const handleSubscribe = () => {
        put('pm/project-board-list-cards', {...{isSubscribed: true}, ...{id: card.id}}).then(card => {
            dispatch(updateCard(boardId, card));
        });
    };

    const handleUnsubscribe = async () => {
        put('pm/project-board-list-cards', {...{isSubscribed: false}, ...{id: card.id}}).then(card => {
            dispatch(updateCard(boardId, card));
        });
    };

    const handleArchive = async () => {
        post('pm/project-board-list-cards/' + card.id + '/archive');
    }

    const handleUnarchive = async () => {
        post('pm/project-board-list-cards/' + card.id + '/unarchive');
    }

    const handleDelete = async () => {
        if (window.confirm('Möchtest du das Ticket wirklich löschen?')) {
            del('pm/project-board-list-cards', card.id).then(() => {
                dispatch(deleteCard(boardId, card.id));
            });
        }
    };

    const handleAddChecklist = async () => {
        post('pm/project-board-list-cards/' + card.id + '/checklists', {name: 'Meilensteine', card: card.id}).then(checklist => {
            dispatch(addChecklist(boardId, card.id, checklist));
        })
    };

    const handleClose = () => {
        navigate(location.pathname.split('#')[0])
    };

    const handleSubmit = (values, helpers) => {
        helpers.setSubmitting(true);
        put('pm/project-board-list-cards', values).then(card => {
            dispatch(updateCard(boardId, card));
        }).finally(() => {
            helpers.setSubmitting(false);
        })
    }

    const initialValues = useMemo(() => {
        return {
            id: card.id,
            name: card.name,
            description: card.description,
        }
    }, [JSON.stringify(card)]);

    const cardFormik = useFormik({
        initialValues: initialValues,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    })

    useEffect(() => {
        setAllMember(users.filter(u => members.includes(u.id)));
    }, [members, users]);

    useEffect(() => {
        setOpen(parseInt(location.hash.substring(1)) === parseInt(card.id));
    }, [location, card]);

    useEffect(() => {
        setComments(null);
        if (open) {
            get('/pm/project-board-list-cards/' + card.id + '/comments').then(data => {
                setComments(data);
            });
        }
    }, [open, card?.id]);

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            sx={{backgroundColor: 'backgroundDefault'}}
            fullWidth
            fullScreen={isMobile}
            maxWidth="md"
        >
            <DialogContent sx={{padding: 0}}>
                <Grid container>
                    {isMobile && (
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{p: 3}}>
                                <Typography variant="h5" color="textPrimary">
                                    Ticket bearbeiten
                                </Typography>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon/>
                                </IconButton>
                            </Stack>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={8}>
                        <Box sx={{p: 3, pt: 4, pb: 4}}>
                            <Details card={card} list={list} formik={cardFormik}/>
                            <Attachments card={card}/>
                            {card.checklists.length > 0 && (
                                <Box>
                                    <Box mt={2}>
                                        <Typography variant="overline" color="textSecondary">
                                            Checklisten
                                        </Typography>
                                    </Box>
                                    {card.checklists.map((checklist, i) => (
                                        <Box mt={2} key={"card_checklist_" + i}>
                                            <Checklist
                                                card={card}
                                                checklist={checklist}
                                                style={{
                                                    '& + &': {
                                                        marginTop: theme.spacing(3)
                                                    }
                                                }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            <Box mt={2}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                    <Typography variant="overline" color="textSecondary">
                                        Aktivität
                                    </Typography>
                                    <Button onClick={handleToggleDetails}>
                                        {showDetails ? "Details ausblenden" : "Details anzeigen"}
                                    </Button>
                                </Stack>
                                <Box mt={2}>
                                    <NewComment
                                        cardId={card.id.toString()}
                                        onCommentAdded={(comment) => {
                                            setComments(prevComments => prevComments ? [comment, ...prevComments ] : [comment])
                                        }}/>
                                    <Box mt={2}>
                                        <Stack
                                            direction="column"
                                            spacing={2}
                                        >
                                            {comments?.filter(c => showDetails ? true : !c.is_system).map((comment, i) => (
                                                <Comment
                                                    key={"card_comment_" + i}
                                                    comment={comment}
                                                    onDelete={handleDeleteComment}
                                                />
                                            ))}
                                        </Stack>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box
                            sx={{
                                backgroundColor: 'backgroundDefault',
                                px: 3,
                                py: 4,
                                height: '100%'
                            }}
                        >
                            <Box mb={2}>
                                <Typography variant="overline" color="textSecondary">
                                    Hinzufügen
                                </Typography>
                            </Box>
                            <Stack spacing={1}>
                                <KanbanCardAction icon={<CheckIcon fontSize="small"/>} onClick={handleAddChecklist}>
                                    Checkliste
                                </KanbanCardAction>
                                <KanbanCardAction
                                    icon={<UsersIcon fontSize="small"/>}
                                    onClick={openAddMember}
                                    menuRef={addMemberRef}
                                >
                                    Benutzer
                                </KanbanCardAction>
                                <LabelSelector
                                    card={card}
                                />
                                <KanbanCardAction
                                    icon={<DocumentTextIcon fontSize="small"/>}
                                    onClick={addFiles}
                                    disabled={isTransporting}
                                    menuRef={addLabelRef}
                                >
                                    {isTransporting ? (uploadStatus === 100 ? "Verarbeiten ..." : ('Hochladen ' + uploadStatus + '%')) : 'Dateien'}
                                </KanbanCardAction>
                                <Box mt={2} mb={2}>
                                    <Typography variant="overline" color="textSecondary">
                                        Aktionen
                                    </Typography>
                                </Box>
                                {card.subscribers.filter(id => id === user.id).length > 0 ? (
                                    <KanbanCardAction
                                        icon={<EyeOffIcon fontSize="small"/>}
                                        onClick={handleUnsubscribe}
                                    >
                                        Abo beenden
                                    </KanbanCardAction>
                                ) : (
                                    <KanbanCardAction
                                        icon={<EyeIcon fontSize="small"/>}
                                        onClick={handleSubscribe}
                                    >
                                        Abonnieren
                                    </KanbanCardAction>
                                )}
                                {!card.is_archived ? (
                                    <KanbanCardAction
                                        icon={<ArchiveIcon fontSize="small"/>}
                                        onClick={handleArchive}
                                    >
                                        Archivieren
                                    </KanbanCardAction>
                                ) : (
                                    <KanbanCardAction
                                        icon={<ArchiveIcon fontSize="small"/>}
                                        onClick={handleUnarchive}
                                    >
                                        Wiederherstellen
                                    </KanbanCardAction>
                                )}
                                <KanbanCardAction
                                    icon={<DeleteIcon fontSize="small"/>}
                                    onClick={handleDelete}
                                >
                                    Löschen
                                </KanbanCardAction>
                            </Stack>
                            <Menu
                                keepMounted
                                anchorEl={addMemberRef.current}
                                open={addMemberOpen}
                                onClose={closeAddMember}
                            >
                                {allMember.map((member, i) => (
                                    <MenuItem
                                        key={'member_select_' + i}
                                        onClick={() => card.members.includes(member.id) ? handleRemoveMember(member) : handleAddMember(member)}
                                        sx={{minWidth: 300}}
                                    >
                                        <Checkbox checked={card.members.includes(member.id)}/>
                                        <ListItemText primary={member.name}/>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <SaveButton formik={cardFormik} autoSave={true} />
            </DialogActions>
            <input
                {...getInputProps()}
                style={{display: "none"}}
                ref={fileInputRef}
                type="file"
            />
        </Dialog>
    );
}

CardEditModal.propTypes = {
    card: PropTypes.object.isRequired,
    className: PropTypes.string,
    list: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool
};

export default CardEditModal;
