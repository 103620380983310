import React from 'react';
import PropTypes from 'prop-types';
import {useSettings} from 'src/omnia/hooks/use-settings';
import {useSections} from '../../../config';
import {VerticalLayout} from './vertical-layout';
import {FlyingLayout} from './flying-layout';
import {useAppMode} from "../../../hooks/use-app-mode";
import {useSelector} from "react-redux";

export const Layout = (props) => {

    const settings = useSettings();
    const sections = useSections();
    const appMode = useAppMode();
    const transitioning = useSelector(state => state.account.isTransitioning);
    const isHome = appMode.currentApp?.path === '/groon/home';

    if(!settings.isInitialized) return null;

    if (!isHome) {
        return (
            <VerticalLayout
                sections={sections}
                navColor={settings.navColor}
                navMode={settings.navMode}
                {...props}
            />
        );
    }

    return (
        <FlyingLayout
            sections={sections}
            navColor={settings.navColor}
            navMode={settings.navMode}
            {...props}
        />
    )
};

Layout.propTypes = {
    children: PropTypes.node
};
