import React, {useRef, useState} from "react";
import ReactQuill from 'react-quill-new';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

const QuillEditorRoot = styled('div')(({ theme, multiLine }) => {

    return {
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',

        '& .quill': {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflow: 'hidden',
        },

        '& .ql-snow.ql-toolbar': {
            border: `1px solid ${theme.palette.divider}`,
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',

            '& .ql-picker-label:hover, & .ql-picker-label.ql-active': {
                color: theme.palette.primary.main,
            },

            '& .ql-picker-item:hover, & .ql-picker-item.ql-selected': {
                color: theme.palette.primary.main,
            },

            '& button:hover, & button:focus, & button.ql-active': {
                color: theme.palette.primary.main,
                '& .ql-stroke': {
                    stroke: theme.palette.primary.main,
                },
            },

            '& .ql-stroke': {
                stroke: theme.palette.text.primary,
            },

            '& .ql-picker': {
                color: theme.palette.text.primary,
            },

            '& .ql-picker-options': {
                backgroundColor: theme.palette.background.paper,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[10],
                padding: theme.spacing(2),
            },
        },

        '& .ql-snow.ql-container': {
            borderBottom: 'none',
            borderColor: theme.palette.divider,
            borderLeft: 'none',
            borderRight: 'none',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: 'auto',
            overflow: 'hidden',

            '& .ql-editor': {
                color: theme.palette.text.primary,
                flex: 1,
                fontFamily: theme.typography.fontFamily,
                fontSize: theme.typography.fontSize,
                minHeight: multiLine ? 250 : null,
                height: 'auto',
                overflowY: 'auto',
                padding: theme.spacing(2),

                '&.ql-blank::before': {
                    color: theme.palette.text.secondary,
                    fontStyle: 'normal',
                    left: theme.spacing(2),
                },

                '& h1': {
                    marginTop: '0 !important',
                    marginBottom: '0 !important',
                },
                '& h2': {
                    marginTop: '0 !important',
                    marginBottom: '0 !important',
                },
                '& h3': {
                    marginTop: '0 !important',
                    marginBottom: '0 !important',
                },
                '& h4': {
                    marginTop: '0 !important',
                    marginBottom: '0 !important',
                },

                '& p': {
                    marginBlockEnd: '0 !important',
                    marginBlockStart: '0 !important',
                },

                '& ol': {
                    marginBlockEnd: '0 !important',
                    marginBlockStart: '0 !important',
                },

                '& ul': {
                    marginBlockEnd: '0 !important',
                    marginBlockStart: '0 !important',
                },
            },
        },
    }
});

export const QuillEditor = (props) => {

    const {
        sx,
        onChange = null,
        placeholder,
        defaultValue,
        noBorder,
        multiLine,
        ...other
    } = props;
    const ref = useRef(null);
    const [value, setValue] = useState(defaultValue || "");

    const handleChange = (content) => {
        setValue(content);
        onChange?.(content);
    };

    return (
        <QuillEditorRoot
            sx={sx}
            ref={ref}
            multiLine={multiLine}
            style={noBorder ? {border: 'none'} : {}}
        >
            <ReactQuill
                value={value}
                onChange={handleChange}
                placeholder={placeholder}
                theme="snow"
                modules={{
                    clipboard: {
                        matchVisual: false,
                    },
                }}
                {...other}
            />
        </QuillEditorRoot>
    );
};

QuillEditor.propTypes = {
    onChange: PropTypes.func,
    multiLine: PropTypes.bool,
    placeholder: PropTypes.string,
    noBorder: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
};
