import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import PropTypes from "prop-types";
import OnlineIndicator from "../modules/analytics/dashboards/online-indicator";
import debounce from "lodash.debounce";
import {Tooltip} from "@mui/material";

export default function SaveButton(props) {

    const {
        loading = false,
        label = null,
        progress = null,
        formik = null,
        autoSave = false,
        autoCreate = false,
        disabled = false,
        onlyDot = false,
        ...rest
    } = props;

    const {t} = useTranslation();
    const [manualMode, setManualMode] = useState(false);

    const handleManualSave = () => {
        setManualMode(true);
        formik.handleSubmit();
    }

    const handleAutomaticSubmit = () => {
        formik.handleSubmit();
    }

    const debouncedChangeHandler = useCallback(debounce(handleAutomaticSubmit, 2000), []);

    const isCreate = useMemo(() => !formik?.values?.id, [formik?.values?.id]);

    const showStatusIndicator = useMemo(() => {
        return !(manualMode && formik?.isSubmitting) && !(isCreate && !autoCreate);
    }, [manualMode, formik?.isSubmitting, isCreate, autoCreate]);

    const hasErrors = useMemo(() => {
        return Object.keys(formik?.errors || {}).length > 0;
    }, [formik?.errors]);

    const autoSaveActive = useMemo(() => {
        return isCreate
            ? (!disabled && autoCreate && formik?.dirty && !hasErrors)
            : (!disabled && autoSave && formik?.dirty && !hasErrors);
    }, [disabled, autoSave, autoCreate, formik?.dirty, hasErrors]);

    const getDirtyFields = useCallback(() => {
        const dirtyFields = {};
        if (formik) {

            // Check all initial values
            Object.keys(formik.initialValues).forEach((key) => {
                if (formik.values[key] !== formik.initialValues[key]) {
                    dirtyFields[key] = 'Value changed: ' + formik.values[key];
                }
            });

            // Check all values that are not in initial values
            Object.keys(formik.values).forEach((key) => {
                if (!Object.keys(formik.initialValues).includes(key)) {
                    dirtyFields[key] = 'Not in initial data';
                }
            });

        }
        return dirtyFields;
    }, [formik?.initialValues, formik?.values]);

    const tooltipText = useMemo(() => {
        if (hasErrors) {
            return t('attributes.' + Object.keys(formik?.errors || {})?.[0]) + ': ' + Object.values(formik?.errors || {})?.[0];
        } else {
            if (showStatusIndicator) {
                if (autoSaveActive && formik?.isSubmitting) {
                    return t('common.is_saving');
                } else {
                    if (formik?.dirty) {
                        const unsavedKeys = Object.keys(getDirtyFields())?.join(', ');
                        return t('common.unsaved_changes', {fields: unsavedKeys});
                    } else {
                        return t('common.changes_up_to_date');
                    }
                }
            } else {
                return null;
            }
        }
    }, [autoSaveActive, showStatusIndicator, hasErrors, formik?.errors, formik?.values, formik?.dirty, formik?.isSubmitting, getDirtyFields]);

    const indicator = (
        <OnlineIndicator
            status={(Object.keys(formik?.errors ?? {}).length > 0) ? 'busy' : (formik?.dirty ? 'offline' : 'online')}
        />
    )

    useEffect(() => {
        if (autoSaveActive) {
            debouncedChangeHandler();
        }
    }, [formik?.values, autoSaveActive]);

    useEffect(() => {
        if (manualMode && !formik?.isSubmitting) {
            setManualMode(false);
        }
    }, [formik?.isSubmitting]);

    if (formik) {
        return (
            <Tooltip title={tooltipText} placement="bottom">
                {onlyDot ? indicator : (
                    <LoadingButton
                        variant="contained"
                        startIcon={showStatusIndicator && indicator}
                        loading={manualMode && formik.isSubmitting}
                        disabled={disabled}
                        {...rest}

                        // In this case, we overwrite whatever is coming in because focus is on formik
                        onClick={handleManualSave}
                    >
                        {label ? label : ((autoSaveActive) ? t("common.saving") : (isCreate ? t("common.create") : t("common.save")))}
                    </LoadingButton>
                )}
            </Tooltip>
        )
    }

    return (
        <LoadingButton
            variant="contained"
            disabled={loading || disabled}
            loading={loading && !progress}
            {...rest}
        >
            {(progress && loading) ? (parseInt(progress) + '%') : label ? label : t("common.save")}
        </LoadingButton>
    )
}

SaveButton.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    progress: PropTypes.number,
    formik: PropTypes.object,
    autoSave: PropTypes.bool,
    autoCreate: PropTypes.bool,
}