import React, {useState} from "react";
import PropTypes from 'prop-types';
import {
    ListItemButton,
    ListItemText,
    SvgIcon,
    Tooltip,
    List,
    Typography,
    ButtonBase,
    Avatar,
    Box,
    ListItemAvatar, Stack, Divider
} from '@mui/material';
import OnIcon from "../../../elements/icon";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router";
import {usePopover} from "../../../../hooks/use-popover";
import {RouterLink} from "../../../elements/router-link";
import HistoryPopover from "../../../modules/ai-studio/history-popover";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import SmartPopper from "../../../elements/smart-popper";
import {useTheme} from "@mui/system";
import CustomSidebarItems from "../special-sidebar-item";

const FlyingNavSectionPopoverElement = (props) => {

    const {item, onClose, posPercentage, ...rest} = props;
    const { isMobile } = useIsMobile();
    const theme = useTheme();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const popover = usePopover();

    const hasItems = item?.items?.length > 0;

    const handleTransition = (path) => {
        navigate(path);
        popover.handleClose();
        onClose?.();
    };

    return (
        <>
            {item?.superCustomAction ? <CustomSidebarItems {...props} /> : (
                <ListItemButton
                    selected={item?.path === location?.pathname}
                    component={RouterLink}
                    href={item?.path}
                    ref={popover.anchorRef}
                    onClick={() => {
                        handleTransition(item?.path);
                    }}
                    onMouseEnter={() => {
                        if (hasItems) {
                            popover.handleOpen();
                            popover.queueOpen();
                        }
                    }}
                    onMouseLeave={() => {
                        if (!isMobile && (hasItems)) {
                            popover.queueClose();
                        }
                    }}
                    sx={{
                        borderRadius: 1,
                        cursor: 'pointer',
                        px: 1,
                        py: 1,
                    }}
                    {...rest}
                >
                    {item?.icon && (
                        <ListItemAvatar>
                            <OnIcon iconName={item?.icon} size="small" sx={{color: theme.palette.text.secondary}} />
                        </ListItemAvatar>
                    )}
                    <Tooltip enterDelay={1000} title={item?.tooltip && t(item?.tooltip)} placement="right">
                        <ListItemText
                            primary={(
                                <Typography variant="body1">
                                    {t(item?.title)}
                                </Typography>
                            )}
                        />
                    </Tooltip>
                </ListItemButton>
            )}

            {hasItems && (
                <FlyingNavSectionPopover
                    popover={popover}
                    posPercentage={posPercentage}
                    items={item?.items}
                />
            )}
        </>
    )
}

const FlyingNavSectionPopover = (props) => {
    const {popover, items, posPercentage, title, ...other} = props;
    const { isFlying } = useIsMobile();

    if(!items)
        return null;

    return (
        <SmartPopper
            popover={popover}
            placement={isFlying ? (posPercentage > 0.5 ? 'right-end' : 'right-start') : (posPercentage > 0.5 ? 'bottom-start' : 'bottom-end')}
            minWidth={150}
            {...other}
        >
            {title && (
                <>
                    <Stack direction="row" p={2}>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    </Stack>
                    <Divider />
                </>
            )}
            <List sx={{p: 1}}>
                {items?.map(item => (
                    <FlyingNavSectionPopoverElement
                        key={item?.title}
                        onClose={popover.handleClose}
                        posPercentage={posPercentage}
                        item={item}
                    />
                ))}
            </List>
        </SmartPopper>
    )

}

export const FlyingNavSection = ({section, posPercentage}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const { isFlying, isMobile } = useIsMobile();
    const popover = usePopover();
    const [blockGoingBack, setBlockGoingBack] = useState(false);
    const hasItems = section?.items?.length > 0;
    const modulePathIsIncluded = section?.items?.map(i => i?.path)?.some(path => location.pathname.includes(path));
    const isSelected = modulePathIsIncluded || (section?.path === location.pathname);
    const navigate = useNavigate();

    const handleTransition = (path) => {
        navigate(path);
    };

    return (
        <>
            <Tooltip enterDelay={1000} title={(hasItems && !isMobile) ? "" : t(section?.title)} placement={isFlying ? 'right' : 'bottom'}>
                <Box
                    component={ButtonBase}
                    onClick={() => {
                        popover.handleOpen();
                        if(section?.items?.filter(s => s.path)?.length > 0) {
                            handleTransition(section?.items?.filter(s => s.path)?.[0]?.path);
                        } else {
                            handleTransition(section?.path);
                        }
                    }}
                    // onClick={() => {
                    //     if(isMobile){
                    //         popover.handleOpen();
                    //     } else {
                    //         if(section?.items?.filter(s => s.path)?.length > 0) {
                    //             handleTransition(section?.items?.filter(s => s.path)?.[0]?.path);
                    //         } else {
                    //             handleTransition(section?.path);
                    //         }
                    //         popover.handleClose();
                    //     }
                    // }}
                    // onMouseEnter={() => {
                    //     if(!blockGoingBack) {
                    //         popover.handleOpen();
                    //         popover.queueOpen();
                    //     }
                    // }}
                    // onMouseLeave={() => {
                    //     if(!isMobile){
                    //         popover.queueClose();
                    //     }
                    //     setBlockGoingBack(false);
                    // }}
                    ref={popover.anchorRef}
                    sx={{
                        ...{
                            alignItems: 'center',
                            display: 'flex',
                            height: 40,
                            width: 40,
                            borderRadius: '50%',
                            color: 'text.secondary'
                        },
                        ...(isSelected ? {
                            borderWidth: 2,
                            borderStyle: 'solid',
                            borderColor: 'divider',
                            color: 'text.primary',
                        } : {})
                    }}
                >
                    {isSelected ? (
                        <Avatar sx={{height: 32, width: 32}}>
                            <OnIcon iconName={section?.icon}/>
                        </Avatar>
                    ) : (
                        <SvgIcon>
                            <OnIcon iconName={section?.icon}/>
                        </SvgIcon>
                    )}
                </Box>
            </Tooltip>
            {section?.superCustomAction === 'extend-intelligence-chats' ? (
                <HistoryPopover
                    popover={popover}
                    posPercentage={posPercentage}
                />
            ) : (
                <FlyingNavSectionPopover
                    popover={popover}
                    posPercentage={posPercentage}
                    items={section?.items}
                    title={section.title}
                />
            )}
        </>
    )

};

FlyingNavSectionPopoverElement.propTypes = {
    item: PropTypes.object,
    posPercentage: PropTypes.number,
    onClose: PropTypes.func,
}

FlyingNavSectionPopover.propTypes = {
    popover: PropTypes.object,
    items: PropTypes.array,
    posPercentage: PropTypes.number,
    title: PropTypes.string,
};

FlyingNavSection.propTypes = {
    section: PropTypes.object,
    posPercentage: PropTypes.number,
};
