import React from "react";
import {useSettings} from "./use-settings";
import {useAppMode} from "./use-app-mode";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/system";

function useSidebarWidth(){
    const settings = useSettings();
    const theme = useTheme();
    const appMode = useAppMode();
    const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
    const sideBarWidth = ((appMode.layout === 'vertical' && settings?.navMode === 'hidden') || appMode.layout !== 'vertical' || lgDown) ? 0 : 250;

    return sideBarWidth;
}

export default useSidebarWidth;