/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import {
    UPDATE_ACCOUNTS,
    UPDATE_SELECTED,
    UPDATE_ACCOUNT
} from 'src/omnia/store/actions/email-actions';
import { produce } from "immer";

const initialState = {
    accounts: [],
    selected: []
};

const EmailReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_SELECTED:
            return produce(state, draft => {
                draft.selected = action.payload;
            })

        case UPDATE_ACCOUNTS:
            return produce(state, (draft) => {
                draft.accounts = action.payload;
            });

        case UPDATE_ACCOUNT:
            return produce(state, (draft) => {
                const index = draft.accounts.findIndex(a => a.id === action.payload.id);
                draft.accounts[index] = action.payload;
            });

        default: {
            return state;
        }
    }
};

export default EmailReducer;
