import React from "react";
import {useTranslation} from "react-i18next";
import {List} from "@mui/material";
import {SideNavItem} from "../../layouts/dashboard/flying-layout/side-nav-item";
import {useDialog} from "../../../hooks/use-dialog";
import {useLocation} from "react-router-dom";
import usePagination from "../../../hooks/use-pagination";
import DashboardDialog from "./dashboards/dashboard-dialog";
import useApi from "../../../hooks/use-api";
import {useNotifications} from "../../../hooks/use-notifications";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import SharingStatus from "../../elements/sharing/sharing-status";

export default function DashboardsSidebarSection(){
    // TODO: If we have a lot of models we should pivot to a "models" button

    const { t } = useTranslation();
    const location = useLocation();
    const newDashboardDialog = useDialog();
    const { notifySuccess } = useNotifications();
    const { post } = useApi();
    const { hasRights } = useSecurityCheck();
    const elementLoader = usePagination({
        endpoint: 'core/dashboards',
        query: {ordering: 'created_at'},
        page: 1,
        pageSize: 500
    })

    const handleDashboardDialogSubmit = (values, helpers) => {

        helpers.setSubmitting(true);
        post('core/dashboards', values).then((data) => {

            elementLoader.setElements(prev => [...prev, data]);
            newDashboardDialog.handleClose();

            if(values?.id){
                helpers.resetForm({values: data});
            } else {
                notifySuccess(t);
                helpers.resetForm();
            }

        }).finally(() => {
            helpers.setSubmitting(false);
        })

    };

    return (
        <>
            <List sx={{py: 0}}>
                {elementLoader.loading ? (
                    <SideNavItem
                        title={t('common.loading')}
                        icon="BarChartCircle01"
                        disabled={true}
                    />
                ) : elementLoader.elements?.map(dashboard => (
                    <SideNavItem
                        title={dashboard.name}
                        icon="BarChartCircle01"
                        active={location.pathname?.includes(`/groon/analytics/dashboards/${dashboard.id}`)}
                        path={`/groon/analytics/dashboards/${dashboard.id}`}
                        chip={(
                            <SharingStatus entity={dashboard} minimal={true} />
                        )}
                    />
                ))}
                {hasRights("create_dashboards") && (
                    <SideNavItem
                        title={t('core.analytics.new_dashboard')}
                        icon="PlusCircle"
                        onClick={newDashboardDialog.handleOpen}
                        tooltip={t('core.analytics.new_dashboard_info')}
                    />
                )}
            </List>
            <DashboardDialog
                open={newDashboardDialog.open}
                handleClose={newDashboardDialog.handleClose}
                onSubmit={handleDashboardDialogSubmit}
                initialData={null}
            />
        </>
    )

}