import React, { useState } from "react";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Box,
    IconButton,
    MenuItem,
    Slider,
    Stack,
    Switch,
    TextField,
    Typography,
    Button,
    Tooltip, Link
} from "@mui/material";
import ColorPicker from "src/omnia/components/elements/color-picker";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import ThemePicker from "../../modules/home/control-center/theme-builder/theme-picker";
import SmartSelector from "../smart-selector";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {useSecurityCheck} from "../../../hooks/use-security-check";
import OnIcon from "../icon";
import {useTheme} from "@mui/system";
import DelayedCircularProgress from "../delayed-circular-progress";
import {DateTimePicker} from "@mui/x-date-pickers-pro";
import { produce } from "immer";
import IntegrationSelector from "../../modules/home/integrations/integration-selector";
import EditIcon from "@untitled-ui/icons-react/build/esm/Edit05";
import SettingsItemDialog from "./settings-item-dialog";
import {useDialog} from "../../../hooks/use-dialog";

function ListField({ field, value, onChange }) {
    const parseListStructure = () => {
        const elements = field?.type?.split('$')?.[1]?.split(';') || [];
        if (!elements) return [];
        return elements.map(element => {
            const [name, type] = element?.split(':');
            if (type.includes('select')) {
                const options = type.match(/\[(.*?)\]/)[1].split(',');
                return { name, type: 'select', options };
            }
            return { name, type };
        });
    };
    const { t } = useTranslation();
    const structure = parseListStructure();

    const handleListChange = (index, fieldName, fieldValue) => {
        const updatedList = [...value];
        updatedList[index] = {
            ...updatedList[index],
            [fieldName]: fieldValue
        };
        onChange({ target: { name: field?.name, value: updatedList } });
    };

    const addItem = () => {
        onChange({ target: { name: field?.name, value: [...value, {}] } });
    };

    const removeItem = (index) => {
        const updatedList = [...value];
        updatedList.splice(index, 1);
        onChange({ target: { name: field?.name, value: updatedList } });
    };

    return (
        <Box>
            {value.map((item, index) => (
                <Grid container spacing={2} key={index} alignItems="center" sx={{mb: 2}}>
                    {structure.map(({ name, type, options }, i) => (
                        <Grid item xs={(i === structure?.length - 1) ? 10 : 12} key={name}>
                            {type === 'select' && (
                                <TextField
                                    select
                                    fullWidth
                                    label={name}
                                    value={item[name] || ''}
                                    onChange={(e) =>
                                        handleListChange(index, name, e.target.value)
                                    }
                                >
                                    {options.map(option => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            {type === 'string' && (
                                <TextField
                                    fullWidth
                                    label={name}
                                    value={item[name] || ''}
                                    onChange={(e) =>
                                        handleListChange(index, name, e.target.value)
                                    }
                                />
                            )}
                            {type === 'text' && (
                                <TextField
                                    fullWidth
                                    multiline
                                    maxRows={9}
                                    label={name}
                                    value={item[name] || ''}
                                    onChange={(e) =>
                                        handleListChange(index, name, e.target.value)
                                    }
                                />
                            )}
                        </Grid>
                    ))}
                    <Grid item xs={2}>
                        <IconButton onClick={() => removeItem(index)}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            <Button
                fullWidth
                variant="outlined"
                onClick={addItem}
            >
                {t('common.add')}
            </Button>
        </Box>
    );
}

const getNestedValue = (obj, path) => {
    return path.split('.').reduce((o, key) => {
        return o && o[key];
    }, obj);
}

function SettingsItems(props) {

    const {
        settings,
        setSettings = null,
        handleChange,
        disabled = false,
        handleFieldChange = null,
        handleFieldRemove = null,
        fields = [],
        minimal = false,
        loading = false
    } = props;

    const theme = useTheme();
    const { t } = useTranslation();
    const { hasRights } = useSecurityCheck();
    const [visitedFields, setVisitedFields] = useState({});
    const settingsFieldsDialog = useDialog();

    const markFieldVisited = (fieldName) => {
        setVisitedFields((prev) => ({
            ...prev,
            [fieldName]: true
        }));
    };

    const onChange = (event) => {

        handleChange?.(event);

        if(setSettings){
            const { name, value } = event.target;

            setSettings((prev) =>
                produce(prev, (draft) => {
                    const keys = name.split('.');
                    const lastKey = keys.pop();
                    let current = draft;
                    keys.forEach((key) => {
                        if (!current[key]) current[key] = {};
                        current = current[key];
                    });
                    current[lastKey] = value;
                })
            );
        }
    };

    if(loading){
        return (
            <div style={{
                height: '100%',
                minHeight: 200,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <DelayedCircularProgress size={20}/>
            </div>
        )
    }

    return (
        <>

            {fields?.length === 0 ? (
                <Stack
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        width: '100%', textAlign: 'center',
                        height: '100%', minHeight: 200,
                    }}
                >
                    <OnIcon
                        iconName="Tool02"
                        size="large"
                        sx={{ color: theme.palette.text.secondary }}
                    />
                    <Typography variant="h5" color="textSecondary">
                        {t("common.no_settings")}
                    </Typography>
                </Stack>
            ) : fields
                ?.filter(field => (field?.isVisible ? field?.isVisible(settings) : true))
                ?.filter(field => field?.rights ? hasRights(field?.rights) : true)
                ?.map(field => {

                    const fieldValue = getNestedValue(settings, field.name);
                    const fieldVisited = getNestedValue(visitedFields, field.name) === true;

                    // Compute error conditions for number fields
                    let isNumberError = false;
                    if(field?.type === 'number') {
                        const valueNum = parseFloat(fieldValue || 0);
                        const min = field?.min || -99999999999;
                        const max = field?.max || 99999999999;
                        isNumberError = (valueNum < min) || (valueNum > max);
                    }

                    return (
                        <Grid
                            container
                            item
                            xs={12}
                            key={field?.name}
                            spacing={minimal ? 1 : 2}
                            sx={{mb: minimal ? 2 : 3}}
                        >
                            <Grid item xs={12} lg={minimal ? 12 : handleFieldChange ? 6 : 8}>
                                {minimal ? (
                                    <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
                                        <Tooltip title={t(field?.description)} placement="right">
                                            <Typography variant="subtitle1">
                                                {t(field?.label) || field?.name}
                                            </Typography>
                                        </Tooltip>
                                        {handleFieldChange && (
                                            <Link
                                                component="button"
                                                variant="caption"
                                                color="text.disabled"
                                                onClick={() => {
                                                    settingsFieldsDialog.handleOpen(field?.name || null);
                                                }}
                                            >
                                                {t('common.edit')}
                                            </Link>
                                        )}
                                    </Stack>
                                ) : (
                                    <Stack justifyContent={field?.type?.includes('list$') ? "flex-start" : "center"} sx={{height: '100%'}}>
                                        <Typography variant="subtitle1">
                                            {t(field?.label)}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {t(field?.description)}
                                        </Typography>
                                        {handleFieldChange && (
                                            <Link
                                                component="button"
                                                variant="caption"
                                                color="text.disabled"
                                                onClick={() => {
                                                    settingsFieldsDialog.handleOpen(field?.name || null);
                                                }}
                                            >
                                                {t('common.edit')}
                                            </Link>
                                        )}
                                    </Stack>
                                )}
                            </Grid>
                            <Grid item xs={12} lg={minimal ? 12 : 4}>
                                {field?.type === 'checkbox' && (
                                    <FormControlLabel
                                        disabled={(field?.disabled || false)}
                                        required={field?.required || false}
                                        control={(
                                            <Checkbox
                                                disabled={disabled}
                                                checked={fieldValue || false}
                                                value={fieldValue}
                                                name={field?.name}
                                                onChange={event => {
                                                    const checked = event.target.checked;
                                                    onChange({
                                                        target: {
                                                            name: field?.name,
                                                            value: checked
                                                        }
                                                    })
                                                }}
                                            />
                                        )}
                                        label={t(field?.label)}
                                    />
                                )}
                                {field?.type?.includes('list$') && (
                                    <ListField
                                        field={field}
                                        disabled={disabled}
                                        value={fieldValue || []}
                                        required={field?.required || false}
                                        onChange={onChange}
                                        placeholder={field?.placeholder || field?.default || ''}
                                    />
                                )}
                                {field?.type === 'color' && (
                                    <ColorPicker
                                        withHex={true}
                                        color={fieldValue}
                                        required={field?.required || false}
                                        onChange={(color) => {
                                            onChange({
                                                target: {
                                                    name: field?.name,
                                                    value: color
                                                }
                                            })
                                        }}
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                    />
                                )}
                                {field?.type === 'theme' && (
                                    <ThemePicker
                                        size="small"
                                        required={field?.required || false}
                                        fullWidth
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        name={field?.name}
                                        value={fieldValue || ''}
                                        onChange={onChange}
                                        label={t(field?.label)}
                                    />
                                )}
                                {field?.type === 'text' && (
                                    <TextField
                                        fullWidth
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        name={field?.name}
                                        placeholder={field?.placeholder || field?.default || ''}
                                        type='text'
                                        required={field?.required || false}
                                        multiline={field?.multiline || false}
                                        maxRows={field?.maxRows ? field?.maxRows : ((field?.multiline || false) ? 8 : 1)}
                                        value={fieldValue || ''}
                                        onChange={onChange}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                        label={t(field?.label)}
                                    />
                                )}
                                {field?.type === 'datetime' && (
                                    <DateTimePicker
                                        label={t(field?.label)}
                                        required={field?.required || false}
                                        format="dd.MM.yyyy HH:mm"
                                        value={fieldValue ? (new Date(fieldValue || null)) : null}
                                        onChange={(newValue) => onChange({
                                            target: {
                                                name: field?.name,
                                                value: newValue
                                            }
                                        })}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                        yearsOrder="desc"
                                        views={['year', 'month', 'day']}
                                        slotProps={{
                                            textField: {fullWidth: true}
                                        }}
                                    />
                                )}
                                {field?.type === 'password' && (
                                    <TextField
                                        fullWidth
                                        placeholder={field?.placeholder || field?.default || ''}
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        name={field?.name}
                                        required={field?.required || false}
                                        type='password'
                                        rows={field?.rows || 1}
                                        value={fieldValue || ''}
                                        onChange={onChange}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                        label={t(field?.label)}
                                    />
                                )}
                                {field?.type === 'email' && (
                                    <TextField
                                        fullWidth
                                        placeholder={field?.placeholder || field?.default || ''}
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        type="email"
                                        required={field?.required || false}
                                        name={field?.name}
                                        value={fieldValue || ''}
                                        onChange={onChange}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                        label={t(field?.label)}
                                    />
                                )}
                                {field?.type === 'number' && (
                                    <NumericFormat
                                        customInput={TextField}
                                        fullWidth
                                        required={field?.required || false}
                                        placeholder={field?.placeholder || field?.default || ''}
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        name={field?.name}
                                        value={fieldValue || ''}
                                        suffix={' ' + t(field?.suffix || '')}
                                        prefix={t(field?.prefix || '')}
                                        onValueChange={(values) => {
                                            onChange({
                                                target: {
                                                    name: field?.name,
                                                    value: values.floatValue || null
                                                }
                                            })
                                        }}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                        min={field?.min || -99999999999}
                                        max={field?.max || 99999999999}
                                        error={fieldVisited && isNumberError}
                                        helperText={fieldVisited && isNumberError && t('mgt.settings.invalid_number', {max: field?.max || 99999999999, min: field?.min || -99999999999})}
                                        label={t(field?.label)}
                                        decimalScale={field?.decimalPlaces || field?.decimals}
                                    />
                                )}
                                {field?.type === 'select' && (
                                    <TextField
                                        select
                                        fullWidth
                                        required={field?.required || false}
                                        name={field?.name}
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        label={t(field?.label)}
                                        value={fieldValue || ''}
                                        onChange={onChange}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                    >
                                        {field?.options?.map(option => (
                                            <MenuItem key={option.name} value={option?.value || option.name}>
                                                {/*{t(option.label)}*/}
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                                {field?.type === 'smart-selector' && (
                                    <SmartSelector
                                        fullWidth
                                        required={field?.required || false}
                                        endpoint={field?.endpoint}
                                        name={field?.name}
                                        query={field?.query || {}}
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        label={t(field?.label)}
                                        values={fieldValue || ''}
                                        multiple={false}
                                        handleChange={onChange}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                    />
                                )}
                                {field?.type === 'integration' && (
                                    <IntegrationSelector
                                        fullWidth
                                        required={field?.required || false}
                                        endpoint={field?.endpoint}
                                        name={field?.name}
                                        query={field?.query || {}}
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        label={t(field?.label)}
                                        integration={field?.integration || null}
                                        connector={field?.connector || null}
                                        values={fieldValue || ''}
                                        multiple={false}
                                        handleChange={onChange}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                    />
                                )}
                                {field?.type === 'slider' && (
                                    <Slider
                                        name={field?.name}
                                        value={fieldValue || ''}
                                        disabled={disabled || (field?.disabled || false) || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        label={t(field?.label)}
                                        required={field?.required || false}
                                        onChange={onChange}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                        min={field?.min}
                                        max={field?.max}
                                        step={0.1}
                                        valueLabelDisplay="auto"
                                        marks={[]}
                                    />
                                )}
                                {field?.type === 'switch' && (
                                    <FormControlLabel
                                        control={<Switch/>}
                                        required={field?.required || false}
                                        disabled={disabled || ((field?.disabled || false)) && (field?.name !== "cmsSystemActive") || (field?.isDisabled ? field.isDisabled(settings) : false)}
                                        checked={fieldValue}
                                        onChange={(event) => {
                                            const checked = event.target.checked;
                                            onChange({
                                                target: {
                                                    name: field?.name,
                                                    value: checked
                                                }
                                            })
                                        }}
                                        onBlur={() => markFieldVisited(field.name)}
                                        onFocus={() => markFieldVisited(field.name)}
                                        label={t(field?.label)}
                                        name={field?.name}
                                        color="primary"
                                    />
                                )}
                            </Grid>
                            {handleFieldChange && !minimal && (
                                <Grid item xs={12} lg={2}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleFieldChange(field)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    )
                })
            }
            {handleFieldChange && (
                <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => {settingsFieldsDialog.handleOpen(null)}}
                >
                    {t('dialogs.settings_items.title')}
                </Button>
            )}
            <SettingsItemDialog
                field={fields?.find(f => f.name === settingsFieldsDialog?.data) || null}
                open={settingsFieldsDialog.open}
                onClose={settingsFieldsDialog.handleClose}
                onRemove={(field) => {
                    handleFieldRemove(field);
                    settingsFieldsDialog.handleClose();
                }}
                onChange={(field) => {
                    settingsFieldsDialog.handleOpen(field?.name || null);
                    handleFieldChange(field);
                }}
            />
        </>
    );
}

SettingsItems.propTypes = {
    settings: PropTypes.object.isRequired,
    handleChange: PropTypes.func,
    setSettings: PropTypes.func,
    fields: PropTypes.array.isRequired,
    handleFieldChange: PropTypes.func,
    handleFieldRemove: PropTypes.func,
    minimal: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
}

ListField.propTypes = {
    field: PropTypes.object.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default SettingsItems;
