import React, {memo, useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import {APP_SETTING} from "../../../setup";
import {useTheme} from "@mui/system";
import PropTypes from "prop-types";
import {useAppMode} from "../../hooks/use-app-mode";
import LoadingScreen from "./loading-screen";

const DEFAULT_FONT_MAPPING = {
    200: '/assets/fonts/Fabrikat/Thin.otf',
    300: '/assets/fonts/Fabrikat/Light.otf',
    400: '/assets/fonts/Fabrikat/Regular.otf',
    500: '/assets/fonts/Fabrikat/Regular.otf',
    600: '/assets/fonts/Fabrikat/Medium.otf',
    700: '/assets/fonts/Fabrikat/Bold.otf',
}

const loadFont = (fontName, fontUrl, fontWeight = 'normal', fontStyle = 'normal') => {
    return new Promise((resolve, reject) => {
        const font = new FontFace(fontName, `url(${fontUrl})`, {
            weight: fontWeight,
            style: fontStyle,
        });

        // Wait for the font to be loaded
        font.load().then(() => {
            document.fonts.add(font);
        }).catch((error) => {
            console.error(`AIOS could not load font ${fontName}:`, error);
        }).finally(() => {
            setTimeout(() => {
                resolve();
            }, 250);
        })
    });
};

const ThemeProcessor = memo(({theme, disableLoader = false}) => {
    const faviconFallback = APP_SETTING.protocol + "://" + APP_SETTING.domain + "/api/setup/favicon/";
    const iconFallback = APP_SETTING.protocol + "://" + APP_SETTING.domain + "/api/setup/icon/";
    const appMode = useAppMode();
    const [backgroundColor, setBackgroundColor] = useState(null);

    useEffect(() => {
        const config = theme?.config;

        if (config?.id) {
            if(!disableLoader)
                appMode.addLoadingElement('fonts')
            const fontPromises = [];

            // Iterate over an array with the numbers 200, 300 to 700
            let font;
            for (let weight = 200; weight <= 700; weight += 100) {
                font = config?.[`system_font_${weight}`] || null;
                if (font) {
                    fontPromises.push(loadFont(`Font-${config?.id}`, font.view, weight));
                } else {
                    fontPromises.push(loadFont(`Font-${config?.id}`, DEFAULT_FONT_MAPPING[weight], weight));
                }
            }

            Promise.all(fontPromises).finally(() => {
                if(!disableLoader)
                    appMode.removeLoadingElement('fonts')
            })
        } else {
            if(!disableLoader)
                appMode.removeLoadingElement('fonts')
        }

        const timer = setTimeout(() => {
            setBackgroundColor(theme.palette.background.default);
        }, 500);

        return () => {
            clearTimeout(timer);
        }

    }, [theme?.config?.id]);

    return (
        <>
            <style>
                {`
                    ::selection {
                        background-color: ${theme.palette.secondary.main};
                        color: ${theme.palette.secondary.contrastText};
                    }
                    ::-moz-selection {
                        background-color: ${theme.palette.secondary.main};
                        color: ${theme.palette.secondary.contrastText};
                    }
                `}
                {backgroundColor && `
                    body {
                        background-color: ${theme.palette.background.default};
                    }                
                `}
            </style>
            <Helmet>
                <meta
                    name="theme-color"
                    content={theme.palette.primary.default}
                />
                <meta
                    name="color-scheme"
                    content={theme.palette?.mode}
                />
                <link
                    rel="icon"
                    href={theme?.config?.favicon?.view || faviconFallback}
                />
                <link
                    rel="apple-touch-icon"
                    sizes="512x512"
                    href={theme?.config?.icon?.view || iconFallback}
                />
            </Helmet>
        </>
    );
}, (prevProps, nextProps) => {
    return prevProps.theme === nextProps.theme;
});

function FontMetaProvider({children, disableLoader = false}){
    const theme = useTheme();

    return (
        <>
            <ThemeProcessor disableLoader={disableLoader} theme={theme} />
            {children}
        </>
    )

}

ThemeProcessor.propTypes = {
    theme: PropTypes.object
}

FontMetaProvider.propTypes = {
    children: PropTypes.node
}

export default FontMetaProvider;