import React from "react";
import {Checkbox, FormControlLabel, ListItemText, Stack, Switch, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import PropTypes from "prop-types";
import {Box} from "@mui/system";

function ShareWithGroupStack({formik, variant = 'switch'}){
    const { t } = useTranslation();
    const user = useSelector(state => state.account.user);

    if(formik.values?.id)
        return null;

    if(!user?.current_group)
        return null;

    if(variant === 'checkbox'){
        return (
            <FormControlLabel
                control={(
                    <Checkbox
                        onChange={(event, val) => {
                            formik.setFieldValue('share_with_group', val);
                        }}
                        checked={formik.values.share_with_group}
                        name='share_with_group'
                        value={formik.values.share_with_group}
                    />
                )}
                label={<Box>
                    <ListItemText
                        primary={t('common.share_with_group', {group: user?.current_group?.name || ''})}
                        primaryTypographyProps={{
                            noWrap: true,
                            variant: 'subtitle1',
                            color: 'textPrimary'
                        }}
                    />
                </Box>}
                labelPlacement="end"
            />
        )
    }

    return (
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1">{t('common.share_with_group', {group: user?.current_group?.name || ''})}</Typography>
            <Switch
                name="share_with_group"
                checked={formik.values.share_with_group}
                onChange={(event, value) => formik.setFieldValue("share_with_group", value)}
            />
        </Stack>
    )
}

ShareWithGroupStack.propTypes = {
    formik: PropTypes.object.isRequired,
    variant: PropTypes.oneOf(['switch', 'checkbox'])
};

export default ShareWithGroupStack;