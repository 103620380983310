import React from "react";
import PropTypes from "prop-types";
import OnIcon from "./icon";
import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";

function NoResults({ primary = null, secondary = null, icon = null, sx = {}, iconSize = 'large', ...rest }){
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{...{textAlign: 'center'}, ...sx}}
            {...rest}
        >
            <OnIcon
                iconName={icon || "SearchRefraction"}
                size={iconSize}
                sx={{color: theme.palette.text.secondary}}
            />
            <Typography variant="h5" color="textSecondary">
                {primary || t("common.sst_no_results")}
            </Typography>
            {secondary && (
                <Typography variant="subtitle1" color="textSecondary">
                    {secondary}
                </Typography>
            )}
        </Stack>
    )
}

NoResults.propTypes = {
    primary: PropTypes.string,
    secondary: PropTypes.string,
    icon: PropTypes.string,
    iconSize: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
}

export default NoResults;