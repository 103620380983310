import PropTypes from 'prop-types';
import {Avatar, Box, CircularProgress, Divider, IconButton, Link, Stack, SvgIcon, Typography} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../../general/logo";
import React, {useEffect, useState} from "react";
import {useSettings} from "src/omnia/hooks/use-settings";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import usePopover from "../../../../hooks/use-popover";
import {TenantPopover} from "./tenant-popover";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import OnIcon from "../../../elements/icon";
import {setCurrentGroup, setIsTransitioning} from "../../../../store/actions/account-actions";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {useAppMode} from "../../../../hooks/use-app-mode";

export const TenantSwitch = ({sx = {}, ...props}) => {

    const { t } = useTranslation();
    const appMode = useAppMode();

    return (
        <Stack direction="row" spacing={2} sx={{ width: '100%' }} alignItems="center">
            <Avatar sx={{borderRadius: '5px', width: 30, height: 30 }}>
                <OnIcon iconName={appMode.currentApp?.icon} size="small" />
            </Avatar>
            <Typography
                noWrap
                color="inherit"
                variant="h6"
            >
                {t(appMode.currentApp?.title)}
            </Typography>
        </Stack>
    )

};

TenantSwitch.propTypes = {
    sx: PropTypes.object
};
