import React from "react";
import MessagesIcon from '@untitled-ui/icons-react/build/esm/MessageChatSquare';
import {Badge, IconButton, SvgIcon, Tooltip} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {ChatPopover} from './chat-popover';
import {useSelector} from "react-redux";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useTranslation} from "react-i18next";

export const ChatButton = () => {

    const popover = usePopover();
    const { isFlying } = useIsMobile();
    const { t } = useTranslation();
    const { hasRights, isModuleInstalled } = useSecurityCheck();
    const unread = useSelector(state => state.messages.unread);

    const handleOpenChats = () => {
        popover.handleOpen();
    }

    if(!hasRights(['social_intranet']) || !isModuleInstalled('intranet'))
        return null;

    return (
        <>
            <Tooltip enterDelay={1000} title={t("common.tooltips.show_chats")} placement={isFlying ? 'left' : 'bottom'}>
                <IconButton
                    onClick={handleOpenChats}
                    ref={popover.anchorRef}
                >
                    <Badge
                        color="error"
                        badgeContent={unread > 0 ? unread : 0}
                    >
                        <SvgIcon>
                            <MessagesIcon/>
                        </SvgIcon>
                    </Badge>
                </IconButton>
            </Tooltip>
            <ChatPopover popover={popover} />
        </>
    );
};
