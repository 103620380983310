import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import usePagination from "../../../../hooks/use-pagination";
import {Box, CircularProgress, List, Stack, Typography, MenuItem, Fade, Button} from "@mui/material";
import OnIcon from "../../../elements/icon";
import SmartPopper from "../../../elements/smart-popper";
import LlmAssistantsList from "./llm-assistants-list";
import {usePopover} from "../../../../hooks/use-popover";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import AssistantLogo from "../assistants/assistant-logo";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {setCurrentAssistant} from "../../../../store/actions/account-actions";
import {APP_SETTING} from "../../../../../setup";
import AssistantImageTitle from "./assistant-image-title";

const LlmAssistantSelector = (props) => {

    const {
        value = null,
        defaultValue = null,
        onSelect = () => {},
        disableUnavailable = false
    } = props;

    const theme = useTheme();
    const {t} = useTranslation();
    const [selected, setSelected] = useState(null);
    const dispatch = useDispatch();
    const {isMobile} = useIsMobile();
    const user = useSelector(state => state.account.user);
    const {hasRights} = useSecurityCheck();
    const popover = usePopover();
    const { post } = useOmniaApi();
    const navigate = useNavigate();
    const assistantsData = usePagination({
        endpoint: 'core/assistants',
        page: 1,
        query: {ordering: 'name'},
        pageSize: 9999,
    });

    const handleAddAssistant = () => {
        navigate('/groon/home/assistants/add');
    }

    const handleMenuItemClick = (assistant) => {
        onSelect(assistant);
        setSelected(assistant);
        popover.handleClose();
        dispatch(setCurrentAssistant(assistant?.id));
        post('core/me/set_current_assistant', {id: assistant?.id});
    };

    const handleCreate = () => {
        navigate('/groon/home/assistants/add');
    }

    useEffect(() => {
        if (!assistantsData.loading) {
            let updatedSelected = null;

            // Choose the assistant of interest
            if (value || defaultValue || user?.current_assistant) {
                const query = assistantsData.elements.filter(a => a.id === parseInt(value || defaultValue || user?.current_assistant));
                if (query.length > 0) {
                    updatedSelected = query[0];
                }
            }

            // First, check to have an "available" assistant selected
            if (!updatedSelected) {
                const onlineModels = assistantsData.elements.filter(a => a?.llm?.is_available)
                if (onlineModels.length > 0) {
                    updatedSelected = onlineModels[0];
                }
            }

            // Ensure that we always have an assistant selected
            if(!updatedSelected && assistantsData.elements.length > 0) {
                updatedSelected = assistantsData.elements[0];
            }

            setSelected(updatedSelected);
            onSelect(updatedSelected);
        }
    }, [assistantsData.loading, value]);

    return (
        <>
            <Fade in={!assistantsData.loading} timeout={APP_SETTING?.transitionDuration || 500}>
                <Box>
                    {selected ? (
                        <AssistantImageTitle
                            assistant={selected}
                            onClick={popover.handleOpen}
                            ref={popover.anchorRef}
                            onMouseEnter={() => {
                                popover.handleOpen();
                                popover.queueOpen();
                            }}
                            onMouseLeave={() => {
                                if (!isMobile) {
                                    popover.queueClose();
                                }
                            }}
                            sx={{color: theme.palette.text.primary, cursor: 'pointer'}}
                            disabled={assistantsData.loading}
                        />
                    ) : hasRights('manage_assistants') ? (
                        <Button onClick={handleCreate}>
                            {t('intelligence.assistants.new_assistant')}
                        </Button>
                    ) : null}
                </Box>
            </Fade>

            <SmartPopper
                popover={popover}
                placement="bottom-start"
            >
                <Box sx={{minWidth: 230}}>
                    <List
                        sx={{maxHeight: 400, overflowY: 'auto'}}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        mt={2}
                    >
                        <LlmAssistantsList
                            isLoading={assistantsData.loading}
                            onSelect={handleMenuItemClick}
                            dense={true}
                            disableUnavailable={disableUnavailable}
                            selectedId={selected?.id}
                            assistants={assistantsData.elements?.filter(a => !a.is_model_wrapper)}
                        />
                        {hasRights("manage_assistants") && (
                            <MenuItem
                                fullWidth
                                sx={{minHeight: isMobile ? 22 : 'auto', py: 0.5, mt: 2}}
                                onClick={handleAddAssistant}
                            >
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <OnIcon iconName="Plus" size="small"/>
                                    <Typography variant="subtitle1">
                                        {t('common.create_new')}
                                    </Typography>
                                </Stack>
                            </MenuItem>
                        )}
                    </List>
                </Box>
            </SmartPopper>
        </>
    );

}

LlmAssistantSelector.propTypes = {
    value: PropTypes.number,
    defaultValue: PropTypes.number,
    onSelect: PropTypes.func,
    disableUnavailable: PropTypes.bool
}

export default LlmAssistantSelector;