import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {IconButton, Slide, Stack, SvgIcon} from '@mui/material';
import { AccountButton } from '../account-button';
import { NotificationsButton } from '../notifications-button';
import { ChatButton } from "../chat-button";
import BlurredBox from "../../../elements/blurred-box";
import { useSecurityCheck } from "../../../../hooks/use-security-check";
import {Box, useTheme} from "@mui/system";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {AssistantButton} from "../assistant-button";
import {useSelector} from "react-redux";
import {useSettings} from "../../../../hooks/use-settings";
import {CreatedMediaButton} from "../created-media-button";
import OnIcon from "../../../elements/icon";
import {AppsButton} from "../apps-button";

export const TopNav = (props) => {
    const theme = useTheme();
    const { isModuleInstalled, hasRights } = useSecurityCheck();
    const { onMobileNavOpen, mobileNavActive, styles, ...other } = props;
    const { isMobile, navBarMode } = useIsMobile();
    const [ showTopNav, setShowTopNav ] = useState(false);
    const chatOpen = useSelector((state) => state.messages.assistantOpen);
    const [verticalPosition, setVerticalPosition] = useState(chatOpen);
    const [ position, setPosition ] = useState(navBarMode);
    const settings = useSettings();

    useEffect(() => {
        setShowTopNav(false);
        const timer = setTimeout(() => {
            setShowTopNav(true);
            setPosition(navBarMode);
            setVerticalPosition(chatOpen);
        }, 1000);
        return () => clearTimeout(timer);
    }, [navBarMode, chatOpen]);

    const content = (
        <Slide direction={position === 'flying' ? "left" : "down"} in={showTopNav} mountOnEnter unmountOnExit>
            <Stack
                alignItems="center"
                direction={(position === 'flying') ? "column" : "row"}
                justifyContent="space-between"
                spacing={2}
                sx={{
                    borderRadius: isMobile ? 0 : '30px',
                    borderColor: styles?.[settings.navColor]?.borderColor || theme.palette.divider,
                    borderWidth: 1,
                    color: styles?.[settings.navColor]?.color || theme.palette.text.primary,
                    borderStyle: 'solid',
                    backgroundColor: styles?.[settings.navColor]?.backgroundColor || theme.palette.background.default,
                    boxShadow: theme.palette.mode === 'light' ? '0px 0px 40px rgba(0, 0, 0, 0.1)' : '0px 0px 40px rgba(255, 255, 255, 0.1)',
                    p: 0.5
                }}
            >
                {['mobile', 'topbar'].includes(position) && (
                    <Stack alignItems="center" direction="row" spacing={2}>
                        {mobileNavActive && (
                            <IconButton onClick={onMobileNavOpen}>
                                <OnIcon iconName="Menu03" size="small" />
                            </IconButton>
                        )}
                    </Stack>
                )}
                <Stack alignItems="center" direction={(position === 'flying') ? "column" : "row-reverse"} spacing={1}>
                    <AccountButton />
                    <AppsButton/>
                    <NotificationsButton />
                    {isModuleInstalled('intranet') && <ChatButton />}
                    <AssistantButton styles={styles} />
                </Stack>
            </Stack>
        </Slide>
    )


    if(position === 'flying'){
        return (
            <Box
                sx={{
                    position: 'fixed',
                    borderRadius: '30px',
                    right: '16px',
                    top: verticalPosition ? 'calc(50% - 200px)' : '50%',
                    transform: 'translateY(-50%)',
                    backdropFilter: 'blur(6px)',
                    zIndex: (theme) => theme.zIndex.appBar,
                }}

                {...other}
            >
                {content}
            </Box>
        )
    } else if (position === 'flying-topbar'){
        return (
            <Box
                sx={{
                    position: 'fixed',
                    borderRadius: '30px',
                    right: '14px',
                    top: '14px',
                    backdropFilter: 'blur(6px)',
                    zIndex: (theme) => theme.zIndex.appBar,
                }}

                {...other}
            >
                {content}
            </Box>
        )
    } else {
        return (
            <BlurredBox
                opacity={0}
                sx={{
                    position: 'fixed',
                    width: '100%',
                    borderRadius: isMobile ? 0 : '30px',
                    top: isMobile ? 0 : '14px', // Adds spacing from the top
                    zIndex: (theme) => theme.zIndex.appBar,
                    margin: '0 auto', // Centers the BlurredBox
                    px: isMobile ? 0 : 2,
                }}

                {...other}
            >
                {content}
            </BlurredBox>
        )

    }

};

TopNav.propTypes = {
    onMobileNavOpen: PropTypes.func,
    mobileNavActive: PropTypes.bool,
};
