import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Button,
    Dialog,
    DialogContent,
    Grid,
    Stack,
    TextField,
    Typography,
    MenuItem,
    DialogActions
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import SaveButton from "../../../elements/save-button";
import useIntelligenceFormik from "../../../../hooks/formik/use-intelligence-formik";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import { useNotifications } from "../../../../hooks/use-notifications";
import { useTranslation } from "react-i18next";
import ArchitectureRenderer from "./architecture-renderer";
import SelectionTiles from "../../../elements/selection-tiles";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import SelectExternalModel from "../model-selector/select-external-model";

function CreateModelDialog({ open, onClose, onAdded = null }) {
    const { t } = useTranslation();
    const { post } = useOmniaApi();
    const { isMobile } = useIsMobile();
    const { notifySuccess } = useNotifications();
    const [ selected, setSelected ] = useState([]);
    const [ creating, setCreating ] = useState(false);
    const [ step, setStep ] = useState('choose');

    const [layers, setLayers] = useState([
        { type: 'input', size: 3, activation: 'relu', position: 0 },
        { type: 'output', size: 1, activation: 'sigmoid', position: 1 }
    ]);

    const handleClose = () => {
        setSelected([]);
        setStep('choose');
        setCreating(false);
        onClose();
    }

    const sortLayers = (layerArray) => {
        return layerArray.sort((a, b) => {
            if (a.type === 'input') return -1;
            if (b.type === 'output') return -1;
            if (a.type === 'output') return 1;
            return 0;
        });
    };

    const handleCreateModels = () => {
        setCreating(true);
        post('ai/ai_models/create_from_integration', selected).then((newModels) => {
            notifySuccess(t);
            handleClose();
            onAdded?.(newModels);
        }).finally(() => {
            setCreating(false);
        })
    }

    const updateLayerPositions = (newLayers) => {
        const sortedLayers = sortLayers(newLayers);
        return sortedLayers.map((layer, index) => ({ ...layer, position: index }));
    };

    const addLayer = () => {
        setLayers(prev => {
            const newLayers = [...prev, { type: 'hidden', size: 12, activation: '', position: prev.length }];
            return updateLayerPositions(newLayers);
        });
    };

    const updateLayer = (index, newData) => {
        const updatedLayers = layers.map((layer, i) => (i === index ? { ...layer, ...newData } : layer));
        setLayers(updateLayerPositions(updatedLayers));
    };

    const removeLayer = (index) => {
        if (layers.length > 2) {  // Prevent removing input and output layers
            const newLayers = layers.filter((_, i) => i !== index);
            setLayers(updateLayerPositions(newLayers));
        }
    };

    const onSubmit = (values, helpers) => {
        const modelArchitecture = layers.map((layer) => ({
            type: layer.type,
            size: layer.size,
            activation: layer.activation,
        }));

        const payload = {
            ...values,
            architecture: modelArchitecture,
        };

        helpers.setSubmitting(true);
        post('ai/ai_models', payload).then(() => {
            notifySuccess(t);
            handleClose();
        }).finally(() => {
            helpers.setSubmitting(false);
        });
    };

    const { aiModelFormik } = useIntelligenceFormik(null, onSubmit);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="md"
            fullScreen={isMobile}
        >
            <DialogTitle>
                <Typography variant="h4">
                    {t('dialogs.new_ai_model.title')}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {t('dialogs.new_ai_model.description')}
                </Typography>
            </DialogTitle>
            <DialogContent style={{padding: 0}}>
                {step === 'choose' && (
                    <SelectionTiles
                        sx={{px: 2}}
                        options={[
                            {label: t("intelligence.models.custom_model"), value: "custom", description: t('intelligence.models.custom_model_info'), icon: 'Container'},
                            {label: t("intelligence.models.from_integration"), value: "integration", description: t('intelligence.models.from_integration_info'), icon: 'Download02'},
                            {label: t("intelligence.models.upload_pytorch"), value: "pytorch", description: t('intelligence.models.upload_pytorch_info'), icon: 'Upload02', disabled: true},
                        ]}
                        onSelect={(option) => setStep(option.value)}
                    />
                )}
                {step === 'custom' && (
                    <Grid container spacing={2} sx={{ mt: 1, px: 2 }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('attributes.name')}
                                {...aiModelFormik.getFieldProps('name')}
                            />
                        </Grid>
                        {layers.map((layer, index) => (
                            <Grid item xs={12} key={index}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                label={t('intelligence.models.' + layer.type + '_layer')}
                                                value={layer.size}
                                                onChange={(e) => updateLayer(index, { size: e.target.value })}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                select
                                                label={t('intelligence.models.activation_function')}
                                                value={layer.activation}
                                                onChange={(e) => updateLayer(index, { activation: e.target.value })}
                                                fullWidth
                                            >
                                                {['', 'sigmoid', 'relu', 'tanh'].map((activation) => (
                                                    <MenuItem key={activation} value={activation}>
                                                        {activation || 'None'}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Button
                                        color="primary"
                                        disabled={!(layer.type !== 'input' && layer.type !== 'output')}
                                        sx={{ minWidth: 100 }}
                                        onClick={() => removeLayer(index)}
                                    >
                                        {t('common.remove')}
                                    </Button>
                                </Stack>
                            </Grid>
                        ))}
                        <Grid item xs={12}>
                            <ArchitectureRenderer layers={layers} />
                        </Grid>
                    </Grid>
                )}
                {step === 'integration' && (
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <SelectExternalModel onSelectionChange={selected => setSelected(selected)} />
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 4, width: '100%' }}>
                    {step === 'choose' ? (
                        <Button onClick={handleClose} variant="outlined">{t('common.cancel')}</Button>
                    ) : (
                        <Button onClick={() => setStep('choose')} variant="outlined">{t('common.back')}</Button>
                    )}
                    {step === 'custom' && (
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <Button variant="outliend" onClick={addLayer}>
                                {t('intelligence.models.add_layer')}
                            </Button>
                            <SaveButton
                                formik={aiModelFormik}

                                // onClick={aiModelFormik.handleSubmit}
                                // loading={aiModelFormik.isSubmitting}
                                // noIcon
                                // label={t('common.create')}
                            />
                        </Stack>
                    )}
                    {step === 'integration' && (
                        <SaveButton
                            onClick={handleCreateModels}
                            loading={creating}
                            label={t('common.add')}
                        />
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

CreateModelDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAdded: PropTypes.func,
};

export default CreateModelDialog;
