import { useEffect, useState } from "react";

// Helper function to shuffle an array (Fisher-Yates shuffle)
function shuffleArray(array) {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
}

function useGenerateVisibility(count, delay = 25, randomOrder = false) {
    const [visible, setVisible] = useState(new Array(count || 0).fill(false));

    useEffect(() => {
        // Create an array of indices [0, 1, 2, ..., count - 1]
        const indices = Array.from({ length: count }, (_, i) => i);
        // If randomOrder is true, shuffle the indices
        const order = randomOrder ? shuffleArray(indices) : indices;

        // For each index in the (possibly shuffled) order, schedule a timeout
        const timeouts = order.map((index, i) =>
            setTimeout(() => {
                setVisible(prevVisible => {
                    const newVisible = [...prevVisible];
                    newVisible[index] = true;
                    return newVisible;
                });
            }, delay * i)
        );

        // Cleanup any timeouts when the component unmounts or dependencies change
        return () => timeouts.forEach(clearTimeout);
    }, [count, delay, randomOrder]);

    return { visible };
}

export default useGenerateVisibility;
