// Global Omnia Application Settings

export const APP_SETTING = {

  /**
   * Domain Setup
   *
   * Specify the core domain and protocol of the application
   */
  domain: "demo.groon.io", protocol: "https", socket_protocol: "wss", // domain: "localhost:8000",
  // protocol: "http",
  // socket_protocol: "ws",

  /**
   * Sidebar
   *
   * Specify the sidebar items that shall be in the menu of this instance
   * Example:
   *      {
   *          title: 'Some Tool',
   *          path: '/custom-module/some-tool',
   *          icon: <FittingUntitledIcon />,
   *          items: [ ...children of the same type],
   *      },
   */
  // navigation: {},

  /**
   * Widgets
   *
   * Specify the widgets that shall be available in the dashboards of this instance
   * Example:
   *      {
   *          name: "Custom Widget",
   *          description: "This is a custom widget that does something",
   *          identifier: "UNIQUE-IDENTIFIER",
   *          module: "Custom Module",
   *          component: ReactComponentToUse
   *      },
   */
  widgets: []

};
