import React from "react";
import {Stack} from "@mui/material";
import AttachedElement from "./attached-element";
import PropTypes from "prop-types"

function AttachedElements({objects = [], ...rest}){

    return (
        <Stack direction="column" sx={{width: '100%'}}>
            {objects?.map(object => <AttachedElement object={object} key={object.id} {...rest} />)}
        </Stack>
    )
}

AttachedElements.propTypes = {
    objects: PropTypes.array
}

export default AttachedElements;