export default function formatArrayToString(array, maxElements = 3, finalConjunction = "und", moreWord = "weitere", capitalizeFirstLetter = false) {

    // Function to capitalize the first letter of a string
    const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

    // Optionally capitalize the first letter of each string in the array
    const processedArray = capitalizeFirstLetter ? array.map(capitalize) : array;

    // If the array length is within the maxElements, join with commas and replace the last comma with the final conjunction
    if (processedArray.length <= maxElements) {
        return processedArray.join(', ').replace(/, (?=[^,]*$)/, ` ${finalConjunction} `);
    }

    // If the array length exceeds maxElements, format it according to the specifications
    const initialElements = processedArray.slice(0, maxElements).join(', ');
    const remainingCount = processedArray.length - maxElements;

    return `${initialElements} ${finalConjunction} ${remainingCount} ${moreWord}`;
}
