import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Typography, Box, Stack, Paper, Tooltip} from '@mui/material';
import {useSelector} from 'react-redux';
import useApi from "src/omnia/hooks/use-api";
import HtmlWrapper from "../../../../../elements/html-wrapper";
import OnIcon from "../../../../../elements/icon";
import {useTranslation} from "react-i18next";

function Comment({comment, onDelete, ...rest}) {
    const user = useSelector(state => state.account.user);
    const {del} = useApi();
    const member = useSelector(state => state.users.users.find(u => u.id === comment.memberId));
    const {t} = useTranslation();
    const handleRemove = () => {
        if (window.confirm("Möchtest du den Kommentar wirklich löschen?")) {
            del('pm/project-board-list-card-comments', comment.id);
            onDelete?.(comment.id);
        }
    }

    if (!member)
        return null;

    if (comment.is_system)
        return (
            <Stack
                direction="column"
                spacing={0}
                justifyContent="flex-left"
                sx={{width: '100%'}}
                {...rest}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-left"
                    alignItems="center"
                    spacing={1}
                    sx={{width: '100%'}}
                >
                    <Typography
                        variant="h5"
                        color="textPrimary"
                        style={{fontSize: 14}}
                    >
                        {member.name}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textPrimary"
                        gutterBottom
                    >
                        {comment.message}
                    </Typography>
                </Stack>
                <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{display: 'flex', alignItems: 'center', gap: 1}}
                >
                    {moment(comment.created_at).fromNow()}
                    <Tooltip
                        componentsProps={{
                            tooltip: {
                                sx: {
                                    fontSize: '8px'
                                }
                            }
                        }}
                        placement="right"
                        title={`${t("attributes.created")} : ${moment(comment.created_at).format('MMMM D, YYYY HH:mm')}`}
                    >
                        <OnIcon iconName="AlertCircle" size="tiny"/>
                    </Tooltip>
                </Typography>
            </Stack>
        )

    return (
        <Stack
            direction="row"
            spacing={2}
            {...rest}
        >
            <Stack
                direction="column"
                spacing={0}
                justifyContent="flex-left"
                sx={{width: '100%'}}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-left"
                    alignItems="center"
                    spacing={1}
                    sx={{height: 40, width: '100%'}}
                >
                    <Typography
                        variant="h5"
                        color="textPrimary"
                        style={{fontSize: 14}}
                    >
                        {member.name}
                    </Typography>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                    >
                        {moment(comment.created_at).fromNow()}
                    </Typography>
                </Stack>
                <Paper
                    component={Box}
                    p={1.5}
                    bgcolor="backgroundDark"
                    variant="outlined"
                >
                    <HtmlWrapper html={comment.message}/>
                </Paper>
                <Box sx={{ml: 1}}>
                    {user.id === member.id && (
                        <Typography variant="caption" color="textSecondary" sx={{cursor: 'pointer'}}
                                    onClick={handleRemove}>
                            Löschen
                        </Typography>
                    )}
                </Box>
            </Stack>
        </Stack>
    );
}

Comment.propTypes = {
    comment: PropTypes.object.isRequired,
    className: PropTypes.string,
    onDelete: PropTypes.func
};

export default Comment;
