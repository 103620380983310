import React, { useState } from 'react';
import { TextField, Button, Grid, MenuItem, IconButton, Stack, FormControlLabel, Switch } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from "react-i18next";

const AdditionalFieldsComponent = ({ formik, name, addLabel, ...rest }) => {
    const [fields, setFields] = useState(formik.values?.[name] || []);
    const { t } = useTranslation();

    const handleAddField = () => {
        const newField = { id: uuidv4(), label: '', name: '', type: 'text', isRequired: false };
        const updatedFields = [...fields, newField];
        setFields(updatedFields);
        formik.setFieldValue(name, updatedFields);
    };

    const handleRemoveField = (id) => {
        const updatedFields = fields.filter(field => field.id !== id);
        setFields(updatedFields);
        formik.setFieldValue(name, updatedFields);
    };

    const generateNameKey = (label) => {
        return label.toLowerCase().replace(/\s+/g, '_');
    };

    const handleChange = (id, key, value) => {
        const updatedFields = fields.map(field => {
            if (field.id === id) {
                if (key === 'label') {
                    return { ...field, label: value, name: generateNameKey(value) };
                }
                return { ...field, [key]: value };
            }
            return field;
        });
        setFields(updatedFields);
        formik.setFieldValue(name, updatedFields);
    };

    return (
        <Grid container spacing={2} {...rest}>
            {fields.map((field, index) => (
                <Grid item xs={12} key={field.id}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{ width: '100%' }}
                        justifyContent="space-between"
                    >
                        <TextField
                            fullWidth
                            label={t('Label')}
                            value={field.label}
                            onChange={e => handleChange(field.id, 'label', e.target.value)}
                        />
                        <TextField
                            fullWidth
                            select
                            label={t('Type')}
                            value={field.type}
                            onChange={e => handleChange(field.id, 'type', e.target.value)}
                        >
                            <MenuItem value="text">{t('Text')}</MenuItem>
                            <MenuItem value="number">{t('Number')}</MenuItem>
                            <MenuItem value="slider">{t('Slider')}</MenuItem>
                        </TextField>
                        {field.type === 'number' || field.type === 'slider' ? (
                            <>
                                <TextField
                                    fullWidth
                                    label={t('Min')}
                                    type="number"
                                    value={field.min}
                                    onChange={e => handleChange(field.id, 'min', e.target.value)}
                                />
                                <TextField
                                    fullWidth
                                    label={t('Max')}
                                    type="number"
                                    value={field.max}
                                    onChange={e => handleChange(field.id, 'max', e.target.value)}
                                />
                            </>
                        ) : null}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={field.isRequired}
                                    onChange={e => handleChange(field.id, 'isRequired', e.target.checked)}
                                />
                            }
                            label={t('Required')}
                        />
                        <IconButton sx={{ width: 60 }} onClick={() => handleRemoveField(field.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </Grid>
            ))}
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    onClick={handleAddField}
                >
                    {addLabel}
                </Button>
            </Grid>
        </Grid>
    );
};

export default AdditionalFieldsComponent;
