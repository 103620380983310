import React, {useState} from "react";
import SmartSelector from "../../elements/smart-selector";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import AiModelImage from "./ai-model-image";
import {IconButton, Stack, Tooltip} from "@mui/material";
import OnIcon from "../../elements/icon";
import {useNavigate} from "react-router-dom";

function AiModelSelector(props) {

    const {
        formik,
        field = 'ai_model',
        setModel,
        label = null,
        multiple = false,
        query = {},
        fullWidth = true,
        ...rest
    } = props;

    const {t} = useTranslation();
    const [ selected, setSelected ] = useState();
    const navigate = useNavigate();

    const handleEdit = () => {
        if(selected)
            navigate('/groon/ai-studio/models/' + selected?.id + '#inferences');
    }

    return (
        <Stack direction="row" alignItems="center" sx={fullWidth ? {width: '100%'} : {}} spacing={2}>
            <SmartSelector
                endpoint="ai/ai_models"
                name={field}
                query={query}
                label={label || t('intelligence.models.model')}
                secondaryLabelField='connector.name'
                values={formik ? (formik?.values?.[field] || null) : selected?.id || null}
                multiple={multiple}
                sx={{width: 250}}
                withLabel={false}
                renderAvatar={(option) => option ? (
                    <AiModelImage aiModel={option} sizePixels={20} />
                ) : (
                    <OnIcon iconName="Stars06" />
                )}
                onSelectionLoad={selection => {
                    if(selection){
                        setModel?.(selection);
                    }
                    setSelected(selection);
                }}
                handleChange={(event) => {
                    if (formik)
                        formik?.handleChange(event);
                    setModel?.(event?.target?.object);
                    setSelected(event?.target?.object);
                }}
                fullWidth={fullWidth}
                // sx={{width: 250}}
                {...rest}
            />
            <Tooltip title={t('intelligence.models.configure_model')}>
                <IconButton disabled={!selected} onClick={handleEdit}>
                    <OnIcon iconName="Sliders04" />
                </IconButton>
            </Tooltip>
        </Stack>
    )
}

AiModelSelector.propTypes = {
    formik: PropTypes.object,
    field: PropTypes.string,
    setModel: PropTypes.func,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    fullWidth: PropTypes.bool,
    query: PropTypes.object,
}

export default AiModelSelector;