import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import {Switch, Tooltip, Link} from "@mui/material";
import ServerSideDataTable from "../../../elements/tables/server-side-data-table";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

const ActivationSwitch = ({id, state, onChange}) => {

    const { t } = useTranslation();
    const { post } = useOmniaApi();
    const [checked, setChecked] = useState(state);
    const [loading, setLoading] = useState(null);

    const handleToggle = () => {
        setLoading(true);
        setChecked(prev => !prev);
        post(`sm/platform-ad-accounts/${id}/toggle`).finally(() => {
            setLoading(false);
            onChange?.();
        });
    };

    return (
        <Tooltip placement="right-end" title={t('marketing.ad_accounts.activate_this_account')}>
            <Switch
                checked={checked}
                disabled={loading}
                onChange={() => handleToggle(id)}
            />
        </Tooltip>
    )
}

function PlatformAdAccountsTable({connectorId, ...props}) {
    const { t } = useTranslation();
    const [inc, setInc] = useState(0);

    const columns = [
        {
            field: 'reference_id',
            headerAlign: 'left',
            align: 'left',
            headerName: t("attributes.ads_account_id"),
            width: 200,
        },
        {
            field: 'name',
            headerAlign: 'left',
            align: 'left',
            headerName: t("attributes.name"),
            width: 200,
            renderCell: (data) => (
                <Link
                    underline="hover"
                    color="textPrimary"
                    href={'/groon/marketing/ad-accounts/' + data.row.id}
                    component={RouterLink}
                    variant="h6"
                >
                    {data.row.name}
                </Link>
            )
        },
        {
            field: 'is_active',
            headerName: t("attributes.is_active"),
            width: 120,
            renderCell: (params) => {
                return <ActivationSwitch id={params.row.id} state={params.row.is_active} onChange={() => setInc(prev => prev + 1)} />;
            }
        }
    ];

    return (
        <ServerSideDataTable
            title={t('marketing.platforms.ad_accounts')}
            defaultState={[
                {
                    field: 'name',
                    pinned: 'left',
                    sorted: 'asc',
                    width: 200,
                },
                {
                    field: 'reference_id',
                    pinned: null,
                    sorted: null,
                    width: 200,
                },
                {
                    field: 'state',
                    pinned: null,
                    sorted: null,
                    width: 200,
                },
                {
                    field: 'is_active',
                    pinned: 'right',
                    sorted: null,
                    width: 75,
                }
            ]}
            defaultQuery={{...{i: inc}, ...(connectorId ? {connector__id: connectorId} : {})}}
            canEdit={false}
            id='platform-ad-accounts'
            endpoint='sm/platform-ad-accounts'
            columns={columns}
            {...props}
        />
    );
}

ActivationSwitch.propTypes = {
    id: PropTypes.number,
    state: PropTypes.bool,
    onChange: PropTypes.func
}

export default PlatformAdAccountsTable;