import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {format} from 'date-fns';
import Attachment01Icon from '@untitled-ui/icons-react/build/esm/Attachment01';
import BookmarkIcon from '@untitled-ui/icons-react/build/esm/Bookmark';
import Star01Icon from '@untitled-ui/icons-react/build/esm/Star01';
import {
    Avatar, AvatarGroup,
    Box,
    Checkbox,
    Chip,
    IconButton, Stack,
    SvgIcon,
    Tooltip,
    Typography
} from '@mui/material';
import {getInitials} from 'src/omnia/utils/get-initials';
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {useDispatch} from "react-redux";
import {updateSelected} from "../../../../store/actions/email-actions";
import EmailStateChip from "./email-state-chip";
import formatArrayToString from "../../../../utils/format-array-to-string";
import OnIcon from "../../../elements/icon";

export const MailItem = (props) => {
    const {
        email,
        onDeselect,
        onSelect,
        onChanged,
        selected = false,
        withActions = true,
        onClick = null,
        ...other
    } = props;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { post } = useOmniaApi();
    const dispatch = useDispatch();

    const handleSelectToggle = useCallback((event) => {
        if (event.target.checked) {
            onSelect?.();
        } else {
            onDeselect?.();
        }
    }, [onSelect, onDeselect]);

    const handleToggleStarred = () => {
        onChanged?.({...email, starred: !email?.starred});
        post('core/emails/' + email?.id + '/toggle_starred').then((data) => {
            onChanged?.(data);
        })
    }

    const handleClick = () => {
        dispatch(updateSelected([]));
        onChanged?.({...email, unread: false});
        post('core/emails/' + email?.id + '/set_unread', {unread: false}).then(data => {
            onChanged?.(data);
        })
        navigate(`/groon/home/emails?emailId=${email.id}&account=${email?.folder}`);
        onClick?.();
    }

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            onClick={handleClick}
            sx={{
                width: '100%',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: 'divider',
                display: 'flex',
                cursor: 'pointer',
                p: 2,
                ...(email?.unread && {
                    position: 'relative',
                    '&:before': {
                        backgroundColor: 'primary.main',
                        content: '" "',
                        height: '100%',
                        left: 0,
                        position: 'absolute',
                        top: 0,
                        width: 4
                    },
                    '& $name, & $subject': {
                        fontWeight: 600
                    }
                }),
                ...(selected && {
                    backgroundColor: 'action.selected',
                }),
                ...(!selected && {
                    '&:hover': {
                        backgroundColor: 'action.hover'
                    }
                })
            }}
            {...other}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}
            >
                {withActions && (
                    <Stack direction="row" spacing={0} alignItems="center" id="email-actions">
                        <Checkbox
                            checked={selected}
                            onClick={e => e.stopPropagation()}
                            onChange={handleSelectToggle}
                        />
                        <Tooltip title={t('core.emails.starred')} placement="right">
                            <IconButton onClick={(e) => { e.stopPropagation(); handleToggleStarred(); }}>
                                <SvgIcon
                                    sx={{
                                        ...(email?.starred && {
                                            color: 'warning.main',
                                            '& path': {
                                                fill: (theme) => theme.palette.warning.main,
                                                fillOpacity: 1
                                            }
                                        })
                                    }}
                                >
                                    <Star01Icon/>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                )}
                <Stack direction="row" spacing={2} alignItems="center" id="email-from">
                    {email?.context ? (
                        <>
                            <Avatar
                                src={null}
                                sx={{
                                    height: 35,
                                    width: 35
                                }}
                            >
                                <OnIcon iconName="AlignHorizontalCentre01" />
                            </Avatar>
                            <Stack sx={{width: 200, ml: 2}}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Typography
                                        color="text.primary"
                                        noWrap
                                        variant="h6"
                                    >
                                        {email?.context?.name}
                                    </Typography>
                                    <Typography
                                        color="text.secondary"
                                        noWrap
                                        variant="overline"
                                    >
                                        {email?.child_count + ' Emails'}
                                    </Typography>
                                </Stack>
                                <Typography
                                    color="text.secondary"
                                    noWrap
                                    variant="body1"
                                >
                                    {email?.subject}
                                </Typography>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <AvatarGroup max={5} spacing="small">
                                {email?.participants?.filter(p => p.role === email?.main_role)?.map((participant, index) => (
                                    <Avatar
                                        key={'avatar-' + index}
                                        src={participant?.avatar?.view}
                                        sx={{
                                            height: 35,
                                            width: 35
                                        }}
                                    >
                                        {getInitials(participant?.visual_name || 'Unbekannt')}
                                    </Avatar>
                                ))}
                            </AvatarGroup>
                            <Stack sx={{width: 200, ml: 2}}>
                                <Typography
                                    color="text.primary"
                                    noWrap
                                    variant="h6"
                                >
                                    {(email?.main_role === 'to' && email?.context) ? email?.context?.name : formatArrayToString(email?.participants?.filter(p => p.role === email?.main_role)?.map(p => p.visual_name), 5)}
                                </Typography>
                                <Typography
                                    color="text.secondary"
                                    noWrap
                                    variant="body1"
                                >
                                    {email?.subject}
                                </Typography>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
            >
                <Typography
                    color="text.secondary"
                    variant="caption"
                    sx={{
                        display: 'block',
                        textAlign: {
                            xs: 'left',
                            md: 'right'
                        },
                        whiteSpace: 'nowrap',
                    }}
                >
                    {email?.state === 'planned' ? moment(email?.time).fromNow() : moment(email?.time).calendar()}
                </Typography>
                <EmailStateChip email={email} />
            </Stack>
        </Stack>
    )

};

MailItem.propTypes = {
    email: PropTypes.object.isRequired,
    onDeselect: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.bool,
    withActions: PropTypes.bool
};
