import React from "react";
import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import FileSelector from "../../../../elements/file-selector";
import useOmniaApi from "../../../../../hooks/use-omnia-api";
import {useNotifications} from "../../../../../hooks/use-notifications";

function SectionMedia({formik}){

    const { t } = useTranslation();
    const { post } = useOmniaApi();
    const { notify, notifySuccess } = useNotifications();

    const removeFile = (name) => {
        if(window.confirm(t('notify.are_you_sure'))){
            post('core/themes/' + formik.values.id + '/remove_media', {media_type: name}).then(() => {
                formik.setFieldValue(name, null);
                notifySuccess(t);
            })
        }
    }

    return (
        <>
            <Grid container spacing={2} sx={{p: 2}}>
                <Grid item xs={12} sx={{mb: 6}}>
                    <Typography
                        variant="h6"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.media')}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.media_instructions')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FileSelector
                        label={t('mgt.settings.main_background')}
                        description={t('mgt.settings.main_background_info')}
                        asBackground={true}
                        withOverlay={true}
                        onRemove={removeFile}
                        value={formik.values.main_background}
                        onChange={formik.handleChange}
                        name="main_background"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FileSelector
                        label={t('mgt.settings.auth_background')}
                        asBackground={true}
                        withOverlay={true}
                        defaultImagePath='/assets/images/gradient-bg.svg'
                        onRemove={removeFile}
                        value={formik.values.auth_background}
                        onChange={formik.handleChange}
                        name="auth_background"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FileSelector
                        label={t('mgt.settings.favicon')}
                        asBackground={false}
                        defaultImagePath='/assets/images/favicon.ico'
                        maxWidth={100}
                        onRemove={removeFile}
                        value={formik.values.favicon}
                        onChange={formik.handleChange}
                        name="favicon"
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FileSelector
                        label={t('mgt.settings.icon')}
                        asBackground={false}
                        maxWidth={100}
                        defaultImagePath='/assets/images/icon.png'
                        onRemove={removeFile}
                        value={formik.values.icon}
                        onChange={formik.handleChange}
                        name="icon"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FileSelector
                        label={t('mgt.settings.error_404')}
                        defaultImagePath='/assets/errors/error-404.png'
                        onRemove={removeFile}
                        value={formik.values.error_404}
                        onChange={formik.handleChange}
                        name="error_404"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FileSelector
                        label={t('mgt.settings.error_403')}
                        defaultImagePath='/assets/errors/error-401.png'
                        onRemove={removeFile}
                        value={formik.values.error_403}
                        onChange={formik.handleChange}
                        name="error_403"
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FileSelector
                        label={t('mgt.settings.error_500')}
                        defaultImagePath='/assets/errors/error-500.png'
                        onRemove={removeFile}
                        value={formik.values.error_500}
                        onChange={formik.handleChange}
                        name="error_500"
                    />
                </Grid>
            </Grid>
        </>
    )
}

SectionMedia.propTypes = {
    formik: PropTypes.object.isRequired
}

export default SectionMedia;