import React, {useEffect} from "react";
import usePopover from "../../hooks/use-popover";
import SearchDialog from "../elements/search-dialog";

function Search(){

    const popover = usePopover();

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if the "Command" key and "K" key are pressed
            if (!event.shiftKey && (event.metaKey || event.ctrlKey) && event.key === 'k') {
                event.preventDefault();
                // Open the search or perform your desired action
                popover.handleOpen();
            }
        };

        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <SearchDialog popover={popover}/>
    )
}

export default Search;