/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { produce } from 'immer';
import {
    GET_USERS,
    REMOVE_USER,
    DETACH_USERS_FROM_GROUP,
    UPDATE_FAVORITE,
    UPDATE_USER,
    UPDATE_COVER,
    UPDATE_AVATAR,
    UPDATE_USER_ACTIVITY,
    ADD_USER,
} from 'src/omnia/store/actions/users-actions';
const initialState = {
    users: [],
    initialized: false
};

const usersReducer = (state = initialState, action) => {

    let tmp = null;

    switch (action.type) {

        case GET_USERS: {
            // Produce new state with new set of users
            return produce(state, (draft) => {
                draft.users = action.payload;
                draft.initialized = true;
            });
        }

        case UPDATE_FAVORITE:
            if(state.users.filter(u => u.id === action.payload.user.id).length === 1){
                tmp = state.users.indexOf(state.users.find(u => u.id === action.payload.user.id));
                if(tmp === -1)
                    return state;
                return produce(state, draft => {
                    draft.users[tmp]['is_favorite'] = action.payload.isFavorite;
                });
            } else {
                return state;
            }

        case UPDATE_AVATAR:
            if(state.users.filter(u => u.id === action.payload.user.id).length === 1) {
                let tmp = state.users.indexOf(state.users.find(u => u.id === action.payload.user.id));
                if(tmp === -1)
                    return null;
                return produce(state, draft => {
                    draft.users[tmp]['avatar'] = action.payload.avatar;
                });
            } else {
                return state;
            }

        case UPDATE_COVER:
            if(state.users.filter(u => u.id === action.payload.user.id).length === 1) {
                let tmp = state.users.indexOf(state.users.find(u => u.id === action.payload.user.id));
                if(tmp === -1)
                    return null;
                return produce(state, draft => {
                    draft.users[tmp]['cover'] = action.payload.cover;
                });
            } else {
                return state;
            }

        case UPDATE_USER_ACTIVITY:
            // eslint-disable-next-line no-case-declarations
            const { id, status, last_active } = action.payload;
            return produce(state, draft => {
                let u = draft.users.find(u => u.id === id);
                u['status'] = status;
                u['last_active'] = last_active;
            })

        case REMOVE_USER: {
            // produce state with all users but the one passed
            return produce(state, (draft) => {
                draft.users = state.users.filter(u => u.id !== action.payload);
            });
        }

        case DETACH_USERS_FROM_GROUP:
            // eslint-disable-next-line no-case-declarations
            let usersToRemove = state.users.filter(u => (u.current_group !== null) ? u.current_group.id === action.payload.id : false);
            return produce(state, draft => {
                for(let i = 0; i < usersToRemove.length; i++){
                    draft.users[state.users.indexOf(usersToRemove[i])]['user_group'] = null;
                }
            });

        case ADD_USER: {
            // make new app state with updated users or return old if already there
            if((state.users.filter(u => u.id === action.payload.id).length === 0)){
                return produce(state, (draft) => {
                    draft.users = state.users.concat([action.payload]);
                });
            } else if(state.users.filter(u => u.id === action.payload.id).length === 1) {
                return produce(state, (draft) => {
                    draft.users[state.users.indexOf(state.users.find(u => u.id === action.payload.id))] = action.payload;
                });
            } else {
                return state;
            }
        }

        case UPDATE_USER: {
            const updatedUser = action.payload;
            return produce(state, (draft) => {
                if(state.users.filter(g => g.id === updatedUser.id).length === 0){
                    draft.users.push(updatedUser);
                } else {
                    let index = state.users.indexOf(state.users.find(g => g.id === updatedUser.id));
                    return produce(state, (draft) => {
                        // not merge but replace the existing details with new details
                        draft.users[index] = updatedUser;
                    });
                }
            });
        }

        default: {
            return state;
        }
    }
};

export default usersReducer;
