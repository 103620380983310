export const GET_USERS = '@user/update-users';
export const REMOVE_USER = '@user/remove-users';
export const ADD_USER = '@user/add-users';
export const DETACH_USERS_FROM_GROUP = '@user/detach-users-from-groups';
export const UPDATE_USER_ACTIVITY = '@user/update-user-activity';
export const UPDATE_FAVORITE = '@user/update-favorite';
export const UPDATE_AVATAR = '@user/update-avatar';
export const UPDATE_COVER = '@user/update-cover';
export const UPDATE_USER = '@user/update-user';

export function storeUsers(users){

    return (dispatch) => dispatch({
        type: GET_USERS,
        payload: users
    });

}

export function updateUser(user){
    return (dispatch) => dispatch({
        type: UPDATE_USER,
        payload: user
    });
}

export function addUser(user){
    // dispatch remove users action
    return (dispatch) => dispatch({
        type: ADD_USER,
        payload: user
    })
}

export function updateFavorite(user, isFavorite){
    return (dispatch) => dispatch({
        type: UPDATE_FAVORITE,
        payload: {
            user: user,
            isFavorite: isFavorite
        }
    })
}

export function detachUsersFromGroup(group){
    return (dispatch) => dispatch({
        type: DETACH_USERS_FROM_GROUP,
        payload: group
    })
}

export function updateActivityStatus(id, status, last_active){
    // create request object
    return (dispatch) => dispatch({
        type: UPDATE_USER_ACTIVITY,
        payload: {
            id, status, last_active
        }
    });
}

export function removeUsers(id){
    // dispatch remove users action
    return (dispatch) => dispatch({
        type: REMOVE_USER,
        payload: id
    })
}

export function updateUserAvatar(user, avatar){
    return (dispatch) => dispatch({
        type: UPDATE_AVATAR,
        payload: {
            user: user,
            avatar: avatar
        }
    })
}

export function updateUserCover(user, cover){
    return (dispatch) => dispatch({
        type: UPDATE_COVER,
        payload: {
            user: user,
            cover: cover
        }
    })
}