import React from "react";
import PropTypes from "prop-types";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../../hooks/use-is-mobile";
import { useAppMode } from "../../../../hooks/use-app-mode";
import usePerformTransition from "../../../../hooks/use-perform-transition";
import SelectionTiles from "../../../elements/selection-tiles";
import { APP_SETTING } from "../../../../../setup";

export const AppsPopover = ({ popover }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isMobile } = useIsMobile();
    const appMode = useAppMode();

    const transit = usePerformTransition((path) => {
        navigate(path);
        popover.handleClose();
    });

    const openApp = (option) => {
        transit(option?.value);
    };

    const tileOptions = appMode.availableApps.map((a) => ({
        label: t(a.title),
        value: a.path,
        icon: a.icon,
    }));

    return (
        <Dialog
            open={popover.open}
            onClose={popover.handleClose}
            fullScreen={true}
            TransitionComponent={Fade}
            TransitionProps={{
                timeout: APP_SETTING?.transitionDuration || 500,
            }}

            slotProps={{
                backdrop: {
                    sx: {
                        backdropFilter: 'blur(10px) !important',
                        transition: 'backdrop-filter 0.5s ease',
                        '&.MuiBackdrop-open': {
                            backdropFilter: 'blur(10px) !important',
                        },
                    },
                },
            }}

            PaperProps={{
                onClick: popover.handleClose,
                sx: {
                    boxShadow: "none",
                    overflow: "hidden",
                    backgroundColor: 'transparent',
                    // backgroundColor: alpha(theme.palette.background.default, 0.1),
                },
            }}
        >
            {isMobile ? (
                <>
                    <DialogTitle onClick={popover.handleClose}>
                        <Typography variant="h6">{t("common.apps")}</Typography>
                    </DialogTitle>
                    <DialogContent onClick={popover.handleClose}>
                        <Box onClick={(e) => e.stopPropagation()} sx={{ mt: 4 }}>
                            <SelectionTiles
                                lowestCol={4}
                                lowestMobileCol={6}
                                tileHeight={80}
                                delay={0}
                                iconSize="small"
                                spacing={4}
                                paperBackground={true}
                                selected={appMode.currentApp?.path || null}
                                options={tileOptions}
                                onSelect={(option) => openApp(option)}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions onClick={popover.handleClose}>
                        <Button variant="contained" onClick={popover.handleClose}>
                            {t("common.close")}
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent
                        onClick={popover.handleClose}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            p: 4,
                            height: "100%",
                        }}
                    >
                        <Box onClick={(e) => e.stopPropagation()} sx={{ width: "50%", position: "relative" }}>
                            <SelectionTiles
                                sx={{ mt: 0 }}
                                lowestCol={4}
                                lowestMobileCol={6}
                                tileHeight={80}
                                randomOrder={true}
                                spacing={4}
                                delay={0}
                                keyControl={true}
                                paperBackground={true}
                                iconSize="small"
                                selected={appMode.currentApp?.path || null}
                                options={tileOptions}
                                onSelect={(option) => openApp(option)}
                            />
                        </Box>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

AppsPopover.propTypes = {
    popover: PropTypes.shape({
        open: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
    }).isRequired,
};
