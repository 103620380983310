import React, {useEffect, useState} from 'react';
import {Badge, IconButton, Tooltip} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {NotificationsPopover} from './notifications-popover';
import useApi from "../../../../hooks/use-api";
import {useDispatch, useSelector} from "react-redux";
import {setUnreadNotifications} from "../../../../store/actions/account-actions";
import usePagination from "../../../../hooks/use-pagination";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";

const NotificationsButtonBuffer = ({popover}) => {

    const {post} = useApi();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);

    const { elements, isMore, setElements, initialLoading, loading, reset } = usePagination({
        endpoint: 'core/notifications',
        page: currentPage,
        append: true,
        query: {ordering: '-created_at'},
        pageSize: 10
    });

    const handleMarkAsRead = (notification) => {
        setElements(prev => prev.map(n => n.id === notification.id ? {...n, read: true} : n));
    };

    const handleMarkAsUnread = (notification) => {
        setElements(prev => prev.map(n => n.id === notification.id ? {...n, read: false} : n));
    };

    const handleMarkAllAsRead = () => {
        post('core/notifications/mark_all_as_read').then(() => {
            setElements(prev => prev.map(notification => ({...notification, read: true})));
        });
    };

    const handleDeleteNotification = (notification) => {
        setElements(prev => prev.filter(n => n.id !== notification.id));
    };

    const handleDeleteAll = () => {
        dispatch(setUnreadNotifications(0));
        post('core/notifications/delete_all').then(() => {
            setElements([]);
        });
    };

    const loadMore = () => {
        setCurrentPage(prev => prev + 1);
    };

    useEffect(() => {
        if(popover.open)
            reset();
    }, [popover.open]);

    return (
        <NotificationsPopover
            popover={popover}
            notifications={elements}
            onRemoveAll={handleDeleteAll}
            loading={loading}
            initialLoading={initialLoading}
            onLoadMore={loadMore}
            onMarkAsRead={handleMarkAsRead}
            onMarkAsUnread={handleMarkAsUnread}
            onRemove={handleDeleteNotification}
            onMarkAllAsRead={handleMarkAllAsRead}
            moreExists={isMore}
        />
    )
}

export const NotificationsButton = () => {
    const popover = usePopover();
    const { t } = useTranslation();
    const { isFlying } = useIsMobile();
    const unread = useSelector(state => state.account.unread);

    return (
        <>
            <Tooltip enterDelay={1000} title={t("common.tooltips.show_notifications")} placement={isFlying ? 'left' : 'bottom'}>
                <IconButton
                    ref={popover.anchorRef}
                    onClick={popover.handleOpen}
                >
                    <Badge color="error" badgeContent={unread}>
                        <OnIcon iconName="NotificationBox" />
                    </Badge>
                </IconButton>
            </Tooltip>
            <NotificationsButtonBuffer popover={popover} />
        </>
    );
};

NotificationsButtonBuffer.propTypes = {
    popover: PropTypes.object.isRequired
}