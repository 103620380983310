import React, {useEffect, useState} from "react";
import AddThemeDialog from "./control-center/theme-builder/add-theme-dialog";
import {
    Card,
    CardHeader,
    Grid,
    IconButton,
    List,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip
} from "@mui/material";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import DelayedCircularProgress from "../../elements/delayed-circular-progress";
import ThemeSetup from "./control-center/theme-builder/theme-setup";
import {useTranslation} from "react-i18next";
import {useDialog} from "../../../hooks/use-dialog";
import useOmniaApi from "../../../hooks/use-omnia-api";
import usePagination from "../../../hooks/use-pagination";
import OnIcon from "../../elements/icon";

function ThemeBuilder(){

    const { t } = useTranslation();
    const [ selected, setSelected ] = useState(null);
    const dialog = useDialog();
    const { del } = useOmniaApi();

    const themeLoader = usePagination({
        endpoint: 'core/themes',
        pageSize: 100,
        query: {ordering: 'name'},
        page: 1
    })

    const handleRemove = (id) => {
        if(window.confirm(t('notify.are_you_sure'))){
            del('core/themes', id).then(() => {
                themeLoader.reset();
                setSelected(null);
            })
        }
    }

    useEffect(() => {
        if(!selected && themeLoader.elements.length > 0)
            setSelected(themeLoader.elements[0]);
    }, [selected, themeLoader.elements]);

    return (
        <>
            <AddThemeDialog
                open={dialog.open}
                onClose={dialog.handleClose}
                onDone={themeLoader.reset}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} xl={4} lg={5}>
                    <Card>
                        <CardHeader
                            title={t('common.themes')}
                            // subheader={t('mgt.settings.manage_themes')}
                            action={(
                                <Tooltip enterDelay={1000} title={t('mgt.settings.add_theme')}>
                                    <IconButton onClick={dialog.handleOpen}>
                                        <PlusIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        />
                        {themeLoader.initialLoading ? (
                            <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <DelayedCircularProgress />
                            </div>
                        ) : (
                            <List sx={{maxHeight: 800, width: '100%', overflow: 'auto'}}>
                                {themeLoader.elements.map(item => (
                                    <ListItemButton
                                        key={'theme-' + item.id}
                                        onClick={() => setSelected(item)}
                                        selected={selected?.id === item.id}
                                    >
                                        {/*<ListItemIcon>*/}
                                        {/*    <OnIcon iconName="Palette" size="small" />*/}
                                        {/*</ListItemIcon>*/}
                                        <ListItemText
                                            primary={item.name}
                                            secondary={item.created_by ? t('mgt.settings.theme_created_by', {creator: item.created_by?.first_name || t('common.unknown')}) : t('mgt.settings.theme_by_groon')}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton onClick={(event) => {
                                                event.preventDefault();
                                                handleRemove(item.id)
                                            }}>
                                                <OnIcon iconName="Trash01" size="small" />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                ))}
                            </List>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={12} xl={8} lg={7}>
                    {selected && (
                        <ThemeSetup
                            theme={selected}
                            onChange={themeLoader.reset}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
}

export default ThemeBuilder;