import React from "react";
import {useTranslation} from "react-i18next";
import {List} from "@mui/material";
import {SideNavItem} from "../../../layouts/dashboard/flying-layout/side-nav-item";
import {useDialog} from "../../../../hooks/use-dialog";
import {useLocation} from "react-router-dom";
import usePagination from "../../../../hooks/use-pagination";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import SharingStatus from "../../../elements/sharing/sharing-status";
import AddContext from "../add-context";
import {useNavigate} from "react-router";

export default function ContextsSidebarSection(){
    // TODO: Later we can have icons after type

    const { t } = useTranslation();
    const location = useLocation();
    const newContextDialog = useDialog();
    const { hasRights } = useSecurityCheck();
    const navigate = useNavigate();
    const elementLoader = usePagination({
        endpoint: 'crm/contexts',
        query: {ordering: 'created_at'},
        page: 1,
        pageSize: 500
    })

    const handleNewContext = (context) => {
        elementLoader.setElements(prev => [...prev, context]);
        newContextDialog.handleClose();
        navigate('/groon/connections/context/' + context.id);
    }

    return (
        <>
            <List sx={{py: 0}}>
                <SideNavItem
                    title={t('connections.contexts.title')}
                    icon="Grid01"
                    path='/groon/connections/contexts'
                    tooltip={t('layout.tooltips.connections.contexts')}
                />
                {elementLoader.loading ? (
                    <SideNavItem
                        title={t('common.loading')}
                        icon="AlignHorizontalCentre01"
                        disabled={true}
                    />
                ) : elementLoader.elements?.map(context => (
                    <SideNavItem
                        key={'context-' + context.id}
                        title={context.name}
                        icon="AlignHorizontalCentre01"
                        active={location.pathname?.includes(`/groon/connections/context/${context.id}`)}
                        path={`/groon/connections/context/${context.id}`}
                        chip={(
                            <SharingStatus entity={context} minimal={true} />
                        )}
                    />
                ))}
                {hasRights("crm_add_contexts") && (
                    <SideNavItem
                        title={t('connections.contexts.new_context')}
                        icon="PlusCircle"
                        onClick={newContextDialog.handleOpen}
                        tooltip={t('connections.contexts.new_context_info')}
                    />
                )}
            </List>
            <AddContext
                open={newContextDialog.open}
                onClose={newContextDialog.handleClose}
                onDone={handleNewContext}
            />
        </>
    )

}