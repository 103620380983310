export const UPDATE_ACCOUNTS = '@email/update-accounts';
export const UPDATE_SELECTED = '@email/update-selected';
export const UPDATE_ACCOUNT = '@email/update-account';

export function updateSelected(selected){
    return (dispatch) => dispatch({
        type: UPDATE_SELECTED,
        payload: selected
    })
}

export function updateAccounts(accounts){
    return (dispatch) => dispatch({
        type: UPDATE_ACCOUNTS,
        payload: accounts
    })
}

export function updateAccount(account){
    return (dispatch) => dispatch({
        type: UPDATE_ACCOUNT,
        payload: account
    })
}